import { BRAND } from 'config/core';
import { getFileLink, getPageLink } from 'helpers/resources';

import { getCertificates } from './getCertificates';

const term90PlusConfig = {
  common: {
    brand: BRAND.metlife,
    schedule: '95',
    type: 'L4',
    minAge: 18,
    maxAge: 69,
    notAvailableForStates: ['AA', 'AE', 'AP', 'ME', 'MP', 'OR'],
    paymentMethods: ['eft', 'cc', 'check', 'maa'],
    shortName: 'Term 90 Plus',
    getCertificates
  },
  'term-90-plus': {
    shortName: 'Term 90 Plus Life Insurance',
    appLink: getPageLink('page-term-90-plus-app'),
    pdf: getFileLink('file-term-90-plus-brochure'),
    coverageAmount: {
      min: 50000,
      max: 1000000,
      step: 50000
    }
  },
  'term-90-plus-spouse': {
    shortName: 'Term 90 Plus Life Insurance',
    appLink: getPageLink('page-term-90-plus-spouse-app'),
    pdf: getFileLink('file-term-90-plus-spouse-brochure'),
    dutyStatuses: ['I', '2', '1', 'N-', 'N', '3', '7'],
    memberStatuses: ['N1', 'N-1', 'A3', 'C1'],
    coverageAmount: {
      min: 50000,
      max: 1000000,
      step: 50000
    }
  },
  'federal-civilian-term-90-plus': {
    shortName: 'Current Federal Employee Term 90 Plus Life Insurance',
    appLink: getPageLink('page-federal-civilian-term-90-plus-app'),
    pdf: getFileLink('file-federal-civilian-term-90-plus-brochure'),
    paymentMethods: ['eft', 'cc', 'check']
  },
  'federal-civilian-term-90-plus-spouse': {
    shortName: 'Current Federal Employee Term 90 Plus Life Insurance',
    appLink: getPageLink('page-federal-civilian-term-90-plus-spouse-app'),
    pdf: getFileLink('file-federal-civilian-term-90-plus-spouse-brochure'),
    paymentMethods: ['eft', 'cc', 'check'],
    memberStatuses: ['N1', 'N-1', 'A3', 'C1']
  },
  'term-90-plus-simplified': {
    shortName: 'Term 90 Plus Life Insurance',
    appLink: getPageLink('page-term-90-plus-simplified-app'),
    fullAppFormId: 'term-90-plus',
    schedule: '98',
    pdf: getFileLink('file-term-90-plus-brochure'),
    dutyStatuses: ['2', '1', 'N1', 'C', '3', '7'],
    memberStatuses: ['A1'],
    coverageAmount: {
      list: [50000]
    }
  },
  'term-90-plus-short': {
    shortName: 'Term 90 Plus Life Insurance',
    appLink: getPageLink('page-term-90-plus-short-app'),
    pdf: getFileLink('file-term-90-plus-brochure'),
    paymentMethods: ['same', 'eft', 'cc', 'check', 'maa'],
    memberStatuses: ['C'],
    coverageAmount: {
      min: 50000,
      max: 1000000,
      step: 50000
    }
  },
  'term-90-plus-spouse-short': {
    shortName: 'Term 90 Plus Life Insurance',
    appLink: getPageLink('page-term-90-plus-spouse-short-app'),
    pdf: getFileLink('file-term-90-plus-spouse-brochure'),
    paymentMethods: ['same', 'eft', 'cc', 'check', 'maa'],
    memberStatuses: ['C1', 'N2'],
    coverageAmount: {
      min: 50000,
      max: 1000000,
      step: 50000
    }
  },
  'term-90-plus-quick-step': {
    shortName: 'Term 90 Plus Life Insurance',
    appLink: getPageLink('page-term-90-plus-quick-step-app'),
    schedule: '98',
    fullAppFormId: {
      member: 'term-90-plus',
      spouse: 'term-90-plus-spouse'
    },
    pdf: getFileLink('file-term-90-plus-quick-step-brochure'),
    dutyStatuses: ['2', '1', 'N-', 'N', 'C', '3', '7'],
    memberStatuses: ['N', 'N-', 'A2', 'C2', 'NS', 'NS-', 'AS', 'CS'],
    maxAge: 59,
    coverageAmount: {
      min: 50000,
      max: 250000,
      step: 50000
    },
    ageToCoverage: [
      {
        maxAge: 39,
        maxCoverage: 250000
      },
      {
        minAge: 40,
        maxAge: 49,
        maxCoverage: 150000
      },
      {
        minAge: 50,
        maxCoverage: 100000
      }
    ]
  },
  'federal-civilian-term-90-plus-quick-step': {
    shortName:
      'Current Federal Employee Quick Step Term 90 Plus Life Insurance',
    appLink: getPageLink('page-federal-civilian-term-90-plus-quick-step-app'),
    schedule: '98',
    fullAppFormId: {
      member: 'federal-civilian-term-90-plus',
      spouse: 'federal-civilian-term-90-plus-spouse'
    },
    pdf: getFileLink('file-federal-civilian-term-90-plus-quick-step-brochure'),
    memberStatuses: ['N', 'N-', 'A2', 'C2', 'NS', 'NS-', 'AS', 'CS'],
    maxAge: 59,
    coverageAmount: {
      min: 50000,
      max: 250000,
      step: 50000
    },
    ageToCoverage: [
      {
        maxAge: 39,
        maxCoverage: 250000
      },
      {
        minAge: 40,
        maxAge: 49,
        maxCoverage: 150000
      },
      {
        minAge: 50,
        maxCoverage: 100000
      }
    ]
  }
};

export default term90PlusConfig;
