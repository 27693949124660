import PropTypes from 'prop-types';
import React from 'react';
import LazyLoad from 'react-lazyload';

const propTypes = {
  offset: PropTypes.number,
  children: PropTypes.node
};

const defaultProps = {
  offset: 100
};

const LazyLoadComponent = ({ offset, children }) => {
  return (
    <LazyLoad once={true} offset={offset}>
      {children}
    </LazyLoad>
  );
};

LazyLoadComponent.propTypes = propTypes;

LazyLoadComponent.defaultProps = defaultProps;

export default LazyLoadComponent;
