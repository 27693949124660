import BrendelImg from 'assets/images/ballot/ballot-form/brendel.jpg';
import HaywoodImg from 'assets/images/ballot/ballot-form/haywood.jpg';
import RadfordImg from 'assets/images/ballot/ballot-form/radford.jpg';
import TefftImg from 'assets/images/ballot/ballot-form/tefft.jpg';
import Image from 'components/partials/elements/components/image/Image';
import React from 'react';

const ballotConfig = {
  election: '2024',
  meetingCount: (
    <>
      67<sup>th</sup>
    </>
  ),
  startDate: '07/01/2024 00:00',
  endDate: '10/18/2024 00:00',
  meetingDate: '10/18/2024 10:00',
  dateFormatString: 'MM/dd/yyyy HH:mm',
  tz: 'America/New_York',
  recommended: [
    {
      name: 'Amanda M. Tefft, Chief Warrant Officer 4, USA National Guard',
      bio: (
        <div className="bio-row">
          <div className="photo">
            <Image
              className="icon"
              src={TefftImg}
              alt="Amanda M. Tefft, Chief Warrant Officer 4, USA National Guard"
            />
          </div>
          <div className="text">
            <h3>Amanda M. Tefft</h3>
            <p>
              <small>Chief Warrant Officer 4, USA National Guard</small>
            </p>
            <p>
              Chief Warrant Officer 4 Amanda M. Tefft serves as the Command
              Property Accounting Officer for the Massachusetts Army National
              Guard. She enlisted in 1998, serving ten years before completing
              the Warrant Officer Candidate School in 2008. She deployed in
              support of Operation Enduring Freedom to Kabul, Afghanistan from
              2011-2012.
            </p>
            <p>
              CW4 Tefft graduated from the Defense Strategy Course through the
              US Army War College, the Warrant Officer Senior Service Education
              Course, the Reserve Component National Security Course through the
              National Defense University, and the Leadership in Homeland
              Security Course through the Harvard Kennedy School. She holds a
              Bachelor of Arts in Business Administration from Becker College
              and an MBA from the University of Massachusetts Isenberg School of
              Management. Her decorations include the Defense Meritorious
              Service Medal, the Meritorious Service Medal, and many others.
            </p>
            <p>
              CW4 Tefft resides in Brookfield, Massachusetts with her husband,
              David and two sons, William and Timothy. She has been a member of
              MBA since 2020.
            </p>
          </div>
        </div>
      )
    },
    {
      name: 'James B. Brendel, Jr., Lieutenant Commander, USCG',
      bio: (
        <div className="bio-row">
          <div className="photo">
            <Image
              className="icon"
              src={BrendelImg}
              alt="James B. Brendel, Lieutenant Commander, USCG"
            />
          </div>
          <div className="text">
            <h3>James B. Brendel</h3>
            <p>
              <small>Lieutenant Commander, USCG</small>
            </p>
            <p>
              Lieutenant Commander James B. Brendel serves as the Assistant
              Chief, Prevention Training Branch at the Coast Guard Training
              Center Yorktown. Prior assignments include Coast Guard Marine
              Safety Detachment in Cincinnati, Ohio, and Coast Guard Cutter
              Harriet Lane, Portsmouth, Virginia.
            </p>
            <p>
              LCDR Brendel enlisted in 2000 and advanced to the rank of Chief
              Petty Officer before earning his commission at the Coast Guard’s
              Officer Candidate School in 2010. He graduated from Excelsior
              College in Albany, New York with a Bachelor of Science in History
              and from Spring Hill College in Mobile, Alabama and a Master of
              Business Administration degree in Leadership and Ethics.
            </p>
            <p>
              LCDR Brendel’s awards include the Coast Guard Commendation and
              Achievement Medals, Global War on Terrorism Expeditionary Medal,
              Armed Forces Expeditionary Medal, and several other personal and
              unit awards. He is married to the former Kirstin Bussey of
              Yorktown, Virginia and they have one daughter, Madelyn Lee
              Brendel.
            </p>
          </div>
        </div>
      )
    },
    {
      name: 'Michael M. Haywood, Chief Master Sergeant, USAF',
      bio: (
        <div className="bio-row">
          <div className="photo">
            <Image
              className="icon"
              src={HaywoodImg}
              alt="Michael M. Haywood, Chief Master Sergeant, USAF"
            />
          </div>
          <div className="text">
            <h3>Michael M. Haywood</h3>
            <p>
              <small>Chief Master Sergeant, USAF</small>
            </p>
            <p>
              Chief Master Sergeant Michael M. Haywood is the Senior Enlisted
              Leader for Headquarters Pacific Air Forces’ Directorate of Air and
              Cyberspace Operations (A3/6), Joint Base Pearl Harbor-Hickam,
              Hawaii. He is responsible for advising the Director and leading
              mission requirements on all matters relating to Air and Cyberspace
              Operations within the Pacific Area of Responsibility to include
              operational planning, readiness, training, professional
              development, and effective utilization of over 46,000 personnel
              and resources.
            </p>
            <p>
              Chief Haywood enlisted in the United States Air Force in 1998. His
              background includes various duties at the tactical, operational,
              and strategic levels within the Communications-Electronics
              Maintenance, Cyberspace Support, and Cyberspace Defense Operations
              specialties. He has deployed to Saudi Arabia, Germany, Italy,
              Afghanistan, Djibouti and Iraq in support of Operations SOUTHERN
              WATCH, ENDURING FREEDOM, and IRAQI FREEDOM.
            </p>
            <p>
              Chief Haywood’s major decorations include the Bronze Star, the
              Meritorious Service Medal, the Joint Service Commendation Medal,
              the Air Force Commendation Medal, the Joint Service Achievement
              Medal, and the Air Force Achievement Medal. CMSgt Haywood has been
              a member of MBA since 1998.
            </p>
          </div>
        </div>
      )
    },
    {
      name: 'Andrew B. Radford, Sergeant Major, USMC',
      bio: (
        <div className="bio-row">
          <div className="photo">
            <Image
              className="icon"
              src={RadfordImg}
              alt="Andrew B. Radford, Sergeant Major, USMC"
            />
          </div>
          <div className="text">
            <h3>Andrew B. Radford</h3>
            <p>
              <small>Sergeant Major, USMC</small>
            </p>
            <p>
              Sergeant Major Andrew B. Radford is the Command Senior Enlisted
              Leader for Marine Unmanned Aerial Vehicle Squadron 3, Marine Corps
              Base Hawaii.
            </p>
            <p>
              SgtMaj Radford enlisted in the Marine Corps in November of 2002.
              During his career he has served with 2d Battalion 8th Marines, 2d
              Marine Division, Camp Lejeune, NC; Marine and Family Programs
              Division Casualty Branch Quantico, VA, 1st Battalion 10th Marines
              and Marine Forces Special Operation Command Camp Lejeune; Marine
              Security Guard Program at Lagos, Nigeria; Managua, Nicaragua;
              Bogota, Colombia; Tashkent, Uzbekistan; and Algiers, Algeria.
              SgtMaj Radford also deployed in support of Operation Enduring
              Freedom and Operation Iraqi Freedom.
            </p>
            <p>
              His decorations include the Meritorious Service Medal, Navy and
              Marine Corps Commendation Medal (w/two gold stars in lieu of third
              award), Navy and Marine Corps Achievement Medal (w/two gold stars
              in lieu of third award), Combat Action Ribbon, Outstanding
              Volunteer Service Medal (w/bronze star in lieu of second award),
              and two Meritorious Honor Awards from the Department of State.
            </p>
            <p>
              SgtMaj Radford holds a Bachelor of Arts in Business Administration
              from American InterContinental University. He completed the Joint
              Special Operations Senior Enlisted Academy at Joint Special
              Operations University and is a Resilience-Building Leadership
              Professional Trainer and a Six Sigma Lean Black Belt Professional.
              SgtMaj Radford has been a member of MBA since 2006.
            </p>
          </div>
        </div>
      )
    }
  ]
};

export default ballotConfig;
