export function setRequired(fieldSchema, isRequired) {
  return isRequired ? fieldSchema.required() : fieldSchema;
}

/** This limitation exists on MBA web service side. */
export function beneLengthValid(beneArr = []) {
  /**
   * Produce the string in form
   * "name1,relationship1,percent1%;name2,relationship2,percent2%;"
   */
  const resultingString = beneArr.reduce(
    (memo, { name = '', relationship = '', sharePercent = '' }) => {
      /** Reserve two characters for sharePercent in case not provided */
      memo += `${name},${relationship},${sharePercent || '00'}%;`;

      return memo;
    },
    /**
     * This used as delimiter between primary and contingent bene on web service
     * Official limit is 240 chars, but extra chars (0) added just to be sure
     */
    ', 00000'
  );

  return {
    error:
      resultingString.length > 240 &&
      `The maximum length of all names and relationship statuses must be less or equal to 240 characters. Current length is ${resultingString.length}.`,
    length: resultingString.length
  };
}
