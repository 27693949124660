import _cloneDeep from 'lodash/cloneDeep';

import * as Types from './types';

const initialState = {
  loading: false,
  acquired: false,
  acquiredTemp: false // it's for member request to not send new get user request
};

const reducer = (state = initialState, action = {}) => {
  const { type } = action;
  let newState;

  switch (type) {
    case Types.REQUEST_FOR_ELECTRONIC_CONSENT:
    case Types.REQUEST_FOR_ELECTRONIC_CONSENT_MEMBER:
      newState = _cloneDeep(state);
      newState.loading = true;
      return newState;

    case Types.ELECTRONIC_CONSENT_SUCCESS:
      newState = _cloneDeep(state);
      newState.loading = false;
      newState.acquired = true;
      return newState;

    case Types.ELECTRONIC_CONSENT_MEMBER_SUCCESS:
      newState = _cloneDeep(state);
      newState.loading = false;
      // we will receive this value from user data
      newState.acquired = false;
      newState.acquiredTemp = true;
      return newState;

    case Types.ELECTRONIC_CONSENT_ERROR:
    case Types.ELECTRONIC_CONSENT_MEMBER_ERROR:
      newState = _cloneDeep(state);
      newState.loading = false;
      return newState;

    default:
      return state;
  }
};

const reducerConfig = {
  initialState,
  reducer,
  filter: ['acquired']
};

export default reducerConfig;
