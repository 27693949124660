const policies = [
  {
    code: 'LG',
    name: 'MetLife Legal Plans',
    type: 'other',
    appId: 'metlaw-legal',
    formId: 'metlaw-legal'
  },
  { code: 'AD', name: 'AD&D', appId: 'ad-and-d', formId: 'ad-and-d' },
  // TODO: ask TJ about this policy type code and update name
  { code: 'AV', name: 'AD&D', appId: 'ad-and-d', formId: 'ad-and-d-spouse' },
  { code: 'AN', name: 'Decreasing Monthly Income Plan' },
  { code: 'CH', name: 'Tricare Supp', appId: 'tricare', formId: 'tricare' },
  {
    code: 'CI',
    name: 'Tricare Select and Extra in/outpatient Reimbursement Opt.',
    appId: 'tricare',
    formId: 'tricare'
  },
  { code: 'DC', name: 'Dependent Continuation Coverage' },
  {
    code: 'FD',
    name: 'Adult Former Dependent',
    appId: 'nextgen',
    formId: 'nextgen-adult'
  },
  { code: 'FP', name: 'Family Plan' },
  { code: 'HC', name: 'Hospital Insurance Plan for Children' },
  { code: 'HM', name: 'Hospital Insurance Plan for Member' },
  { code: 'HP', name: 'Hospital Insurance Plan' },
  { code: 'HS', name: 'Hospital Insurance Plan for Spouse' },
  { code: 'IR', name: 'IRA' },
  {
    code: 'L1',
    name: 'Level Term I',
    appId: 'level-term-l1-l2',
    formId: 'level-term-l1'
  },
  {
    code: 'L2',
    name: 'Level Term II',
    appId: 'level-term-l1-l2',
    formId: 'level-term-l2'
  },
  // plain 90
  {
    code: 'L3',
    name: 'Plan 90',
    appId: 'term-90',
    formId: 'term-90'
  },
  // 90 plus
  {
    code: 'L4',
    name: 'Plan 90 Plus',
    appId: 'term-90-plus',
    formId: 'term-90-plus'
  },
  { code: 'L5', name: 'GI Plan 90 Plus' },
  {
    code: 'L6',
    name: 'Level Term 10/20',
    appId: 'level-term',
    formId: 'level-term'
  },
  { code: 'L7', name: 'Level Term Ultimate' },
  { code: 'N1', name: 'National Guard Basic' },
  { code: 'N3', name: 'National Guard' },
  { code: 'LD', name: 'Life of Duty' },
  { code: 'LS', name: 'Lump Sum' },
  { code: 'MD', name: 'Medicare Supplement' },
  {
    code: 'NG',
    name: 'Nexgen Dependent Term',
    appId: 'nextgen',
    formId: 'nextgen-child'
  },
  { code: 'VP', name: 'Veterans Term' },
  { code: 'WL', name: 'Whole Life' }
];

export default policies;
