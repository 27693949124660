import apoFpoStates from 'config/dictionaries/apo-fpo-states';
import frequencies from 'config/dictionaries/frequencies';
import ranks from 'config/dictionaries/ranks';
import relationshipStatuses from 'config/dictionaries/relationship-statuses';
import states from 'config/dictionaries/states';
import _concat from 'lodash/concat';
import _merge from 'lodash/merge';

import { convertArrayToString, formatString, pick } from './helpers';

export function getFullName(...nameParts) {
  return convertArrayToString(nameParts, ' ');
}

export function getFullAddress({ street, city, state, zip }) {
  return convertArrayToString([street, city, getStateName(state), zip], ', ');
}

export function getStateName(code) {
  return pick(_concat(apoFpoStates, states), { code }, 'name');
}

export function stateCodesToNames(codes) {
  return (codes || []).map(code => getStateName(code));
}

export function getRelationshipStatusName(code) {
  return pick(relationshipStatuses, { code }, 'name');
}

export function getRankName(rankCode, branchCode) {
  return pick(ranks, { code: rankCode, branch: branchCode }, 'name');
}

// ##### -> #####
// ######### -> #####-####
export function convertZip(zip) {
  return formatString(zip, '#####-####', '-');
}

// ########## -> ###-###-####
export function convertPhone(phone) {
  return formatString(phone, '###-###-####', '-');
}

// ######### -> ###-##-####
export function convertSsn(ssn) {
  return formatString(ssn, '###-##-####', '-');
}

export function convertLocationToPath(location, updateLocation) {
  const { pathname, search, hash } = _merge({}, location, updateLocation);
  return convertArrayToString([pathname, search, hash], '');
}

export function calculateAmountFromFrequency(amount, frequency) {
  amount = Number(amount);
  if (!frequency) return amount;
  const multiplier = pick(frequencies, { code: frequency }, 'multiplier', 1);
  return amount * multiplier;
}

// current limits: min 0, max - 19
export function convertNumberToWord(number) {
  var names = [
    'zero',
    'one',
    'two',
    'three',
    'four',
    'five',
    'six',
    'seven',
    'eight',
    'nine',
    'ten',
    'eleven',
    'twelve',
    'thirteen',
    'fourteen',
    'fifteen',
    'sixteen',
    'seventeen',
    'eighteen',
    'nineteen'
  ];

  return names[Math.abs(parseInt(number))] || '';
}
