import { BRAND } from 'config/core';
import { getFileLink, getPageLink } from 'helpers/resources';

const metlawLegalConfig = {
  common: {
    brand: BRAND.metlife,
    type: 'LG',
    notAvailableForStates: ['AA', 'AE', 'AP', 'MP'],
    dutyStatuses: ['2', '1', 'C', '3', '7', 'N-', 'N', '9.1', '8.1']
  },
  'metlaw-legal': {
    appLink: getPageLink('page-metlaw-legal-app'),
    pdf: getFileLink('file-metlaw-legal-brochure'),
    planCode: '990-2237',
    paymentAmount: '277.20',
    renewalPeriod: 12 // months
  }
};

export default metlawLegalConfig;
