import { object, string } from 'yup';

export const reg = object()
  .shape({
    authToken: string().required(),
    member: object()
      .shape({
        contact: object().shape({
          email: object().shape({
            primary: string()
              .email()
              .required()
          })
        }),
        firstName: string().required(),
        lastName: string().required(),
        _id: string().required()
      })
      .required()
  })
  .required();

export const login = object()
  .shape({
    authToken: string().required(),
    member: object()
      .shape({
        firstName: string().required(),
        lastName: string().required(),
        email: string()
          .email()
          .required(),
        _id: string().required()
      })
      .required()
  })
  .required();

export const userData = object()
  .shape({
    _id: string().required()
  })
  .required();
