import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';

import { elementTopIsInViewport, scrollToSelector } from './dom';

export function useOutsideClick(ref, callback) {
  useEffect(() => {
    const handleClickOutside = event => {
      if (ref.current && !ref.current.contains(event.target)) {
        typeof callback === 'function' && callback();
      }
    };
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [ref, callback]);
}

export function useScrollToTop(dependency) {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [dependency]);
}

export function useScrollToHash(defaultToTop) {
  const { hash } = useLocation();

  useEffect(() => {
    if (hash) {
      scrollToSelector(hash, defaultToTop);
    }
  }, [hash, defaultToTop]);
}

export function useScrollToRef(ref, dependency) {
  useEffect(() => {
    if (ref && ref.current && !elementTopIsInViewport(ref.current)) {
      ref.current.scrollIntoView({ behavior: 'smooth', block: 'start' });
    }
  }, [ref, dependency]);
}
