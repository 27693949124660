import {
  convertPhone,
  convertSsn,
  convertZip,
  getFullName
} from 'helpers/converters';
import _get from 'lodash/get';
import _omit from 'lodash/omit';
import _set from 'lodash/set';

export const login = ({ authToken, member: { firstName, lastName, _id } }) => {
  return {
    memberId: _id,
    memberToken: authToken,
    memberName: getFullName(firstName, lastName)
  };
};

// should return the same auth object as login reducer
export const reg = ({ authToken, member: { firstName, lastName, _id } }) => {
  return {
    memberId: _id,
    memberToken: authToken,
    memberName: getFullName(firstName, lastName)
  };
};

export const userData = ({ _id, ...data }) => {
  // TODO: this should be implemented in aqua
  const convert = [
    { converter: convertPhone, path: 'contact.phone.home' },
    { converter: convertPhone, path: 'contact.phone.work' },
    { converter: convertZip, path: 'mailing.zip' },
    { converter: convertSsn, path: 'ssn' }
  ];
  convert.forEach(({ converter, path }) => {
    const value = _get(data, path);
    if (value !== undefined) {
      _set(data, path, converter(value));
    }
  });
  return {
    memberId: _id,
    ..._omit(data, ['password', 'devices'])
  };
};
