import { BRAND } from 'config/core';
import { getFileLink, getPageLink } from 'helpers/resources';

const tricareConfig = {
  common: {
    brand: BRAND.hartford,
    type: 'CH', // TRICARE SUPP
    maxAge: 64,
    minAge: 18,
    notAvailableForStates: [
      'AA',
      'AE',
      'AP',
      'AS',
      'ME',
      'NM',
      'PR',
      'VI',
      'WA'
    ],
    /*
      paperStatesFlow example:
      {
          states: ['AZ', 'GU', 'ID', 'OR', 'WA', 'WV'],
          pdf: getFileLink('file-tricare-brochure')
      }
    */
    paperStatesFlow: [
      {
        states: ['NY'],
        pdf: getFileLink('file-tricare-brochure-ny')
      }
    ],
    memberStatuses: ['N', 'NS', 'A2', 'C2'],
    // ! use getDutyStatuses
    dutyStatuses: [],
    // ! use getBranches
    branches: [],
    paymentMethods: ['eft', 'cc', 'check']
  },
  tricare: {
    shortName: 'TRICARE Supplement Insurance',
    appLink: getPageLink('page-tricare-app'),
    pdf: getFileLink('file-tricare-brochure')
  }
};

export default tricareConfig;
