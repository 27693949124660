import _get from 'lodash/get';
import PropTypes from 'prop-types';
import { useEffect } from 'react';
import { connect } from 'react-redux';
import { getData } from 'store/parts/seo/actions';

const propTypes = {
  children: PropTypes.node.isRequired,
  // store
  loading: PropTypes.bool,
  data: PropTypes.object,
  errorMessage: PropTypes.string,
  getData: PropTypes.func.isRequired
};

const SeoContainer = ({ loading, data, errorMessage, getData, children }) => {
  // get seo data
  useEffect(() => {
    if (!loading && !data && !errorMessage) {
      getData();
    }
  }, [loading, data, errorMessage, getData]);

  return children;
};

SeoContainer.propTypes = propTypes;

const mapStateToProps = state => ({
  loading: _get(state, 'seo.loading'),
  data: _get(state, 'seo.data'),
  errorMessage: _get(state, 'seo.errorMessage')
});

export default connect(mapStateToProps, {
  getData
})(SeoContainer);
