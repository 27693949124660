class RequestError extends Error {
  constructor(error = {}, message) {
    message =
      message || error.response
        ? 'Request error occured'
        : 'Network error occured';
    super(message);
    this.name = 'RequestError';
    const errorResponse = error.response || {};
    for (const key in errorResponse) {
      if (errorResponse.hasOwnProperty(key)) {
        this[key] = errorResponse[key];
      }
    }
  }
}

export default RequestError;
