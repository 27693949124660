export const SAVE_LIVE_CALCULATOR = 'SAVE_LIVE_CALCULATOR';
export const REQUEST_FOR_LIVE_CALCULATOR_PDF =
  'REQUEST_FOR_LIVE_CALCULATOR_PDF';
export const LIVE_CALCULATOR_PDF_REQUEST_SUCCESS =
  'LIVE_CALCULATOR_PDF_REQUEST_SUCCESS';
export const LIVE_CALCULATOR_PDF_REQUEST_ERROR =
  'LIVE_CALCULATOR_PDF_REQUEST_ERROR';
export const REQUEST_FOR_LIVE_CALCULATOR_EMAIL =
  'REQUEST_FOR_LIVE_CALCULATOR_EMAIL';
export const LIVE_CALCULATOR_EMAIL_REQUEST_SUCCESS =
  'LIVE_CALCULATOR_EMAIL_REQUEST_SUCCESS';
export const LIVE_CALCULATOR_EMAIL_REQUEST_ERROR =
  'LIVE_CALCULATOR_EMAIL_REQUEST_ERROR';
