import _cloneDeep from 'lodash/cloneDeep';

import * as Types from './types';

const initialState = {
  alertVisible: true,
  modalNoticeOpened: true,
  loading: false
};

const reducer = (state = initialState, action = {}) => {
  const { type, payload } = action;
  let newState;

  switch (type) {
    case Types.REQUEST_FOR_BALLOT:
      newState = _cloneDeep(state);
      newState.loading = true;
      return newState;

    case Types.BALLOT_REQUEST_SUCCESS:
    case Types.BALLOT_REQUEST_ERROR:
      newState = _cloneDeep(state);
      newState.loading = false;
      return newState;

    case Types.SET_BALLOT_NOTICE_MODAL_STATE:
      newState = _cloneDeep(state);
      if (!payload) {
        newState.alertVisible = false;
      }
      newState.modalNoticeOpened = !!payload;
      return newState;

    case Types.BALLOT_HIDE_ALERT:
      newState = _cloneDeep(state);
      newState.alertVisible = false;
      return newState;

    default:
      return state;
  }
};

const reducerConfig = {
  initialState,
  reducer,
  filter: ['modalNoticeOpened', 'alertVisible']
};

export default reducerConfig;
