import APPLICATIONS from 'config/applications';
import apoFpoStates from 'config/dictionaries/apo-fpo-states';
import dutyStatuses from 'config/dictionaries/duty-statuses';
import states from 'config/dictionaries/states';
import _concat from 'lodash/concat';
import _get from 'lodash/get';

import { pick, pickAll } from './helpers';

export function getAppIdByFormId(formId) {
  const appIds = Object.keys(APPLICATIONS || {});

  for (const appId of appIds) {
    const formIds = Object.keys(APPLICATIONS[appId] || {});

    const isTargetApp = formIds.some(e => e === formId);

    if (isTargetApp) {
      return appId;
    }
  }
}

export function getAppConfig(appId, formId, path, defaultToCommon = true) {
  return _getAppConfig(appId, formId, path, APPLICATIONS, defaultToCommon);
}

export function _getAppConfig(
  appId,
  formId,
  path,
  configs,
  defaultToCommon = true
) {
  if (!appId && formId) {
    appId = getAppIdByFormId(formId);
  }

  if (
    !appId ||
    !formId ||
    !configs[appId] ||
    !path ||
    !path.length ||
    (typeof path !== 'string' && !Array.isArray(path))
  ) {
    return;
  }
  let result;
  const formConfig = _get(configs, [appId, formId], {});
  result = _get(formConfig, path);
  if (result === undefined && defaultToCommon) {
    const commonConfig = _get(configs, [appId, 'common']);
    result = _get(commonConfig, path);
  }
  return result;
}

export function getFormerDutyStatusesCodes() {
  return pickAll(dutyStatuses, { isFormer: true }, 'code');
}

export function getTypeOfPayConfig() {
  return dutyStatuses.reduce((memo, item) => {
    if (item.isFormer) {
      memo.push({ code: item.code, label: item.typeOfPayLabel });
    }
    return memo;
  }, []);
}

export function getTypeOfPayLabel(dutyStatus) {
  const config = getTypeOfPayConfig();
  return pick(config, { code: dutyStatus }, 'label');
}

export function getStateCodesList(includeApoFpoStates = true) {
  return _concat(includeApoFpoStates ? apoFpoStates : [], states).map(
    s => s.code
  );
}

export function isStateCodeValid(code = '') {
  return getStateCodesList(true).includes(code.toUpperCase());
}

export function isAppIdValid(appId) {
  return Object.keys(APPLICATIONS).includes(appId);
}

export const getCertificatesHelpers = {
  getStateSpecificConfig: ({ state = '', stateSpecificConfig }) => {
    state = state.toUpperCase();

    const stateConfig = stateSpecificConfig.find(row =>
      row.states.includes(state)
    );

    return _get(stateConfig, 'certs');
  },

  getCertificates: ({ certsConfig, certificates }) => {
    return !!certsConfig && certsConfig.map(certId => certificates[certId]);
  }
};
