import ErrorStrings from 'config/error-strings';
import _cloneDeep from 'lodash/cloneDeep';

import * as Types from './types';

const initialState = {
  loading: false,
  errorMessage: ''
};

const reducer = (state = initialState, action = {}) => {
  const { type, payload = {} } = action;
  let newState;

  switch (type) {
    case Types.REQUEST_FOR_DOWNLOAD_FORM:
      newState = _cloneDeep(state);
      newState.loading = true;
      newState.errorMessage = '';
      return newState;

    case Types.DOWNLOAD_FORM_REQUEST_SUCCESS:
      newState = _cloneDeep(state);
      newState.loading = false;
      newState.errorMessage = '';
      return newState;

    case Types.DOWNLOAD_FORM_REQUEST_ERROR:
      newState = _cloneDeep(state);
      const requestError = payload;
      newState.loading = false;
      newState.errorMessage =
        requestError.status >= 400 && requestError.status < 500
          ? ErrorStrings.validationError
          : ErrorStrings.apiError;
      return newState;

    default:
      return state;
  }
};

const reducerConfig = { initialState, reducer };

export default reducerConfig;
