import _get from 'lodash/get';
import PropTypes from 'prop-types';
import { useEffect } from 'react';
import { connect } from 'react-redux';
import {
  loadUser,
  logoutUser,
  sendGetUserRequest
} from 'store/parts/user/actions';

const propTypes = {
  children: PropTypes.node.isRequired,
  // store
  auth: PropTypes.object,
  userIsLoggedIn: PropTypes.bool,
  getUser: PropTypes.shape({
    loading: PropTypes.bool,
    dataLoaded: PropTypes.bool,
    errorMessage: PropTypes.string
  }),
  loadUser: PropTypes.func.isRequired,
  logoutUser: PropTypes.func.isRequired,
  sendGetUserRequest: PropTypes.func.isRequired
};

const UserContainer = ({
  auth,
  userIsLoggedIn,
  getUser,
  loadUser,
  logoutUser,
  sendGetUserRequest,
  children
}) => {
  // load user
  if (!auth) {
    loadUser();
  }
  // get user data
  useEffect(() => {
    if (
      userIsLoggedIn &&
      !getUser.dataLoaded &&
      !getUser.loading &&
      !getUser.errorMessage
    ) {
      sendGetUserRequest(auth.memberId, auth.memberToken);
    } else if (getUser.errorMessage) {
      logoutUser();
    }
  }, [auth, userIsLoggedIn, getUser, sendGetUserRequest, logoutUser]);
  return children;
};

UserContainer.propTypes = propTypes;

const mapStateToProps = state => ({
  auth: _get(state, 'user.auth'),
  userIsLoggedIn: _get(state, 'user.isLoggedIn'),
  getUser: {
    loading: _get(state, 'user.loading.getUser'),
    dataLoaded: _get(state, 'user.dataLoaded'),
    errorMessage: _get(state, 'user.errorMessage.getUser')
  }
});

export default connect(mapStateToProps, {
  loadUser,
  logoutUser,
  sendGetUserRequest
})(UserContainer);
