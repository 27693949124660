import ErrorStrings from 'config/error-strings';
import _cloneDeep from 'lodash/cloneDeep';
import _concat from 'lodash/concat';
import _get from 'lodash/get';
import _set from 'lodash/set';

import * as Types from './types';

const initialState = {
  loading: false,
  data: {},
  errorMessage: ''
};

const reducer = (state = initialState, action = {}) => {
  const { type, payload = {} } = action;
  let newState;

  switch (type) {
    case Types.REQUEST_FOR_SEARCH:
      newState = _cloneDeep(state);
      newState.loading = true;
      newState.errorMessage = '';
      return newState;

    case Types.GET_SEARCH_SUCCESS:
      newState = _cloneDeep(state);
      const {
        term,
        data,
        pages: { next, hasNext }
      } = payload;
      newState.loading = false;
      const currentTermData = _get(newState.data, term, {});
      currentTermData.data = _concat(currentTermData.data || [], data);
      currentTermData.next = next;
      currentTermData.hasNext = hasNext;
      _set(newState.data, term, currentTermData);
      newState.errorMessage = '';
      return newState;

    case Types.GET_SEARCH_ERROR:
      newState = _cloneDeep(state);
      newState.loading = false;
      newState.errorMessage = ErrorStrings.apiError;
      return newState;

    default:
      return state;
  }
};

const reducerConfig = { initialState, reducer };

export default reducerConfig;
