import _cloneDeep from 'lodash/cloneDeep';

import * as Types from './types';

const DEFAULT_FORM = 'login';

const initialState = {
  isOpened: false,
  currentForm: DEFAULT_FORM,
  loginRedirectUrl: undefined
};

const reducer = (state = initialState, action = {}) => {
  const { type, payload = {} } = action;
  let newState;

  switch (type) {
    case Types.CLOSE_AUTH_MODAL:
      newState = _cloneDeep(state);
      newState.isOpened = false;
      newState.currentForm = DEFAULT_FORM;
      newState.loginRedirectUrl = undefined;
      return newState;

    case Types.OPEN_AUTH_MODAL:
      newState = _cloneDeep(state);
      const { loginRedirectUrl, currentForm } = payload;
      newState.isOpened = true;
      if (loginRedirectUrl) {
        newState.loginRedirectUrl = loginRedirectUrl;
      }
      newState.currentForm = currentForm || DEFAULT_FORM;
      return newState;

    case Types.SET_CURRENT_AUTH_MODAL_FORM:
      newState = _cloneDeep(state);
      newState.currentForm = payload || DEFAULT_FORM;
      return newState;

    default:
      return state;
  }
};

const reducerConfig = { initialState, reducer };

export default reducerConfig;
