// load user
export const LOAD_USER = 'LOAD_USER';
// set user auth
export const SET_USER_AUTH = 'SET_USER_AUTH';
// login user
export const REQUEST_FOR_USER_LOGIN = 'REQUEST_FOR_USER_LOGIN';
export const USER_LOGIN_ERROR = 'USER_LOGIN_ERROR';
// login super admin
export const REQUEST_FOR_SUPER_ADMIN_LOGIN = 'REQUEST_FOR_SUPER_ADMIN_LOGIN';
export const SUPER_ADMIN_LOGIN_ERROR = 'SUPER_ADMIN_LOGIN_ERROR';
// logout user
export const LOGOUT_USER = 'LOGOUT_USER';
export const LOGOUT_USER_SUCCESS = 'LOGOUT_USER_SUCCESS';
// registration request
export const REQUEST_FOR_USER_REG = 'REQUEST_FOR_USER_REG';
export const USER_REG_ERROR = 'USER_REG_ERROR';
// setup username request
export const REQUEST_FOR_SETUP_USERNAME = 'REQUEST_FOR_SETUP_USERNAME';
export const SETUP_USERNAME_SUCCESS = 'SETUP_USERNAME_SUCCESS';
export const SETUP_USERNAME_ERROR = 'SETUP_USERNAME_ERROR';
// forgot password request
export const REQUEST_FOR_FORGOT_PASSWORD = 'REQUEST_FOR_FORGOT_PASSWORD';
export const FORGOT_PASSWORD_SUCCESS = 'FORGOT_PASSWORD_SUCCESS';
export const FORGOT_PASSWORD_ERROR = 'FORGOT_PASSWORD_ERROR';
// find your username request
export const REQUEST_FOR_FIND_YOUR_USERNAME = 'REQUEST_FOR_FIND_YOUR_USERNAME';
export const FIND_YOUR_USERNAME_SUCCESS = 'FIND_YOUR_USERNAME_SUCCESS';
export const FIND_YOUR_USERNAME_ERROR = 'FIND_YOUR_USERNAME_ERROR';
// reset password request
export const REQUEST_FOR_RESET_PASSWORD = 'REQUEST_FOR_RESET_PASSWORD';
export const RESET_PASSWORD_SUCCESS = 'RESET_PASSWORD_SUCCESS';
export const RESET_PASSWORD_ERROR = 'RESET_PASSWORD_ERROR';
// get user request
export const REQUEST_FOR_GET_USER = 'REQUEST_FOR_GET_USER';
export const GET_USER_SUCCESS = 'GET_USER_SUCCESS';
export const GET_USER_ERROR = 'GET_USER_ERROR';
// update password request
export const REQUEST_FOR_UPDATE_PASSWORD = 'REQUEST_FOR_UPDATE_PASSWORD';
export const UPDATE_PASSWORD_SUCCESS = 'UPDATE_PASSWORD_SUCCESS';
export const UPDATE_PASSWORD_ERROR = 'UPDATE_PASSWORD_ERROR';
// update contact info request
export const REQUEST_FOR_UPDATE_CONTACT_INFO =
  'REQUEST_FOR_UPDATE_CONTACT_INFO';
export const UPDATE_CONTACT_INFO_SUCCESS = 'UPDATE_CONTACT_INFO_SUCCESS';
export const UPDATE_CONTACT_INFO_ERROR = 'UPDATE_CONTACT_INFO_ERROR';
// update user data
export const UPDATE_USER_DATA = 'UPDATE_USER_DATA';
