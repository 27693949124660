import _cloneDeep from 'lodash/cloneDeep';

import * as Types from './types';

const initialState = {
  form: {
    loading: false
  },
  events: {
    loading: false,
    data: null
  }
};

const reducer = (state = initialState, action = {}) => {
  const { type, payload } = action;
  let newState;

  switch (type) {
    case Types.REQUEST_FOR_DIGITAL_SIGNUP:
      newState = _cloneDeep(state);
      newState.form.loading = true;
      return newState;

    case Types.DIGITAL_SIGNUP_REQUEST_SUCCESS:
    case Types.DIGITAL_SIGNUP_REQUEST_ERROR:
      newState = _cloneDeep(state);
      newState.form.loading = false;
      return newState;

    case Types.REQUEST_FOR_DIGITAL_SIGNUP_EVENTS:
      newState = _cloneDeep(state);
      newState.events.loading = true;
      return newState;

    case Types.DIGITAL_SIGNUP_EVENTS_REQUEST_SUCCESS:
      newState = _cloneDeep(state);
      newState.events.loading = false;
      newState.events.data = payload;
      return newState;

    case Types.DIGITAL_SIGNUP_EVENTS_REQUEST_ERROR:
      newState = _cloneDeep(state);
      newState.events.data = [];
      newState.events.loading = false;
      return newState;

    default:
      return state;
  }
};

const reducerConfig = { initialState, reducer };

export default reducerConfig;
