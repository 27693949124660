import _cloneDeep from 'lodash/cloneDeep';
import _set from 'lodash/set';

import * as Types from './types';

const initialState = {
  data: {}
};

const reducer = (state = initialState, action = {}) => {
  const { type, payload = {} } = action;
  let newState;
  let appPath;

  switch (type) {
    case Types.SET_FINISHED_APPLICATION_DATA:
      newState = _cloneDeep(state);
      appPath = [payload.appId, payload.formId];
      _set(newState.data, appPath, payload.data);
      return newState;

    default:
      return state;
  }
};

const reducerConfig = { initialState, reducer };

export default reducerConfig;
