import { certificates as coreCertificates } from 'config/certificates';
import { getCertificatesHelpers } from 'helpers/applications';
import { getFileLink } from 'helpers/resources';

export const certConfig = {
  certificates: {
    ...coreCertificates,
    nwLevelTermL1L2Cert: {
      link: getFileLink('file-cert-level-term-l1-l2-nw-cert'),
      label: 'NW Level Term I & II Certificate'
    },
    nwLevelTermL1L2ContributionRider: {
      link: getFileLink('file-cert-level-term-l1-l2-nw-contribution-rider'),
      label: 'NW Level Term I & II Contribution Rider'
    },
    levelTermL1Rates: {
      link: getFileLink('file-cert-level-term-l1-l2-l1-rates'),
      label: 'Level Term I Rate Sheet'
    },
    levelTermL2Rates: {
      link: getFileLink('file-cert-level-term-l1-l2-l2-rates'),
      label: 'Level Term II Rate Sheet'
    },
    akLevelTermL1L2Cert: {
      link: getFileLink('file-cert-level-term-l1-l2-ak-cert'),
      label: 'AK Level Term I & II Certificate'
    },
    ksLevelTermL1L2AboRider: {
      link: getFileLink('file-cert-level-term-l1-l2-ks-abo-rider'),
      label: 'KS Level Term I & II ABO Rider'
    },
    laLevelTermL1L2Cert: {
      link: getFileLink('file-cert-level-term-l1-l2-la-cert'),
      label: 'LA Level Term I & II Certificate'
    },
    laLevelTermL1L2ContributionRider: {
      link: getFileLink('file-cert-level-term-l1-l2-la-contribution-rider'),
      label: 'LA Level Term I & II Contribution Rider'
    },
    mdLevelTermL1L2Cert: {
      link: getFileLink('file-cert-level-term-l1-l2-md-cert'),
      label: 'MD Level Term I & II Certificate'
    },
    mdLevelTermL1L2ContributionRider: {
      link: getFileLink('file-cert-level-term-l1-l2-md-contribution-rider'),
      label: 'MD Level Term I & II Contribution Rider'
    },
    mtLevelTermL1L2Cert: {
      link: getFileLink('file-cert-level-term-l1-l2-mt-cert'),
      label: 'MT Level Term I & II Certificate'
    },
    ncLevelTermL1L2Cert: {
      link: getFileLink('file-cert-level-term-l1-l2-nc-cert'),
      label: 'NC Level Term I & II Certificate'
    },
    nhLevelTermL1L2Cert: {
      link: getFileLink('file-cert-level-term-l1-l2-nh-cert'),
      label: 'NH Level Term I & II Certificate'
    },
    nyLevelTermL1L2Cert: {
      link: getFileLink('file-cert-level-term-l1-l2-ny-cert'),
      label: 'NY Level Term I & II Certificate'
    },
    txLevelTermL1L2Cert: {
      link: getFileLink('file-cert-level-term-l1-l2-tx-cert'),
      label: 'TX Level Term I & II Certificate'
    },
    txLevelTermL1L2ContributionRider: {
      link: getFileLink('file-cert-level-term-l1-l2-tx-contribution-rider'),
      label: 'TX Level Term I & II Contribution Rider'
    },
    utLevelTermL1L2Cert: {
      link: getFileLink('file-cert-level-term-l1-l2-ut-cert'),
      label: 'UT Level Term I & II Certificate'
    },
    utLevelTermL1L2ContributionRider: {
      link: getFileLink('file-cert-level-term-l1-l2-ut-contribution-rider'),
      label: 'UT Level Term I & II Contribution Rider'
    },
    waLevelTermL1L2Cert: {
      link: getFileLink('file-cert-level-term-l1-l2-wa-cert'),
      label: 'WA Level Term I & II Certificate'
    }
  },
  l1: {
    nationalWideConfig: [
      'nwLevelTermL1L2Cert',
      'nwLevelTermL1L2ContributionRider',
      'nwFamilyRider',
      'levelTermL1Rates',
      'nwChildRider',
      'howWePay1Rider'
    ],
    stateSpecificConfig: [
      // Alaska
      {
        states: ['AK'],
        certs: [
          'akLevelTermL1L2Cert',
          'nwLevelTermL1L2ContributionRider',
          'akFamilyRider',
          'levelTermL1Rates',
          'akChildRider',
          'howWePay1Rider'
        ]
      },
      // California
      {
        states: ['CA'],
        certs: [
          'nwLevelTermL1L2Cert',
          'nwLevelTermL1L2ContributionRider',
          'nwFamilyRider',
          'levelTermL1Rates',
          'nwChildRider',
          'howWePay1Rider',
          'caProofRider'
        ]
      },
      // Guam
      {
        states: ['GU'],
        certs: [
          'nwLevelTermL1L2Cert',
          'nwLevelTermL1L2ContributionRider',
          'nwFamilyRider',
          'levelTermL1Rates',
          'nwChildRider',
          'howWePay1Rider',
          'guPowMiaRider'
        ]
      },
      // Idaho
      {
        states: ['ID'],
        certs: [
          'nwLevelTermL1L2Cert',
          'nwLevelTermL1L2ContributionRider',
          'nwFamilyRider',
          'levelTermL1Rates',
          'idChildRider',
          'howWePay1Rider'
        ]
      },
      // Kansas
      {
        states: ['KS'],
        certs: [
          'nwLevelTermL1L2Cert',
          'nwLevelTermL1L2ContributionRider',
          'nwFamilyRider',
          'levelTermL1Rates',
          'nwChildRider',
          'howWePay1Rider',
          'ksLevelTermL1L2AboRider'
        ]
      },
      // Louisiana
      {
        states: ['LA'],
        certs: [
          'laLevelTermL1L2Cert',
          'laLevelTermL1L2ContributionRider',
          'laFamilyRider',
          'levelTermL1Rates',
          'laChildRider',
          'howWePay1Rider'
        ]
      },
      // Maryland
      {
        states: ['MD'],
        certs: [
          'mdLevelTermL1L2Cert',
          'mdLevelTermL1L2ContributionRider',
          'mdFamilyRider',
          'levelTermL1Rates',
          'nwChildRider',
          'howWePay2Rider'
        ]
      },
      // Montana
      {
        states: ['MT'],
        certs: [
          'mtLevelTermL1L2Cert',
          'nwLevelTermL1L2ContributionRider',
          'nwFamilyRider',
          'levelTermL1Rates',
          'mtChildRider',
          'howWePay1Rider'
        ]
      },
      // North Carolina
      {
        states: ['NC'],
        certs: [
          'ncLevelTermL1L2Cert',
          'nwLevelTermL1L2ContributionRider',
          'nwFamilyRider',
          'levelTermL1Rates',
          'nwChildRider',
          'howWePay1Rider'
        ]
      },
      // New Hampshire
      {
        states: ['NH'],
        certs: [
          'nhLevelTermL1L2Cert',
          'nwLevelTermL1L2ContributionRider',
          'nwFamilyRider',
          'levelTermL1Rates',
          'nwChildRider',
          'howWePay1Rider'
        ]
      },
      // New Mexico
      {
        states: ['NM'],
        certs: [
          'nwLevelTermL1L2Cert',
          'nwLevelTermL1L2ContributionRider',
          'nwFamilyRider',
          'levelTermL1Rates',
          'nmChildRider',
          'howWePay1Rider'
        ]
      },
      // New York
      {
        states: ['NY'],
        certs: [
          'nyLevelTermL1L2Cert',
          'nwLevelTermL1L2ContributionRider',
          'nyFamilyRider',
          'levelTermL1Rates',
          'nyChildRider',
          'howWePay1Rider'
        ]
      },
      // Puerto Rico
      {
        states: ['PR'],
        certs: [
          'nwLevelTermL1L2Cert',
          'nwLevelTermL1L2ContributionRider',
          'nwFamilyRider',
          'levelTermL1Rates',
          'nwChildRider',
          'howWePay1Rider',
          'prPowMiaRider'
        ]
      },
      // Texas
      {
        states: ['TX'],
        certs: [
          'txLevelTermL1L2Cert',
          'txLevelTermL1L2ContributionRider',
          'txFamilyRider',
          'levelTermL1Rates',
          'txChildRider',
          'howWePay1Rider'
        ]
      },
      // Utah
      {
        states: ['UT'],
        certs: [
          'utLevelTermL1L2Cert',
          'utLevelTermL1L2ContributionRider',
          'nwFamilyRider',
          'levelTermL1Rates',
          'nwChildRider',
          'howWePay1Rider'
        ]
      },
      // Washington
      {
        states: ['WA'],
        certs: [
          'waLevelTermL1L2Cert',
          'nwLevelTermL1L2ContributionRider',
          'waFamilyRider',
          'levelTermL1Rates',
          'waChildRider',
          'howWePay2Rider'
        ]
      },
      {
        states: ['AR', 'CT', 'DE', 'FL', 'MI', 'NJ', 'OH', 'RI'],
        certs: [
          'nwLevelTermL1L2Cert',
          'nwLevelTermL1L2ContributionRider',
          'nwFamilyRider',
          'levelTermL1Rates',
          'nwChildRider',
          'howWePay2Rider'
        ]
      }
    ]
  },
  l2: {
    nationalWideConfig: [
      'nwLevelTermL1L2Cert',
      'nwLevelTermL1L2ContributionRider',
      'nwFamilyRider',
      'levelTermL2Rates',
      'nwChildRider',
      'howWePay1Rider'
    ],
    stateSpecificConfig: [
      // Alaska
      {
        states: ['AK'],
        certs: [
          'akLevelTermL1L2Cert',
          'nwLevelTermL1L2ContributionRider',
          'akFamilyRider',
          'levelTermL2Rates',
          'akChildRider',
          'howWePay1Rider'
        ]
      },
      // California
      {
        states: ['CA'],
        certs: [
          'nwLevelTermL1L2Cert',
          'nwLevelTermL1L2ContributionRider',
          'nwFamilyRider',
          'levelTermL2Rates',
          'nwChildRider',
          'howWePay1Rider',
          'caProofRider'
        ]
      },
      // Guam
      {
        states: ['GU'],
        certs: [
          'nwLevelTermL1L2Cert',
          'nwLevelTermL1L2ContributionRider',
          'nwFamilyRider',
          'levelTermL2Rates',
          'nwChildRider',
          'howWePay1Rider',
          'guPowMiaRider'
        ]
      },
      // Idaho
      {
        states: ['ID'],
        certs: [
          'nwLevelTermL1L2Cert',
          'nwLevelTermL1L2ContributionRider',
          'nwFamilyRider',
          'levelTermL2Rates',
          'idChildRider',
          'howWePay1Rider'
        ]
      },
      // Kansas
      {
        states: ['KS'],
        certs: [
          'nwLevelTermL1L2Cert',
          'nwLevelTermL1L2ContributionRider',
          'nwFamilyRider',
          'levelTermL2Rates',
          'nwChildRider',
          'howWePay1Rider',
          'ksLevelTermL1L2AboRider'
        ]
      },
      // Louisiana
      {
        states: ['LA'],
        certs: [
          'laLevelTermL1L2Cert',
          'laLevelTermL1L2ContributionRider',
          'laFamilyRider',
          'levelTermL2Rates',
          'laChildRider',
          'howWePay1Rider'
        ]
      },
      // Maryland
      {
        states: ['MD'],
        certs: [
          'mdLevelTermL1L2Cert',
          'mdLevelTermL1L2ContributionRider',
          'mdFamilyRider',
          'levelTermL2Rates',
          'nwChildRider',
          'howWePay2Rider'
        ]
      },
      // Montana
      {
        states: ['MT'],
        certs: [
          'mtLevelTermL1L2Cert',
          'nwLevelTermL1L2ContributionRider',
          'nwFamilyRider',
          'levelTermL2Rates',
          'mtChildRider',
          'howWePay1Rider'
        ]
      },
      // North Carolina
      {
        states: ['NC'],
        certs: [
          'ncLevelTermL1L2Cert',
          'nwLevelTermL1L2ContributionRider',
          'nwFamilyRider',
          'levelTermL2Rates',
          'nwChildRider',
          'howWePay1Rider'
        ]
      },
      // New Hampshire
      {
        states: ['NH'],
        certs: [
          'nhLevelTermL1L2Cert',
          'nwLevelTermL1L2ContributionRider',
          'nwFamilyRider',
          'levelTermL2Rates',
          'nwChildRider',
          'howWePay1Rider'
        ]
      },
      // New Mexico
      {
        states: ['NM'],
        certs: [
          'nwLevelTermL1L2Cert',
          'nwLevelTermL1L2ContributionRider',
          'nwFamilyRider',
          'levelTermL2Rates',
          'nmChildRider',
          'howWePay1Rider'
        ]
      },
      // New York
      {
        states: ['NY'],
        certs: [
          'nyLevelTermL1L2Cert',
          'nwLevelTermL1L2ContributionRider',
          'nyFamilyRider',
          'levelTermL2Rates',
          'nyChildRider',
          'howWePay1Rider'
        ]
      },
      // Puerto Rico
      {
        states: ['PR'],
        certs: [
          'nwLevelTermL1L2Cert',
          'nwLevelTermL1L2ContributionRider',
          'nwFamilyRider',
          'levelTermL2Rates',
          'nwChildRider',
          'howWePay1Rider',
          'prPowMiaRider'
        ]
      },
      // Texas
      {
        states: ['TX'],
        certs: [
          'txLevelTermL1L2Cert',
          'txLevelTermL1L2ContributionRider',
          'txFamilyRider',
          'levelTermL2Rates',
          'txChildRider',
          'howWePay1Rider'
        ]
      },
      // Utah
      {
        states: ['UT'],
        certs: [
          'utLevelTermL1L2Cert',
          'utLevelTermL1L2ContributionRider',
          'nwFamilyRider',
          'levelTermL2Rates',
          'nwChildRider',
          'howWePay1Rider'
        ]
      },
      // Washington
      {
        states: ['WA'],
        certs: [
          'waLevelTermL1L2Cert',
          'nwLevelTermL1L2ContributionRider',
          'waFamilyRider',
          'levelTermL2Rates',
          'waChildRider',
          'howWePay2Rider'
        ]
      },
      {
        states: ['AR', 'CT', 'DE', 'FL', 'MI', 'NJ', 'OH', 'RI'],
        certs: [
          'nwLevelTermL1L2Cert',
          'nwLevelTermL1L2ContributionRider',
          'nwFamilyRider',
          'levelTermL2Rates',
          'nwChildRider',
          'howWePay2Rider'
        ]
      }
    ]
  }
};

export function getCertificates(state, params = {}) {
  let { type = '', policyType = '' } = params;

  const { certificates } = certConfig;

  if (!type && policyType) {
    type = policyType === 'L2' ? 'l2' : 'l1';
  }

  const { stateSpecificConfig, nationalWideConfig } =
    type === 'l2' ? certConfig.l2 : certConfig.l1;

  const stateConfig = getCertificatesHelpers.getStateSpecificConfig({
    state,
    stateSpecificConfig
  });

  return {
    nw: getCertificatesHelpers.getCertificates({
      certsConfig: nationalWideConfig,
      certificates
    }),
    certs: getCertificatesHelpers.getCertificates({
      certsConfig: stateConfig,
      certificates
    })
  };
}
