import { getFileLink } from '../helpers/resources';

const familyRiderUnderline = '3F & AF only';

export const certificates = {
  nwChildRider: {
    link: getFileLink('file-cert-nw-child-rider'),
    label: 'NW Child Rider'
  },
  nwVeteranRider: {
    link: getFileLink('file-cert-nw-veteran-rider'),
    label: 'NW Veteran Rider'
  },
  nwFamilyRider: {
    link: getFileLink('file-cert-nw-family-rider'),
    label: 'NW Family Rider',
    underline: familyRiderUnderline
  },
  akChildRider: {
    link: getFileLink('file-cert-ak-child-rider'),
    label: 'AK Child Rider'
  },
  akFamilyRider: {
    link: getFileLink('file-cert-ak-family-rider'),
    label: 'AK Family Rider',
    underline: familyRiderUnderline
  },
  caProofRider: {
    link: getFileLink('file-cert-ca-proof-rider'),
    label: 'CA Proof Rider'
  },
  guPowMiaRider: {
    link: getFileLink('file-cert-gu-pow-mia-rider'),
    label: 'GU Pow Mia Rider'
  },
  idChildRider: {
    link: getFileLink('file-cert-id-child-rider'),
    label: 'ID Child Rider'
  },
  idVeteranRider: {
    link: getFileLink('file-cert-id-veteran-rider'),
    label: 'ID Veteran Rider'
  },
  laChildRider: {
    link: getFileLink('file-cert-la-child-rider'),
    label: 'LA Child Rider'
  },
  laVeteranRider: {
    link: getFileLink('file-cert-la-veteran-rider'),
    label: 'LA Veteran Rider'
  },
  laFamilyRider: {
    link: getFileLink('file-cert-la-family-rider'),
    label: 'LA Family Rider',
    underline: familyRiderUnderline
  },
  mdVeteranRider: {
    link: getFileLink('file-cert-md-veteran-rider'),
    label: 'MD Veteran Rider'
  },
  mdFamilyRider: {
    link: getFileLink('file-cert-md-family-rider'),
    label: 'MD Family Rider',
    underline: familyRiderUnderline
  },
  mtChildRider: {
    link: getFileLink('file-cert-mt-child-rider'),
    label: 'MT Child Rider'
  },
  mtVeteranRider: {
    link: getFileLink('file-cert-mt-veteran-rider'),
    label: 'MT Veteran Rider'
  },
  nmChildRider: {
    link: getFileLink('file-cert-nm-child-rider'),
    label: 'NM Child Rider'
  },
  nyChildRider: {
    link: getFileLink('file-cert-ny-child-rider'),
    label: 'NY Child Rider'
  },
  nyVeteranRider: {
    link: getFileLink('file-cert-ny-veteran-rider'),
    label: 'NY Veteran Rider'
  },
  nyFamilyRider: {
    link: getFileLink('file-cert-ny-family-rider'),
    label: 'NY Family Rider',
    underline: familyRiderUnderline
  },
  prPowMiaRider: {
    link: getFileLink('file-cert-pr-pow-mia-rider'),
    label: 'PR Pow Mia Rider'
  },
  txChildRider: {
    link: getFileLink('file-cert-tx-child-rider'),
    label: 'TX Child Rider'
  },
  txVeteranRider: {
    link: getFileLink('file-cert-tx-veteran-rider'),
    label: 'TX Veteran Rider'
  },
  txFamilyRider: {
    link: getFileLink('file-cert-tx-family-rider'),
    label: 'TX Family Rider',
    underline: familyRiderUnderline
  },
  utVeteranRider: {
    link: getFileLink('file-cert-ut-veteran-rider'),
    label: 'UT Veteran Rider'
  },
  waChildRider: {
    link: getFileLink('file-cert-wa-child-rider'),
    label: 'WA Child Rider'
  },
  waVeteranRider: {
    link: getFileLink('file-cert-wa-veteran-rider'),
    label: 'WA Veteran Rider'
  },
  waDisclosureRider: {
    link: getFileLink('file-cert-wa-disclosure-rider'),
    label: 'WA Disclosure Rider'
  },
  waFamilyRider: {
    link: getFileLink('file-cert-wa-family-rider'),
    label: 'WA Family Rider',
    underline: familyRiderUnderline
  },
  howWePay1Rider: {
    link: getFileLink('file-cert-nw-how-we-pay-1-rider'),
    label: 'NW How We Pay Rider'
  },
  howWePay2Rider: {
    link: getFileLink('file-cert-how-we-pay-2-rider'),
    label: 'How We Pay Rider'
  }
};
