import _merge from 'lodash/merge';
import Cookies from 'universal-cookie';

const defaultOptions = { path: '/' };

export const get = (name, options = {}) => {
  const cookies = new Cookies();
  cookies.get(name, _merge(options, defaultOptions));
};

export const getAll = (names, options = {}) => {
  const cookies = new Cookies();
  const allCookies = cookies.getAll(_merge(options, defaultOptions));
  return names.reduce((memo, name) => {
    if (allCookies[name]) {
      memo[name] = allCookies[name];
    }
    return memo;
  }, {});
};

export const set = (name, value, options = {}) => {
  const cookies = new Cookies();
  cookies.remove(name);
  cookies.set(name, value, _merge(defaultOptions, options));
};

export const setAll = (names, data, options) => {
  const cookies = new Cookies();
  names.forEach(name => {
    if (data[name]) {
      cookies.remove(name);
      cookies.set(name, data[name], _merge(defaultOptions, options));
    }
  });
};

export const remove = (name, options = {}) => {
  const cookies = new Cookies();
  cookies.remove(name, _merge(options, defaultOptions));
};

export const removeAll = (names, options = {}) => {
  const cookies = new Cookies();
  options = _merge(options, defaultOptions);
  names.forEach(name => cookies.remove(name, options));
};
