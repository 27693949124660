/*
 * Data structure is plan -> gender -> term -> age -> coverage-> cost
 */

const rates = {
  standart: {
    M: {
      10: {
        20: [
          {
            min: 50000,
            max: 200000,
            cost: 9.5,
            schedule: 'A00'
          },
          {
            min: 250000,
            max: 600000,
            cost: 8.63,
            schedule: 'A50'
          }
        ],
        21: [
          {
            min: 50000,
            max: 200000,
            cost: 9.5,
            schedule: 'A01'
          },
          {
            min: 250000,
            max: 600000,
            cost: 8.63,
            schedule: 'A51'
          }
        ],
        22: [
          {
            min: 50000,
            max: 200000,
            cost: 9.5,
            schedule: 'A02'
          },
          {
            min: 250000,
            max: 600000,
            cost: 8.63,
            schedule: 'A52'
          }
        ],
        23: [
          {
            min: 50000,
            max: 200000,
            cost: 9.5,
            schedule: 'A03'
          },
          {
            min: 250000,
            max: 600000,
            cost: 8.67,
            schedule: 'A53'
          }
        ],
        24: [
          {
            min: 50000,
            max: 200000,
            cost: 9.54,
            schedule: 'A04'
          },
          {
            min: 250000,
            max: 600000,
            cost: 8.75,
            schedule: 'A54'
          }
        ],
        25: [
          {
            min: 50000,
            max: 200000,
            cost: 9.54,
            schedule: 'A05'
          },
          {
            min: 250000,
            max: 600000,
            cost: 8.75,
            schedule: 'A55'
          }
        ],
        26: [
          {
            min: 50000,
            max: 200000,
            cost: 9.58,
            schedule: 'A06'
          },
          {
            min: 250000,
            max: 600000,
            cost: 8.79,
            schedule: 'A56'
          }
        ],
        27: [
          {
            min: 50000,
            max: 200000,
            cost: 9.58,
            schedule: 'A07'
          },
          {
            min: 250000,
            max: 600000,
            cost: 8.79,
            schedule: 'A57'
          }
        ],
        28: [
          {
            min: 50000,
            max: 200000,
            cost: 9.63,
            schedule: 'A08'
          },
          {
            min: 250000,
            max: 600000,
            cost: 8.83,
            schedule: 'A58'
          }
        ],
        29: [
          {
            min: 50000,
            max: 200000,
            cost: 9.75,
            schedule: 'A09'
          },
          {
            min: 250000,
            max: 600000,
            cost: 8.92,
            schedule: 'A59'
          }
        ],
        30: [
          {
            min: 50000,
            max: 200000,
            cost: 9.79,
            schedule: 'A10'
          },
          {
            min: 250000,
            max: 600000,
            cost: 8.96,
            schedule: 'A60'
          }
        ],
        31: [
          {
            min: 50000,
            max: 200000,
            cost: 9.79,
            schedule: 'A11'
          },
          {
            min: 250000,
            max: 600000,
            cost: 8.96,
            schedule: 'A61'
          }
        ],
        32: [
          {
            min: 50000,
            max: 200000,
            cost: 9.79,
            schedule: 'A12'
          },
          {
            min: 250000,
            max: 600000,
            cost: 8.96,
            schedule: 'A62'
          }
        ],
        33: [
          {
            min: 50000,
            max: 200000,
            cost: 9.79,
            schedule: 'A13'
          },
          {
            min: 250000,
            max: 600000,
            cost: 8.96,
            schedule: 'A63'
          }
        ],
        34: [
          {
            min: 50000,
            max: 200000,
            cost: 9.79,
            schedule: 'A14'
          },
          {
            min: 250000,
            max: 600000,
            cost: 9,
            schedule: 'A64'
          }
        ],
        35: [
          {
            min: 50000,
            max: 200000,
            cost: 10.08,
            schedule: 'A15'
          },
          {
            min: 250000,
            max: 600000,
            cost: 9.33,
            schedule: 'A65'
          }
        ],
        36: [
          {
            min: 50000,
            max: 200000,
            cost: 10.54,
            schedule: 'A16'
          },
          {
            min: 250000,
            max: 600000,
            cost: 9.75,
            schedule: 'A66'
          }
        ],
        37: [
          {
            min: 50000,
            max: 200000,
            cost: 11.17,
            schedule: 'A17'
          },
          {
            min: 250000,
            max: 600000,
            cost: 10.42,
            schedule: 'A67'
          }
        ],
        38: [
          {
            min: 50000,
            max: 200000,
            cost: 11.88,
            schedule: 'A18'
          },
          {
            min: 250000,
            max: 600000,
            cost: 11.17,
            schedule: 'A68'
          }
        ],
        39: [
          {
            min: 50000,
            max: 200000,
            cost: 12.88,
            schedule: 'A19'
          },
          {
            min: 250000,
            max: 600000,
            cost: 12.21,
            schedule: 'A69'
          }
        ],
        40: [
          {
            min: 50000,
            max: 200000,
            cost: 13.88,
            schedule: 'A20'
          },
          {
            min: 250000,
            max: 600000,
            cost: 13.29,
            schedule: 'A70'
          }
        ],
        41: [
          {
            min: 50000,
            max: 200000,
            cost: 15.17,
            schedule: 'A21'
          },
          {
            min: 250000,
            max: 600000,
            cost: 14.63,
            schedule: 'A71'
          }
        ],
        42: [
          {
            min: 50000,
            max: 200000,
            cost: 16.67,
            schedule: 'A22'
          },
          {
            min: 250000,
            max: 600000,
            cost: 16.21,
            schedule: 'A72'
          }
        ],
        43: [
          {
            min: 50000,
            max: 200000,
            cost: 18.38,
            schedule: 'A23'
          },
          {
            min: 250000,
            max: 600000,
            cost: 17.92,
            schedule: 'A73'
          }
        ],
        44: [
          {
            min: 50000,
            max: 200000,
            cost: 20.25,
            schedule: 'A24'
          },
          {
            min: 250000,
            max: 600000,
            cost: 19.83,
            schedule: 'A74'
          }
        ],
        45: [
          {
            min: 50000,
            max: 200000,
            cost: 23.23,
            schedule: 'A25'
          },
          {
            min: 250000,
            max: 600000,
            cost: 21.83,
            schedule: 'A75'
          }
        ],
        46: [
          {
            min: 50000,
            max: 200000,
            cost: 25.55,
            schedule: 'A26'
          },
          {
            min: 250000,
            max: 600000,
            cost: 24.08,
            schedule: 'A76'
          }
        ],
        47: [
          {
            min: 50000,
            max: 200000,
            cost: 28,
            schedule: 'A27'
          },
          {
            min: 250000,
            max: 600000,
            cost: 26.63,
            schedule: 'A77'
          }
        ],
        48: [
          {
            min: 50000,
            max: 200000,
            cost: 30.63,
            schedule: 'A28'
          },
          {
            min: 250000,
            max: 600000,
            cost: 29.17,
            schedule: 'A78'
          }
        ],
        49: [
          {
            min: 50000,
            max: 200000,
            cost: 33.38,
            schedule: 'A29'
          },
          {
            min: 250000,
            max: 600000,
            cost: 31.88,
            schedule: 'A79'
          }
        ],
        50: [
          {
            min: 50000,
            max: 200000,
            cost: 36.09,
            schedule: 'A30'
          },
          {
            min: 250000,
            max: 600000,
            cost: 34.58,
            schedule: 'A80'
          }
        ],
        51: [
          {
            min: 50000,
            max: 200000,
            cost: 38.76,
            schedule: 'A31'
          },
          {
            min: 250000,
            max: 600000,
            cost: 37.21,
            schedule: 'A81'
          }
        ],
        52: [
          {
            min: 50000,
            max: 200000,
            cost: 41.34,
            schedule: 'A32'
          },
          {
            min: 250000,
            max: 600000,
            cost: 39.79,
            schedule: 'A82'
          }
        ],
        53: [
          {
            min: 50000,
            max: 200000,
            cost: 44.1,
            schedule: 'A33'
          },
          {
            min: 250000,
            max: 600000,
            cost: 42.5,
            schedule: 'A83'
          }
        ],
        54: [
          {
            min: 50000,
            max: 200000,
            cost: 47.12,
            schedule: 'A34'
          },
          {
            min: 250000,
            max: 600000,
            cost: 45.5,
            schedule: 'A84'
          }
        ],
        55: [
          {
            min: 50000,
            max: 200000,
            cost: 50.66,
            schedule: 'A35'
          },
          {
            min: 250000,
            max: 600000,
            cost: 48.96,
            schedule: 'A85'
          }
        ],
        56: [
          {
            min: 50000,
            max: 200000,
            cost: 54.6,
            schedule: 'A36'
          },
          {
            min: 250000,
            max: 600000,
            cost: 52.79,
            schedule: 'A86'
          }
        ],
        57: [
          {
            min: 50000,
            max: 200000,
            cost: 58.71,
            schedule: 'A37'
          },
          {
            min: 250000,
            max: 600000,
            cost: 56.96,
            schedule: 'A87'
          }
        ],
        58: [
          {
            min: 50000,
            max: 200000,
            cost: 63.44,
            schedule: 'A38'
          },
          {
            min: 250000,
            max: 600000,
            cost: 61.67,
            schedule: 'A88'
          }
        ],
        59: [
          {
            min: 50000,
            max: 200000,
            cost: 69.04,
            schedule: 'A39'
          },
          {
            min: 250000,
            max: 600000,
            cost: 67.13,
            schedule: 'A89'
          }
        ],
        60: [
          {
            min: 50000,
            max: 200000,
            cost: 75.47,
            schedule: 'A40'
          },
          {
            min: 250000,
            max: 600000,
            cost: 73.54,
            schedule: 'A90'
          }
        ],
        61: [
          {
            min: 50000,
            max: 200000,
            cost: 82.56,
            schedule: 'A41'
          },
          {
            min: 250000,
            max: 600000,
            cost: 80.58,
            schedule: 'A91'
          }
        ],
        62: [
          {
            min: 50000,
            max: 200000,
            cost: 90.21,
            schedule: 'A42'
          },
          {
            min: 250000,
            max: 600000,
            cost: 88.17,
            schedule: 'A92'
          }
        ],
        63: [
          {
            min: 50000,
            max: 200000,
            cost: 99.27,
            schedule: 'A43'
          },
          {
            min: 250000,
            max: 600000,
            cost: 97.04,
            schedule: 'A93'
          }
        ],
        64: [
          {
            min: 50000,
            max: 200000,
            cost: 110.29,
            schedule: 'A44'
          },
          {
            min: 250000,
            max: 600000,
            cost: 108.21,
            schedule: 'A94'
          }
        ]
      },
      20: {
        20: [
          {
            min: 50000,
            max: 200000,
            cost: 12.21,
            schedule: 'E00'
          },
          {
            min: 250000,
            max: 600000,
            cost: 9.83,
            schedule: 'E50'
          }
        ],
        21: [
          {
            min: 50000,
            max: 200000,
            cost: 12.21,
            schedule: 'E01'
          },
          {
            min: 250000,
            max: 600000,
            cost: 9.83,
            schedule: 'E51'
          }
        ],
        22: [
          {
            min: 50000,
            max: 200000,
            cost: 12.21,
            schedule: 'E02'
          },
          {
            min: 250000,
            max: 600000,
            cost: 9.83,
            schedule: 'E52'
          }
        ],
        23: [
          {
            min: 50000,
            max: 200000,
            cost: 12.21,
            schedule: 'E03'
          },
          {
            min: 250000,
            max: 600000,
            cost: 9.83,
            schedule: 'E53'
          }
        ],
        24: [
          {
            min: 50000,
            max: 200000,
            cost: 12.21,
            schedule: 'E04'
          },
          {
            min: 250000,
            max: 600000,
            cost: 9.83,
            schedule: 'E54'
          }
        ],
        25: [
          {
            min: 50000,
            max: 200000,
            cost: 12.21,
            schedule: 'E05'
          },
          {
            min: 250000,
            max: 600000,
            cost: 9.83,
            schedule: 'E55'
          }
        ],
        26: [
          {
            min: 50000,
            max: 200000,
            cost: 12.21,
            schedule: 'E06'
          },
          {
            min: 250000,
            max: 600000,
            cost: 9.83,
            schedule: 'E56'
          }
        ],
        27: [
          {
            min: 50000,
            max: 200000,
            cost: 12.33,
            schedule: 'E07'
          },
          {
            min: 250000,
            max: 600000,
            cost: 9.92,
            schedule: 'E57'
          }
        ],
        28: [
          {
            min: 50000,
            max: 200000,
            cost: 12.46,
            schedule: 'E08'
          },
          {
            min: 250000,
            max: 600000,
            cost: 10,
            schedule: 'E58'
          }
        ],
        29: [
          {
            min: 50000,
            max: 200000,
            cost: 12.58,
            schedule: 'E09'
          },
          {
            min: 250000,
            max: 600000,
            cost: 10.17,
            schedule: 'E59'
          }
        ],
        30: [
          {
            min: 50000,
            max: 200000,
            cost: 12.96,
            schedule: 'E10'
          },
          {
            min: 250000,
            max: 600000,
            cost: 10.46,
            schedule: 'E60'
          }
        ],
        31: [
          {
            min: 50000,
            max: 200000,
            cost: 13.5,
            schedule: 'E11'
          },
          {
            min: 250000,
            max: 600000,
            cost: 10.92,
            schedule: 'E61'
          }
        ],
        32: [
          {
            min: 50000,
            max: 200000,
            cost: 14.08,
            schedule: 'E12'
          },
          {
            min: 250000,
            max: 600000,
            cost: 11.46,
            schedule: 'E62'
          }
        ],
        33: [
          {
            min: 50000,
            max: 200000,
            cost: 14.88,
            schedule: 'E13'
          },
          {
            min: 250000,
            max: 600000,
            cost: 12.17,
            schedule: 'E63'
          }
        ],
        34: [
          {
            min: 50000,
            max: 200000,
            cost: 15.71,
            schedule: 'E14'
          },
          {
            min: 250000,
            max: 600000,
            cost: 12.83,
            schedule: 'E64'
          }
        ],
        35: [
          {
            min: 50000,
            max: 200000,
            cost: 16.63,
            schedule: 'E15'
          },
          {
            min: 250000,
            max: 600000,
            cost: 13.63,
            schedule: 'E65'
          }
        ],
        36: [
          {
            min: 50000,
            max: 200000,
            cost: 17.46,
            schedule: 'E16'
          },
          {
            min: 250000,
            max: 600000,
            cost: 14.38,
            schedule: 'E66'
          }
        ],
        37: [
          {
            min: 50000,
            max: 200000,
            cost: 18.29,
            schedule: 'E17'
          },
          {
            min: 250000,
            max: 600000,
            cost: 15.13,
            schedule: 'E67'
          }
        ],
        38: [
          {
            min: 50000,
            max: 200000,
            cost: 19.33,
            schedule: 'E18'
          },
          {
            min: 250000,
            max: 600000,
            cost: 16,
            schedule: 'E68'
          }
        ],
        39: [
          {
            min: 50000,
            max: 200000,
            cost: 20.58,
            schedule: 'E19'
          },
          {
            min: 250000,
            max: 600000,
            cost: 17.13,
            schedule: 'E69'
          }
        ],
        40: [
          {
            min: 50000,
            max: 200000,
            cost: 22.33,
            schedule: 'E20'
          },
          {
            min: 250000,
            max: 600000,
            cost: 18.58,
            schedule: 'E70'
          }
        ],
        41: [
          {
            min: 50000,
            max: 200000,
            cost: 24.63,
            schedule: 'E21'
          },
          {
            min: 250000,
            max: 600000,
            cost: 20.63,
            schedule: 'E71'
          }
        ],
        42: [
          {
            min: 50000,
            max: 200000,
            cost: 27.5,
            schedule: 'E22'
          },
          {
            min: 250000,
            max: 600000,
            cost: 23.13,
            schedule: 'E72'
          }
        ],
        43: [
          {
            min: 50000,
            max: 200000,
            cost: 30.71,
            schedule: 'E23'
          },
          {
            min: 250000,
            max: 600000,
            cost: 25.88,
            schedule: 'E73'
          }
        ],
        44: [
          {
            min: 50000,
            max: 200000,
            cost: 34.13,
            schedule: 'E24'
          },
          {
            min: 250000,
            max: 600000,
            cost: 28.88,
            schedule: 'E74'
          }
        ],
        45: [
          {
            min: 50000,
            max: 200000,
            cost: 37.54,
            schedule: 'E25'
          },
          {
            min: 250000,
            max: 600000,
            cost: 31.83,
            schedule: 'E75'
          }
        ],
        46: [
          {
            min: 50000,
            max: 200000,
            cost: 40.96,
            schedule: 'E26'
          },
          {
            min: 250000,
            max: 600000,
            cost: 34.79,
            schedule: 'E76'
          }
        ],
        47: [
          {
            min: 50000,
            max: 200000,
            cost: 44.54,
            schedule: 'E27'
          },
          {
            min: 250000,
            max: 600000,
            cost: 37.92,
            schedule: 'E77'
          }
        ],
        48: [
          {
            min: 50000,
            max: 200000,
            cost: 48.29,
            schedule: 'E28'
          },
          {
            min: 250000,
            max: 600000,
            cost: 41.21,
            schedule: 'E78'
          }
        ],
        49: [
          {
            min: 50000,
            max: 200000,
            cost: 52.29,
            schedule: 'E29'
          },
          {
            min: 250000,
            max: 600000,
            cost: 44.67,
            schedule: 'E79'
          }
        ],
        50: [
          {
            min: 50000,
            max: 200000,
            cost: 56.54,
            schedule: 'E30'
          },
          {
            min: 250000,
            max: 600000,
            cost: 48.33,
            schedule: 'E80'
          }
        ],
        51: [
          {
            min: 50000,
            max: 200000,
            cost: 61.04,
            schedule: 'E31'
          },
          {
            min: 250000,
            max: 600000,
            cost: 52.25,
            schedule: 'E81'
          }
        ],
        52: [
          {
            min: 50000,
            max: 200000,
            cost: 65.96,
            schedule: 'E32'
          },
          {
            min: 250000,
            max: 600000,
            cost: 56.54,
            schedule: 'E82'
          }
        ],
        53: [
          {
            min: 50000,
            max: 200000,
            cost: 71,
            schedule: 'E33'
          },
          {
            min: 250000,
            max: 600000,
            cost: 60.96,
            schedule: 'E83'
          }
        ],
        54: [
          {
            min: 50000,
            max: 200000,
            cost: 76.38,
            schedule: 'E34'
          },
          {
            min: 250000,
            max: 600000,
            cost: 65.58,
            schedule: 'E84'
          }
        ]
      }
    },
    F: {
      10: {
        20: [
          {
            min: 50000,
            max: 200000,
            cost: 8.08,
            schedule: 'C00'
          },
          {
            min: 250000,
            max: 600000,
            cost: 7.25,
            schedule: 'C50'
          }
        ],
        21: [
          {
            min: 50000,
            max: 200000,
            cost: 8.08,
            schedule: 'C01'
          },
          {
            min: 250000,
            max: 600000,
            cost: 7.25,
            schedule: 'C51'
          }
        ],
        22: [
          {
            min: 50000,
            max: 200000,
            cost: 8.08,
            schedule: 'C02'
          },
          {
            min: 250000,
            max: 600000,
            cost: 7.25,
            schedule: 'C52'
          }
        ],
        23: [
          {
            min: 50000,
            max: 200000,
            cost: 8.08,
            schedule: 'C03'
          },
          {
            min: 250000,
            max: 600000,
            cost: 7.25,
            schedule: 'C53'
          }
        ],
        24: [
          {
            min: 50000,
            max: 200000,
            cost: 8.08,
            schedule: 'C04'
          },
          {
            min: 250000,
            max: 600000,
            cost: 7.25,
            schedule: 'C54'
          }
        ],
        25: [
          {
            min: 50000,
            max: 200000,
            cost: 8.08,
            schedule: 'C05'
          },
          {
            min: 250000,
            max: 600000,
            cost: 7.25,
            schedule: 'C55'
          }
        ],
        26: [
          {
            min: 50000,
            max: 200000,
            cost: 8.08,
            schedule: 'C06'
          },
          {
            min: 250000,
            max: 600000,
            cost: 7.25,
            schedule: 'C56'
          }
        ],
        27: [
          {
            min: 50000,
            max: 200000,
            cost: 8.08,
            schedule: 'C07'
          },
          {
            min: 250000,
            max: 600000,
            cost: 7.25,
            schedule: 'C57'
          }
        ],
        28: [
          {
            min: 50000,
            max: 200000,
            cost: 8.13,
            schedule: 'C08'
          },
          {
            min: 250000,
            max: 600000,
            cost: 7.29,
            schedule: 'C58'
          }
        ],
        29: [
          {
            min: 50000,
            max: 200000,
            cost: 8.13,
            schedule: 'C09'
          },
          {
            min: 250000,
            max: 600000,
            cost: 7.29,
            schedule: 'C59'
          }
        ],
        30: [
          {
            min: 50000,
            max: 200000,
            cost: 8.29,
            schedule: 'C10'
          },
          {
            min: 250000,
            max: 600000,
            cost: 7.38,
            schedule: 'C60'
          }
        ],
        31: [
          {
            min: 50000,
            max: 200000,
            cost: 8.29,
            schedule: 'C11'
          },
          {
            min: 250000,
            max: 600000,
            cost: 7.38,
            schedule: 'C61'
          }
        ],
        32: [
          {
            min: 50000,
            max: 200000,
            cost: 8.29,
            schedule: 'C12'
          },
          {
            min: 250000,
            max: 600000,
            cost: 7.38,
            schedule: 'C62'
          }
        ],
        33: [
          {
            min: 50000,
            max: 200000,
            cost: 8.29,
            schedule: 'C13'
          },
          {
            min: 250000,
            max: 600000,
            cost: 7.38,
            schedule: 'C63'
          }
        ],
        34: [
          {
            min: 50000,
            max: 200000,
            cost: 8.29,
            schedule: 'C14'
          },
          {
            min: 250000,
            max: 600000,
            cost: 7.38,
            schedule: 'C64'
          }
        ],
        35: [
          {
            min: 50000,
            max: 200000,
            cost: 8.42,
            schedule: 'C15'
          },
          {
            min: 250000,
            max: 600000,
            cost: 7.58,
            schedule: 'C65'
          }
        ],
        36: [
          {
            min: 50000,
            max: 200000,
            cost: 8.83,
            schedule: 'C16'
          },
          {
            min: 250000,
            max: 600000,
            cost: 8.04,
            schedule: 'C66'
          }
        ],
        37: [
          {
            min: 50000,
            max: 200000,
            cost: 9.5,
            schedule: 'C17'
          },
          {
            min: 250000,
            max: 600000,
            cost: 8.63,
            schedule: 'C67'
          }
        ],
        38: [
          {
            min: 50000,
            max: 200000,
            cost: 10.25,
            schedule: 'C18'
          },
          {
            min: 250000,
            max: 600000,
            cost: 9.46,
            schedule: 'C68'
          }
        ],
        39: [
          {
            min: 50000,
            max: 200000,
            cost: 11.17,
            schedule: 'C19'
          },
          {
            min: 250000,
            max: 600000,
            cost: 10.38,
            schedule: 'C69'
          }
        ],
        40: [
          {
            min: 50000,
            max: 200000,
            cost: 12,
            schedule: 'C20'
          },
          {
            min: 250000,
            max: 600000,
            cost: 11.21,
            schedule: 'C70'
          }
        ],
        41: [
          {
            min: 50000,
            max: 200000,
            cost: 12.88,
            schedule: 'C21'
          },
          {
            min: 250000,
            max: 600000,
            cost: 12.21,
            schedule: 'C71'
          }
        ],
        42: [
          {
            min: 50000,
            max: 200000,
            cost: 13.79,
            schedule: 'C22'
          },
          {
            min: 250000,
            max: 600000,
            cost: 13.17,
            schedule: 'C72'
          }
        ],
        43: [
          {
            min: 50000,
            max: 200000,
            cost: 14.88,
            schedule: 'C23'
          },
          {
            min: 250000,
            max: 600000,
            cost: 14.25,
            schedule: 'C73'
          }
        ],
        44: [
          {
            min: 50000,
            max: 200000,
            cost: 15.92,
            schedule: 'C24'
          },
          {
            min: 250000,
            max: 600000,
            cost: 15.38,
            schedule: 'C74'
          }
        ],
        45: [
          {
            min: 50000,
            max: 200000,
            cost: 17.13,
            schedule: 'C25'
          },
          {
            min: 250000,
            max: 600000,
            cost: 16.63,
            schedule: 'C75'
          }
        ],
        46: [
          {
            min: 50000,
            max: 200000,
            cost: 18.38,
            schedule: 'C26'
          },
          {
            min: 250000,
            max: 600000,
            cost: 17.96,
            schedule: 'C76'
          }
        ],
        47: [
          {
            min: 50000,
            max: 200000,
            cost: 19.75,
            schedule: 'C27'
          },
          {
            min: 250000,
            max: 600000,
            cost: 19.33,
            schedule: 'C77'
          }
        ],
        48: [
          {
            min: 50000,
            max: 200000,
            cost: 21.17,
            schedule: 'C28'
          },
          {
            min: 250000,
            max: 600000,
            cost: 20.79,
            schedule: 'C78'
          }
        ],
        49: [
          {
            min: 50000,
            max: 200000,
            cost: 22.63,
            schedule: 'C29'
          },
          {
            min: 250000,
            max: 600000,
            cost: 22.33,
            schedule: 'C79'
          }
        ],
        50: [
          {
            min: 50000,
            max: 200000,
            cost: 24.85,
            schedule: 'C30'
          },
          {
            min: 250000,
            max: 600000,
            cost: 23.92,
            schedule: 'C80'
          }
        ],
        51: [
          {
            min: 50000,
            max: 200000,
            cost: 26.44,
            schedule: 'C31'
          },
          {
            min: 250000,
            max: 600000,
            cost: 25.5,
            schedule: 'C81'
          }
        ],
        52: [
          {
            min: 50000,
            max: 200000,
            cost: 28.11,
            schedule: 'C32'
          },
          {
            min: 250000,
            max: 600000,
            cost: 27.21,
            schedule: 'C82'
          }
        ],
        53: [
          {
            min: 50000,
            max: 200000,
            cost: 29.78,
            schedule: 'C33'
          },
          {
            min: 250000,
            max: 600000,
            cost: 28.92,
            schedule: 'C83'
          }
        ],
        54: [
          {
            min: 50000,
            max: 200000,
            cost: 31.54,
            schedule: 'C34'
          },
          {
            min: 250000,
            max: 600000,
            cost: 30.63,
            schedule: 'C84'
          }
        ],
        55: [
          {
            min: 50000,
            max: 200000,
            cost: 33.35,
            schedule: 'C35'
          },
          {
            min: 250000,
            max: 600000,
            cost: 32.5,
            schedule: 'C85'
          }
        ],
        56: [
          {
            min: 50000,
            max: 200000,
            cost: 34.93,
            schedule: 'C36'
          },
          {
            min: 250000,
            max: 600000,
            cost: 34.13,
            schedule: 'C86'
          }
        ],
        57: [
          {
            min: 50000,
            max: 200000,
            cost: 36.48,
            schedule: 'C37'
          },
          {
            min: 250000,
            max: 600000,
            cost: 35.67,
            schedule: 'C87'
          }
        ],
        58: [
          {
            min: 50000,
            max: 200000,
            cost: 38.15,
            schedule: 'C38'
          },
          {
            min: 250000,
            max: 600000,
            cost: 37.33,
            schedule: 'C88'
          }
        ],
        59: [
          {
            min: 50000,
            max: 200000,
            cost: 40.26,
            schedule: 'C39'
          },
          {
            min: 250000,
            max: 600000,
            cost: 39.46,
            schedule: 'C89'
          }
        ],
        60: [
          {
            min: 50000,
            max: 200000,
            cost: 43.05,
            schedule: 'C40'
          },
          {
            min: 250000,
            max: 600000,
            cost: 42.25,
            schedule: 'C90'
          }
        ],
        61: [
          {
            min: 50000,
            max: 200000,
            cost: 46.61,
            schedule: 'C41'
          },
          {
            min: 250000,
            max: 600000,
            cost: 45.88,
            schedule: 'C91'
          }
        ],
        62: [
          {
            min: 50000,
            max: 200000,
            cost: 50.86,
            schedule: 'C42'
          },
          {
            min: 250000,
            max: 600000,
            cost: 50.21,
            schedule: 'C92'
          }
        ],
        63: [
          {
            min: 50000,
            max: 200000,
            cost: 55.75,
            schedule: 'C43'
          },
          {
            min: 250000,
            max: 600000,
            cost: 55.04,
            schedule: 'C93'
          }
        ],
        64: [
          {
            min: 50000,
            max: 200000,
            cost: 61.2,
            schedule: 'C44'
          },
          {
            min: 250000,
            max: 600000,
            cost: 60.58,
            schedule: 'C94'
          }
        ]
      },
      20: {
        20: [
          {
            min: 50000,
            max: 200000,
            cost: 8.92,
            schedule: 'G00'
          },
          {
            min: 250000,
            max: 600000,
            cost: 7.65,
            schedule: 'G50'
          }
        ],
        21: [
          {
            min: 50000,
            max: 200000,
            cost: 8.92,
            schedule: 'G01'
          },
          {
            min: 250000,
            max: 600000,
            cost: 7.65,
            schedule: 'G51'
          }
        ],
        22: [
          {
            min: 50000,
            max: 200000,
            cost: 8.92,
            schedule: 'G02'
          },
          {
            min: 250000,
            max: 600000,
            cost: 7.65,
            schedule: 'G52'
          }
        ],
        23: [
          {
            min: 50000,
            max: 200000,
            cost: 8.92,
            schedule: 'G03'
          },
          {
            min: 250000,
            max: 600000,
            cost: 7.65,
            schedule: 'G53'
          }
        ],
        24: [
          {
            min: 50000,
            max: 200000,
            cost: 8.92,
            schedule: 'G04'
          },
          {
            min: 250000,
            max: 600000,
            cost: 7.65,
            schedule: 'G54'
          }
        ],
        25: [
          {
            min: 50000,
            max: 200000,
            cost: 8.92,
            schedule: 'G05'
          },
          {
            min: 250000,
            max: 600000,
            cost: 7.65,
            schedule: 'G55'
          }
        ],
        26: [
          {
            min: 50000,
            max: 200000,
            cost: 9.13,
            schedule: 'G06'
          },
          {
            min: 250000,
            max: 600000,
            cost: 7.84,
            schedule: 'G56'
          }
        ],
        27: [
          {
            min: 50000,
            max: 200000,
            cost: 9.42,
            schedule: 'G07'
          },
          {
            min: 250000,
            max: 600000,
            cost: 8.11,
            schedule: 'G57'
          }
        ],
        28: [
          {
            min: 50000,
            max: 200000,
            cost: 9.75,
            schedule: 'G08'
          },
          {
            min: 250000,
            max: 600000,
            cost: 8.2,
            schedule: 'G58'
          }
        ],
        29: [
          {
            min: 50000,
            max: 200000,
            cost: 10.17,
            schedule: 'G09'
          },
          {
            min: 250000,
            max: 600000,
            cost: 8.25,
            schedule: 'G59'
          }
        ],
        30: [
          {
            min: 50000,
            max: 200000,
            cost: 10.5,
            schedule: 'G10'
          },
          {
            min: 250000,
            max: 600000,
            cost: 8.33,
            schedule: 'G60'
          }
        ],
        31: [
          {
            min: 50000,
            max: 200000,
            cost: 10.88,
            schedule: 'G11'
          },
          {
            min: 250000,
            max: 600000,
            cost: 8.63,
            schedule: 'G61'
          }
        ],
        32: [
          {
            min: 50000,
            max: 200000,
            cost: 11.17,
            schedule: 'G12'
          },
          {
            min: 250000,
            max: 600000,
            cost: 8.88,
            schedule: 'G62'
          }
        ],
        33: [
          {
            min: 50000,
            max: 200000,
            cost: 11.46,
            schedule: 'G13'
          },
          {
            min: 250000,
            max: 600000,
            cost: 9.17,
            schedule: 'G63'
          }
        ],
        34: [
          {
            min: 50000,
            max: 200000,
            cost: 11.96,
            schedule: 'G14'
          },
          {
            min: 250000,
            max: 600000,
            cost: 9.58,
            schedule: 'G64'
          }
        ],
        35: [
          {
            min: 50000,
            max: 200000,
            cost: 12.54,
            schedule: 'G15'
          },
          {
            min: 250000,
            max: 600000,
            cost: 10.13,
            schedule: 'G65'
          }
        ],
        36: [
          {
            min: 50000,
            max: 200000,
            cost: 13.42,
            schedule: 'G16'
          },
          {
            min: 250000,
            max: 600000,
            cost: 10.88,
            schedule: 'G66'
          }
        ],
        37: [
          {
            min: 50000,
            max: 200000,
            cost: 14.46,
            schedule: 'G17'
          },
          {
            min: 250000,
            max: 600000,
            cost: 11.79,
            schedule: 'G67'
          }
        ],
        38: [
          {
            min: 50000,
            max: 200000,
            cost: 15.71,
            schedule: 'G18'
          },
          {
            min: 250000,
            max: 600000,
            cost: 12.83,
            schedule: 'G68'
          }
        ],
        39: [
          {
            min: 50000,
            max: 200000,
            cost: 16.96,
            schedule: 'G19'
          },
          {
            min: 250000,
            max: 600000,
            cost: 13.92,
            schedule: 'G69'
          }
        ],
        40: [
          {
            min: 50000,
            max: 200000,
            cost: 18.21,
            schedule: 'G20'
          },
          {
            min: 250000,
            max: 600000,
            cost: 15.04,
            schedule: 'G70'
          }
        ],
        41: [
          {
            min: 50000,
            max: 200000,
            cost: 19.42,
            schedule: 'G21'
          },
          {
            min: 250000,
            max: 600000,
            cost: 16.13,
            schedule: 'G71'
          }
        ],
        42: [
          {
            min: 50000,
            max: 200000,
            cost: 20.71,
            schedule: 'G22'
          },
          {
            min: 250000,
            max: 600000,
            cost: 17.21,
            schedule: 'G72'
          }
        ],
        43: [
          {
            min: 50000,
            max: 200000,
            cost: 21.96,
            schedule: 'G23'
          },
          {
            min: 250000,
            max: 600000,
            cost: 18.29,
            schedule: 'G73'
          }
        ],
        44: [
          {
            min: 50000,
            max: 200000,
            cost: 23.46,
            schedule: 'G24'
          },
          {
            min: 250000,
            max: 600000,
            cost: 19.58,
            schedule: 'G74'
          }
        ],
        45: [
          {
            min: 50000,
            max: 200000,
            cost: 25.04,
            schedule: 'G25'
          },
          {
            min: 250000,
            max: 600000,
            cost: 20.96,
            schedule: 'G75'
          }
        ],
        46: [
          {
            min: 50000,
            max: 200000,
            cost: 26.83,
            schedule: 'G26'
          },
          {
            min: 250000,
            max: 600000,
            cost: 22.5,
            schedule: 'G76'
          }
        ],
        47: [
          {
            min: 50000,
            max: 200000,
            cost: 28.75,
            schedule: 'G27'
          },
          {
            min: 250000,
            max: 600000,
            cost: 24.21,
            schedule: 'G77'
          }
        ],
        48: [
          {
            min: 50000,
            max: 200000,
            cost: 30.83,
            schedule: 'G28'
          },
          {
            min: 250000,
            max: 600000,
            cost: 26.04,
            schedule: 'G78'
          }
        ],
        49: [
          {
            min: 50000,
            max: 200000,
            cost: 33.13,
            schedule: 'G29'
          },
          {
            min: 250000,
            max: 600000,
            cost: 28,
            schedule: 'G79'
          }
        ],
        50: [
          {
            min: 50000,
            max: 200000,
            cost: 35.5,
            schedule: 'G30'
          },
          {
            min: 250000,
            max: 600000,
            cost: 30.04,
            schedule: 'G80'
          }
        ],
        51: [
          {
            min: 50000,
            max: 200000,
            cost: 37.92,
            schedule: 'G31'
          },
          {
            min: 250000,
            max: 600000,
            cost: 32.17,
            schedule: 'G81'
          }
        ],
        52: [
          {
            min: 50000,
            max: 200000,
            cost: 40.54,
            schedule: 'G32'
          },
          {
            min: 250000,
            max: 600000,
            cost: 34.46,
            schedule: 'G82'
          }
        ],
        53: [
          {
            min: 50000,
            max: 200000,
            cost: 43.33,
            schedule: 'G33'
          },
          {
            min: 250000,
            max: 600000,
            cost: 36.88,
            schedule: 'G83'
          }
        ],
        54: [
          {
            min: 50000,
            max: 200000,
            cost: 46.29,
            schedule: 'G34'
          },
          {
            min: 250000,
            max: 600000,
            cost: 39.46,
            schedule: 'G84'
          }
        ]
      }
    }
  },
  select: {
    M: {
      10: {
        20: [
          {
            min: 50000,
            max: 200000,
            cost: 3.61,
            schedule: 'A00'
          },
          {
            min: 250000,
            max: 600000,
            cost: 3.35,
            schedule: 'A50'
          }
        ],
        21: [
          {
            min: 50000,
            max: 200000,
            cost: 3.61,
            schedule: 'A01'
          },
          {
            min: 250000,
            max: 600000,
            cost: 3.35,
            schedule: 'A51'
          }
        ],
        22: [
          {
            min: 50000,
            max: 200000,
            cost: 3.61,
            schedule: 'A02'
          },
          {
            min: 250000,
            max: 600000,
            cost: 3.35,
            schedule: 'A52'
          }
        ],
        23: [
          {
            min: 50000,
            max: 200000,
            cost: 3.65,
            schedule: 'A03'
          },
          {
            min: 250000,
            max: 600000,
            cost: 3.39,
            schedule: 'A53'
          }
        ],
        24: [
          {
            min: 50000,
            max: 200000,
            cost: 3.65,
            schedule: 'A04'
          },
          {
            min: 250000,
            max: 600000,
            cost: 3.39,
            schedule: 'A54'
          }
        ],
        25: [
          {
            min: 50000,
            max: 200000,
            cost: 3.73,
            schedule: 'A05'
          },
          {
            min: 250000,
            max: 600000,
            cost: 3.47,
            schedule: 'A55'
          }
        ],
        26: [
          {
            min: 50000,
            max: 200000,
            cost: 3.73,
            schedule: 'A06'
          },
          {
            min: 250000,
            max: 600000,
            cost: 3.47,
            schedule: 'A56'
          }
        ],
        27: [
          {
            min: 50000,
            max: 200000,
            cost: 3.73,
            schedule: 'A07'
          },
          {
            min: 250000,
            max: 600000,
            cost: 3.47,
            schedule: 'A57'
          }
        ],
        28: [
          {
            min: 50000,
            max: 200000,
            cost: 3.73,
            schedule: 'A08'
          },
          {
            min: 250000,
            max: 600000,
            cost: 3.47,
            schedule: 'A58'
          }
        ],
        29: [
          {
            min: 50000,
            max: 200000,
            cost: 3.73,
            schedule: 'A09'
          },
          {
            min: 250000,
            max: 600000,
            cost: 3.47,
            schedule: 'A59'
          }
        ],
        30: [
          {
            min: 50000,
            max: 200000,
            cost: 3.77,
            schedule: 'A10'
          },
          {
            min: 250000,
            max: 600000,
            cost: 3.51,
            schedule: 'A60'
          }
        ],
        31: [
          {
            min: 50000,
            max: 200000,
            cost: 3.81,
            schedule: 'A11'
          },
          {
            min: 250000,
            max: 600000,
            cost: 3.55,
            schedule: 'A61'
          }
        ],
        32: [
          {
            min: 50000,
            max: 200000,
            cost: 3.85,
            schedule: 'A12'
          },
          {
            min: 250000,
            max: 600000,
            cost: 3.58,
            schedule: 'A62'
          }
        ],
        33: [
          {
            min: 50000,
            max: 200000,
            cost: 3.9,
            schedule: 'A13'
          },
          {
            min: 250000,
            max: 600000,
            cost: 3.62,
            schedule: 'A63'
          }
        ],
        34: [
          {
            min: 50000,
            max: 200000,
            cost: 3.9,
            schedule: 'A14'
          },
          {
            min: 250000,
            max: 600000,
            cost: 3.62,
            schedule: 'A64'
          }
        ],
        35: [
          {
            min: 50000,
            max: 200000,
            cost: 4.08,
            schedule: 'A15'
          },
          {
            min: 250000,
            max: 600000,
            cost: 3.79,
            schedule: 'A65'
          }
        ],
        36: [
          {
            min: 50000,
            max: 200000,
            cost: 4.08,
            schedule: 'A16'
          },
          {
            min: 250000,
            max: 600000,
            cost: 3.79,
            schedule: 'A66'
          }
        ],
        37: [
          {
            min: 50000,
            max: 200000,
            cost: 4.21,
            schedule: 'A17'
          },
          {
            min: 250000,
            max: 600000,
            cost: 3.91,
            schedule: 'A67'
          }
        ],
        38: [
          {
            min: 50000,
            max: 200000,
            cost: 4.56,
            schedule: 'A18'
          },
          {
            min: 250000,
            max: 600000,
            cost: 4.24,
            schedule: 'A68'
          }
        ],
        39: [
          {
            min: 50000,
            max: 200000,
            cost: 4.86,
            schedule: 'A19'
          },
          {
            min: 250000,
            max: 600000,
            cost: 4.52,
            schedule: 'A69'
          }
        ],
        40: [
          {
            min: 50000,
            max: 200000,
            cost: 5.25,
            schedule: 'A20'
          },
          {
            min: 250000,
            max: 600000,
            cost: 4.88,
            schedule: 'A70'
          }
        ],
        41: [
          {
            min: 50000,
            max: 200000,
            cost: 5.77,
            schedule: 'A21'
          },
          {
            min: 250000,
            max: 600000,
            cost: 5.37,
            schedule: 'A71'
          }
        ],
        42: [
          {
            min: 50000,
            max: 200000,
            cost: 6.2,
            schedule: 'A22'
          },
          {
            min: 250000,
            max: 600000,
            cost: 5.77,
            schedule: 'A72'
          }
        ],
        43: [
          {
            min: 50000,
            max: 200000,
            cost: 6.68,
            schedule: 'A23'
          },
          {
            min: 250000,
            max: 600000,
            cost: 6.21,
            schedule: 'A73'
          }
        ],
        44: [
          {
            min: 50000,
            max: 200000,
            cost: 7.25,
            schedule: 'A24'
          },
          {
            min: 250000,
            max: 600000,
            cost: 6.74,
            schedule: 'A74'
          }
        ],
        45: [
          {
            min: 50000,
            max: 200000,
            cost: 8.34,
            schedule: 'A25'
          },
          {
            min: 250000,
            max: 600000,
            cost: 7.76,
            schedule: 'A75'
          }
        ],
        46: [
          {
            min: 50000,
            max: 200000,
            cost: 9.28,
            schedule: 'A26'
          },
          {
            min: 250000,
            max: 600000,
            cost: 8.63,
            schedule: 'A76'
          }
        ],
        47: [
          {
            min: 50000,
            max: 200000,
            cost: 10.26,
            schedule: 'A27'
          },
          {
            min: 250000,
            max: 600000,
            cost: 9.55,
            schedule: 'A77'
          }
        ],
        48: [
          {
            min: 50000,
            max: 200000,
            cost: 11.25,
            schedule: 'A28'
          },
          {
            min: 250000,
            max: 600000,
            cost: 10.47,
            schedule: 'A78'
          }
        ],
        49: [
          {
            min: 50000,
            max: 200000,
            cost: 12.45,
            schedule: 'A29'
          },
          {
            min: 250000,
            max: 600000,
            cost: 11.58,
            schedule: 'A79'
          }
        ],
        50: [
          {
            min: 50000,
            max: 200000,
            cost: 14.2,
            schedule: 'A30'
          },
          {
            min: 250000,
            max: 600000,
            cost: 13.21,
            schedule: 'A80'
          }
        ],
        51: [
          {
            min: 50000,
            max: 200000,
            cost: 15.52,
            schedule: 'A31'
          },
          {
            min: 250000,
            max: 600000,
            cost: 14.44,
            schedule: 'A81'
          }
        ],
        52: [
          {
            min: 50000,
            max: 200000,
            cost: 16.69,
            schedule: 'A32'
          },
          {
            min: 250000,
            max: 600000,
            cost: 15.53,
            schedule: 'A82'
          }
        ],
        53: [
          {
            min: 50000,
            max: 200000,
            cost: 18.17,
            schedule: 'A33'
          },
          {
            min: 250000,
            max: 600000,
            cost: 16.9,
            schedule: 'A83'
          }
        ],
        54: [
          {
            min: 50000,
            max: 200000,
            cost: 19.8,
            schedule: 'A34'
          },
          {
            min: 250000,
            max: 600000,
            cost: 18.41,
            schedule: 'A84'
          }
        ],
        55: [
          {
            min: 50000,
            max: 200000,
            cost: 21.58,
            schedule: 'A35'
          },
          {
            min: 250000,
            max: 600000,
            cost: 20.07,
            schedule: 'A85'
          }
        ],
        56: [
          {
            min: 50000,
            max: 200000,
            cost: 23.51,
            schedule: 'A36'
          },
          {
            min: 250000,
            max: 600000,
            cost: 21.87,
            schedule: 'A86'
          }
        ],
        57: [
          {
            min: 50000,
            max: 200000,
            cost: 25.4,
            schedule: 'A37'
          },
          {
            min: 250000,
            max: 600000,
            cost: 23.62,
            schedule: 'A87'
          }
        ],
        58: [
          {
            min: 50000,
            max: 200000,
            cost: 27.79,
            schedule: 'A38'
          },
          {
            min: 250000,
            max: 600000,
            cost: 25.84,
            schedule: 'A88'
          }
        ],
        59: [
          {
            min: 50000,
            max: 200000,
            cost: 30.28,
            schedule: 'A39'
          },
          {
            min: 250000,
            max: 600000,
            cost: 28.16,
            schedule: 'A89'
          }
        ],
        60: [
          {
            min: 50000,
            max: 200000,
            cost: 33.64,
            schedule: 'A40'
          },
          {
            min: 250000,
            max: 600000,
            cost: 31.29,
            schedule: 'A90'
          }
        ],
        61: [
          {
            min: 50000,
            max: 200000,
            cost: 37.15,
            schedule: 'A41'
          },
          {
            min: 250000,
            max: 600000,
            cost: 34.55,
            schedule: 'A91'
          }
        ],
        62: [
          {
            min: 50000,
            max: 200000,
            cost: 40.92,
            schedule: 'A42'
          },
          {
            min: 250000,
            max: 600000,
            cost: 38.06,
            schedule: 'A92'
          }
        ],
        63: [
          {
            min: 50000,
            max: 200000,
            cost: 45.45,
            schedule: 'A43'
          },
          {
            min: 250000,
            max: 600000,
            cost: 42.27,
            schedule: 'A93'
          }
        ],
        64: [
          {
            min: 50000,
            max: 200000,
            cost: 50.54,
            schedule: 'A44'
          },
          {
            min: 250000,
            max: 600000,
            cost: 47,
            schedule: 'A94'
          }
        ]
      },
      20: {
        20: [
          {
            min: 50000,
            max: 200000,
            cost: 4.07,
            schedule: 'E00'
          },
          {
            min: 250000,
            max: 600000,
            cost: 3.79,
            schedule: 'E50'
          }
        ],
        21: [
          {
            min: 50000,
            max: 200000,
            cost: 4.07,
            schedule: 'E01'
          },
          {
            min: 250000,
            max: 600000,
            cost: 3.79,
            schedule: 'E51'
          }
        ],
        22: [
          {
            min: 50000,
            max: 200000,
            cost: 4.07,
            schedule: 'E02'
          },
          {
            min: 250000,
            max: 600000,
            cost: 3.79,
            schedule: 'E52'
          }
        ],
        23: [
          {
            min: 50000,
            max: 200000,
            cost: 4.07,
            schedule: 'E03'
          },
          {
            min: 250000,
            max: 600000,
            cost: 3.79,
            schedule: 'E53'
          }
        ],
        24: [
          {
            min: 50000,
            max: 200000,
            cost: 4.07,
            schedule: 'E04'
          },
          {
            min: 250000,
            max: 600000,
            cost: 3.79,
            schedule: 'E54'
          }
        ],
        25: [
          {
            min: 50000,
            max: 200000,
            cost: 4.07,
            schedule: 'E05'
          },
          {
            min: 250000,
            max: 600000,
            cost: 3.79,
            schedule: 'E55'
          }
        ],
        26: [
          {
            min: 50000,
            max: 200000,
            cost: 4.07,
            schedule: 'E06'
          },
          {
            min: 250000,
            max: 600000,
            cost: 3.79,
            schedule: 'E56'
          }
        ],
        27: [
          {
            min: 50000,
            max: 200000,
            cost: 4.07,
            schedule: 'E07'
          },
          {
            min: 250000,
            max: 600000,
            cost: 3.79,
            schedule: 'E57'
          }
        ],
        28: [
          {
            min: 50000,
            max: 200000,
            cost: 4.07,
            schedule: 'E08'
          },
          {
            min: 250000,
            max: 600000,
            cost: 3.79,
            schedule: 'E58'
          }
        ],
        29: [
          {
            min: 50000,
            max: 200000,
            cost: 4.07,
            schedule: 'E09'
          },
          {
            min: 250000,
            max: 600000,
            cost: 3.79,
            schedule: 'E59'
          }
        ],
        30: [
          {
            min: 50000,
            max: 200000,
            cost: 4.86,
            schedule: 'E10'
          },
          {
            min: 250000,
            max: 600000,
            cost: 4.52,
            schedule: 'E60'
          }
        ],
        31: [
          {
            min: 50000,
            max: 200000,
            cost: 4.88,
            schedule: 'E11'
          },
          {
            min: 250000,
            max: 600000,
            cost: 4.54,
            schedule: 'E61'
          }
        ],
        32: [
          {
            min: 50000,
            max: 200000,
            cost: 4.94,
            schedule: 'E12'
          },
          {
            min: 250000,
            max: 600000,
            cost: 4.59,
            schedule: 'E62'
          }
        ],
        33: [
          {
            min: 50000,
            max: 200000,
            cost: 5.05,
            schedule: 'E13'
          },
          {
            min: 250000,
            max: 600000,
            cost: 4.69,
            schedule: 'E63'
          }
        ],
        34: [
          {
            min: 50000,
            max: 200000,
            cost: 5.13,
            schedule: 'E14'
          },
          {
            min: 250000,
            max: 600000,
            cost: 4.77,
            schedule: 'E64'
          }
        ],
        35: [
          {
            min: 50000,
            max: 200000,
            cost: 5.94,
            schedule: 'E15'
          },
          {
            min: 250000,
            max: 600000,
            cost: 5.53,
            schedule: 'E65'
          }
        ],
        36: [
          {
            min: 50000,
            max: 200000,
            cost: 6.13,
            schedule: 'E16'
          },
          {
            min: 250000,
            max: 600000,
            cost: 5.7,
            schedule: 'E66'
          }
        ],
        37: [
          {
            min: 50000,
            max: 200000,
            cost: 6.4,
            schedule: 'E17'
          },
          {
            min: 250000,
            max: 600000,
            cost: 5.95,
            schedule: 'E67'
          }
        ],
        38: [
          {
            min: 50000,
            max: 200000,
            cost: 6.68,
            schedule: 'E18'
          },
          {
            min: 250000,
            max: 600000,
            cost: 6.21,
            schedule: 'E68'
          }
        ],
        39: [
          {
            min: 50000,
            max: 200000,
            cost: 7.07,
            schedule: 'E19'
          },
          {
            min: 250000,
            max: 600000,
            cost: 6.58,
            schedule: 'E69'
          }
        ],
        40: [
          {
            min: 50000,
            max: 200000,
            cost: 9.91,
            schedule: 'E20'
          },
          {
            min: 250000,
            max: 600000,
            cost: 9.22,
            schedule: 'E70'
          }
        ],
        41: [
          {
            min: 50000,
            max: 200000,
            cost: 10.67,
            schedule: 'E21'
          },
          {
            min: 250000,
            max: 600000,
            cost: 9.92,
            schedule: 'E71'
          }
        ],
        42: [
          {
            min: 50000,
            max: 200000,
            cost: 11.7,
            schedule: 'E22'
          },
          {
            min: 250000,
            max: 600000,
            cost: 10.89,
            schedule: 'E72'
          }
        ],
        43: [
          {
            min: 50000,
            max: 200000,
            cost: 12.9,
            schedule: 'E23'
          },
          {
            min: 250000,
            max: 600000,
            cost: 12,
            schedule: 'E73'
          }
        ],
        44: [
          {
            min: 50000,
            max: 200000,
            cost: 14.05,
            schedule: 'E24'
          },
          {
            min: 250000,
            max: 600000,
            cost: 13.07,
            schedule: 'E74'
          }
        ],
        45: [
          {
            min: 50000,
            max: 200000,
            cost: 16.38,
            schedule: 'E25'
          },
          {
            min: 250000,
            max: 600000,
            cost: 15.23,
            schedule: 'E75'
          }
        ],
        46: [
          {
            min: 50000,
            max: 200000,
            cost: 17.61,
            schedule: 'E26'
          },
          {
            min: 250000,
            max: 600000,
            cost: 16.38,
            schedule: 'E76'
          }
        ],
        47: [
          {
            min: 50000,
            max: 200000,
            cost: 18.88,
            schedule: 'E27'
          },
          {
            min: 250000,
            max: 600000,
            cost: 17.56,
            schedule: 'E77'
          }
        ],
        48: [
          {
            min: 50000,
            max: 200000,
            cost: 20.16,
            schedule: 'E28'
          },
          {
            min: 250000,
            max: 600000,
            cost: 18.75,
            schedule: 'E78'
          }
        ],
        49: [
          {
            min: 50000,
            max: 200000,
            cost: 21.86,
            schedule: 'E29'
          },
          {
            min: 250000,
            max: 600000,
            cost: 20.33,
            schedule: 'E79'
          }
        ],
        50: [
          {
            min: 50000,
            max: 200000,
            cost: 24.72,
            schedule: 'E30'
          },
          {
            min: 250000,
            max: 600000,
            cost: 22.99,
            schedule: 'E80'
          }
        ],
        51: [
          {
            min: 50000,
            max: 200000,
            cost: 25.71,
            schedule: 'E31'
          },
          {
            min: 250000,
            max: 600000,
            cost: 23.91,
            schedule: 'E81'
          }
        ],
        52: [
          {
            min: 50000,
            max: 200000,
            cost: 32.91,
            schedule: 'E32'
          },
          {
            min: 250000,
            max: 600000,
            cost: 30.61,
            schedule: 'E82'
          }
        ],
        53: [
          {
            min: 50000,
            max: 200000,
            cost: 38.02,
            schedule: 'E33'
          },
          {
            min: 250000,
            max: 600000,
            cost: 35.36,
            schedule: 'E83'
          }
        ],
        54: [
          {
            min: 50000,
            max: 200000,
            cost: 43.65,
            schedule: 'E34'
          },
          {
            min: 250000,
            max: 600000,
            cost: 40.6,
            schedule: 'E84'
          }
        ]
      }
    },
    F: {
      10: {
        20: [
          {
            min: 50000,
            max: 200000,
            cost: 3.06,
            schedule: 'C00'
          },
          {
            min: 250000,
            max: 600000,
            cost: 2.85,
            schedule: 'C50'
          }
        ],
        21: [
          {
            min: 50000,
            max: 200000,
            cost: 3.06,
            schedule: 'C01'
          },
          {
            min: 250000,
            max: 600000,
            cost: 2.85,
            schedule: 'C51'
          }
        ],
        22: [
          {
            min: 50000,
            max: 200000,
            cost: 3.06,
            schedule: 'C02'
          },
          {
            min: 250000,
            max: 600000,
            cost: 2.85,
            schedule: 'C52'
          }
        ],
        23: [
          {
            min: 50000,
            max: 200000,
            cost: 3.1,
            schedule: 'C03'
          },
          {
            min: 250000,
            max: 600000,
            cost: 2.88,
            schedule: 'C53'
          }
        ],
        24: [
          {
            min: 50000,
            max: 200000,
            cost: 3.1,
            schedule: 'C04'
          },
          {
            min: 250000,
            max: 600000,
            cost: 2.88,
            schedule: 'C54'
          }
        ],
        25: [
          {
            min: 50000,
            max: 200000,
            cost: 3.17,
            schedule: 'C05'
          },
          {
            min: 250000,
            max: 600000,
            cost: 2.95,
            schedule: 'C55'
          }
        ],
        26: [
          {
            min: 50000,
            max: 200000,
            cost: 3.17,
            schedule: 'C06'
          },
          {
            min: 250000,
            max: 600000,
            cost: 2.95,
            schedule: 'C56'
          }
        ],
        27: [
          {
            min: 50000,
            max: 200000,
            cost: 3.17,
            schedule: 'C07'
          },
          {
            min: 250000,
            max: 600000,
            cost: 2.95,
            schedule: 'C57'
          }
        ],
        28: [
          {
            min: 50000,
            max: 200000,
            cost: 3.17,
            schedule: 'C08'
          },
          {
            min: 250000,
            max: 600000,
            cost: 2.95,
            schedule: 'C58'
          }
        ],
        29: [
          {
            min: 50000,
            max: 200000,
            cost: 3.17,
            schedule: 'C09'
          },
          {
            min: 250000,
            max: 600000,
            cost: 2.95,
            schedule: 'C59'
          }
        ],
        30: [
          {
            min: 50000,
            max: 200000,
            cost: 3.21,
            schedule: 'C10'
          },
          {
            min: 250000,
            max: 600000,
            cost: 2.98,
            schedule: 'C60'
          }
        ],
        31: [
          {
            min: 50000,
            max: 200000,
            cost: 3.24,
            schedule: 'C11'
          },
          {
            min: 250000,
            max: 600000,
            cost: 3.01,
            schedule: 'C61'
          }
        ],
        32: [
          {
            min: 50000,
            max: 200000,
            cost: 3.28,
            schedule: 'C12'
          },
          {
            min: 250000,
            max: 600000,
            cost: 3.05,
            schedule: 'C62'
          }
        ],
        33: [
          {
            min: 50000,
            max: 200000,
            cost: 3.31,
            schedule: 'C13'
          },
          {
            min: 250000,
            max: 600000,
            cost: 3.08,
            schedule: 'C63'
          }
        ],
        34: [
          {
            min: 50000,
            max: 200000,
            cost: 3.31,
            schedule: 'C14'
          },
          {
            min: 250000,
            max: 600000,
            cost: 3.08,
            schedule: 'C64'
          }
        ],
        35: [
          {
            min: 50000,
            max: 200000,
            cost: 3.47,
            schedule: 'C15'
          },
          {
            min: 250000,
            max: 600000,
            cost: 3.22,
            schedule: 'C65'
          }
        ],
        36: [
          {
            min: 50000,
            max: 200000,
            cost: 3.47,
            schedule: 'C16'
          },
          {
            min: 250000,
            max: 600000,
            cost: 3.22,
            schedule: 'C66'
          }
        ],
        37: [
          {
            min: 50000,
            max: 200000,
            cost: 3.58,
            schedule: 'C17'
          },
          {
            min: 250000,
            max: 600000,
            cost: 3.33,
            schedule: 'C67'
          }
        ],
        38: [
          {
            min: 50000,
            max: 200000,
            cost: 3.87,
            schedule: 'C18'
          },
          {
            min: 250000,
            max: 600000,
            cost: 3.6,
            schedule: 'C68'
          }
        ],
        39: [
          {
            min: 50000,
            max: 200000,
            cost: 4.13,
            schedule: 'C19'
          },
          {
            min: 250000,
            max: 600000,
            cost: 3.84,
            schedule: 'C69'
          }
        ],
        40: [
          {
            min: 50000,
            max: 200000,
            cost: 4.46,
            schedule: 'C20'
          },
          {
            min: 250000,
            max: 600000,
            cost: 4.15,
            schedule: 'C70'
          }
        ],
        41: [
          {
            min: 50000,
            max: 200000,
            cost: 4.9,
            schedule: 'C21'
          },
          {
            min: 250000,
            max: 600000,
            cost: 4.56,
            schedule: 'C71'
          }
        ],
        42: [
          {
            min: 50000,
            max: 200000,
            cost: 5.27,
            schedule: 'C22'
          },
          {
            min: 250000,
            max: 600000,
            cost: 4.9,
            schedule: 'C72'
          }
        ],
        43: [
          {
            min: 50000,
            max: 200000,
            cost: 5.68,
            schedule: 'C23'
          },
          {
            min: 250000,
            max: 600000,
            cost: 5.28,
            schedule: 'C73'
          }
        ],
        44: [
          {
            min: 50000,
            max: 200000,
            cost: 6.16,
            schedule: 'C24'
          },
          {
            min: 250000,
            max: 600000,
            cost: 5.73,
            schedule: 'C74'
          }
        ],
        45: [
          {
            min: 50000,
            max: 200000,
            cost: 7.09,
            schedule: 'C25'
          },
          {
            min: 250000,
            max: 600000,
            cost: 6.59,
            schedule: 'C75'
          }
        ],
        46: [
          {
            min: 50000,
            max: 200000,
            cost: 7.89,
            schedule: 'C26'
          },
          {
            min: 250000,
            max: 600000,
            cost: 7.34,
            schedule: 'C76'
          }
        ],
        47: [
          {
            min: 50000,
            max: 200000,
            cost: 8.73,
            schedule: 'C27'
          },
          {
            min: 250000,
            max: 600000,
            cost: 8.11,
            schedule: 'C77'
          }
        ],
        48: [
          {
            min: 50000,
            max: 200000,
            cost: 9.57,
            schedule: 'C28'
          },
          {
            min: 250000,
            max: 600000,
            cost: 8.9,
            schedule: 'C78'
          }
        ],
        49: [
          {
            min: 50000,
            max: 200000,
            cost: 10.58,
            schedule: 'C29'
          },
          {
            min: 250000,
            max: 600000,
            cost: 9.84,
            schedule: 'C79'
          }
        ],
        50: [
          {
            min: 50000,
            max: 200000,
            cost: 12.07,
            schedule: 'C30'
          },
          {
            min: 250000,
            max: 600000,
            cost: 11.22,
            schedule: 'C80'
          }
        ],
        51: [
          {
            min: 50000,
            max: 200000,
            cost: 13.19,
            schedule: 'C31'
          },
          {
            min: 250000,
            max: 600000,
            cost: 12.27,
            schedule: 'C81'
          }
        ],
        52: [
          {
            min: 50000,
            max: 200000,
            cost: 14.19,
            schedule: 'C32'
          },
          {
            min: 250000,
            max: 600000,
            cost: 13.2,
            schedule: 'C82'
          }
        ],
        53: [
          {
            min: 50000,
            max: 200000,
            cost: 15.44,
            schedule: 'C33'
          },
          {
            min: 250000,
            max: 600000,
            cost: 14.36,
            schedule: 'C83'
          }
        ],
        54: [
          {
            min: 50000,
            max: 200000,
            cost: 16.83,
            schedule: 'C34'
          },
          {
            min: 250000,
            max: 600000,
            cost: 15.65,
            schedule: 'C84'
          }
        ],
        55: [
          {
            min: 50000,
            max: 200000,
            cost: 18.34,
            schedule: 'C35'
          },
          {
            min: 250000,
            max: 600000,
            cost: 17.06,
            schedule: 'C85'
          }
        ],
        56: [
          {
            min: 50000,
            max: 200000,
            cost: 19.99,
            schedule: 'C36'
          },
          {
            min: 250000,
            max: 600000,
            cost: 18.59,
            schedule: 'C86'
          }
        ],
        57: [
          {
            min: 50000,
            max: 200000,
            cost: 21.59,
            schedule: 'C37'
          },
          {
            min: 250000,
            max: 600000,
            cost: 20.08,
            schedule: 'C87'
          }
        ],
        58: [
          {
            min: 50000,
            max: 200000,
            cost: 23.62,
            schedule: 'C38'
          },
          {
            min: 250000,
            max: 600000,
            cost: 21.97,
            schedule: 'C88'
          }
        ],
        59: [
          {
            min: 50000,
            max: 200000,
            cost: 25.74,
            schedule: 'C39'
          },
          {
            min: 250000,
            max: 600000,
            cost: 23.94,
            schedule: 'C89'
          }
        ],
        60: [
          {
            min: 50000,
            max: 200000,
            cost: 28.6,
            schedule: 'C40'
          },
          {
            min: 250000,
            max: 600000,
            cost: 26.59,
            schedule: 'C90'
          }
        ],
        61: [
          {
            min: 50000,
            max: 200000,
            cost: 31.58,
            schedule: 'C41'
          },
          {
            min: 250000,
            max: 600000,
            cost: 29.37,
            schedule: 'C91'
          }
        ],
        62: [
          {
            min: 50000,
            max: 200000,
            cost: 34.78,
            schedule: 'C42'
          },
          {
            min: 250000,
            max: 600000,
            cost: 32.35,
            schedule: 'C92'
          }
        ],
        63: [
          {
            min: 50000,
            max: 200000,
            cost: 38.63,
            schedule: 'C43'
          },
          {
            min: 250000,
            max: 600000,
            cost: 35.93,
            schedule: 'C93'
          }
        ],
        64: [
          {
            min: 50000,
            max: 200000,
            cost: 42.96,
            schedule: 'C44'
          },
          {
            min: 250000,
            max: 600000,
            cost: 39.95,
            schedule: 'C94'
          }
        ]
      },
      20: {
        20: [
          {
            min: 50000,
            max: 200000,
            cost: 3.46,
            schedule: 'G00'
          },
          {
            min: 250000,
            max: 600000,
            cost: 3.22,
            schedule: 'G50'
          }
        ],
        21: [
          {
            min: 50000,
            max: 200000,
            cost: 3.46,
            schedule: 'G01'
          },
          {
            min: 250000,
            max: 600000,
            cost: 3.22,
            schedule: 'G51'
          }
        ],
        22: [
          {
            min: 50000,
            max: 200000,
            cost: 3.46,
            schedule: 'G02'
          },
          {
            min: 250000,
            max: 600000,
            cost: 3.22,
            schedule: 'G52'
          }
        ],
        23: [
          {
            min: 50000,
            max: 200000,
            cost: 3.46,
            schedule: 'G03'
          },
          {
            min: 250000,
            max: 600000,
            cost: 3.22,
            schedule: 'G53'
          }
        ],
        24: [
          {
            min: 50000,
            max: 200000,
            cost: 3.46,
            schedule: 'G04'
          },
          {
            min: 250000,
            max: 600000,
            cost: 3.22,
            schedule: 'G54'
          }
        ],
        25: [
          {
            min: 50000,
            max: 200000,
            cost: 3.46,
            schedule: 'G05'
          },
          {
            min: 250000,
            max: 600000,
            cost: 3.22,
            schedule: 'G55'
          }
        ],
        26: [
          {
            min: 50000,
            max: 200000,
            cost: 3.46,
            schedule: 'G06'
          },
          {
            min: 250000,
            max: 600000,
            cost: 3.22,
            schedule: 'G56'
          }
        ],
        27: [
          {
            min: 50000,
            max: 200000,
            cost: 3.46,
            schedule: 'G07'
          },
          {
            min: 250000,
            max: 600000,
            cost: 3.22,
            schedule: 'G57'
          }
        ],
        28: [
          {
            min: 50000,
            max: 200000,
            cost: 3.46,
            schedule: 'G08'
          },
          {
            min: 250000,
            max: 600000,
            cost: 3.22,
            schedule: 'G58'
          }
        ],
        29: [
          {
            min: 50000,
            max: 200000,
            cost: 3.46,
            schedule: 'G09'
          },
          {
            min: 250000,
            max: 600000,
            cost: 3.22,
            schedule: 'G59'
          }
        ],
        30: [
          {
            min: 50000,
            max: 200000,
            cost: 4.13,
            schedule: 'G10'
          },
          {
            min: 250000,
            max: 600000,
            cost: 3.84,
            schedule: 'G60'
          }
        ],
        31: [
          {
            min: 50000,
            max: 200000,
            cost: 4.15,
            schedule: 'G11'
          },
          {
            min: 250000,
            max: 600000,
            cost: 3.86,
            schedule: 'G61'
          }
        ],
        32: [
          {
            min: 50000,
            max: 200000,
            cost: 4.2,
            schedule: 'G12'
          },
          {
            min: 250000,
            max: 600000,
            cost: 3.9,
            schedule: 'G62'
          }
        ],
        33: [
          {
            min: 50000,
            max: 200000,
            cost: 4.29,
            schedule: 'G13'
          },
          {
            min: 250000,
            max: 600000,
            cost: 3.99,
            schedule: 'G63'
          }
        ],
        34: [
          {
            min: 50000,
            max: 200000,
            cost: 4.36,
            schedule: 'G14'
          },
          {
            min: 250000,
            max: 600000,
            cost: 4.05,
            schedule: 'G64'
          }
        ],
        35: [
          {
            min: 50000,
            max: 200000,
            cost: 5.05,
            schedule: 'G15'
          },
          {
            min: 250000,
            max: 600000,
            cost: 4.7,
            schedule: 'G65'
          }
        ],
        36: [
          {
            min: 50000,
            max: 200000,
            cost: 5.21,
            schedule: 'G16'
          },
          {
            min: 250000,
            max: 600000,
            cost: 4.84,
            schedule: 'G66'
          }
        ],
        37: [
          {
            min: 50000,
            max: 200000,
            cost: 5.44,
            schedule: 'G17'
          },
          {
            min: 250000,
            max: 600000,
            cost: 5.06,
            schedule: 'G67'
          }
        ],
        38: [
          {
            min: 50000,
            max: 200000,
            cost: 5.67,
            schedule: 'G18'
          },
          {
            min: 250000,
            max: 600000,
            cost: 5.28,
            schedule: 'G68'
          }
        ],
        39: [
          {
            min: 50000,
            max: 200000,
            cost: 6.01,
            schedule: 'G19'
          },
          {
            min: 250000,
            max: 600000,
            cost: 5.59,
            schedule: 'G69'
          }
        ],
        40: [
          {
            min: 50000,
            max: 200000,
            cost: 8.43,
            schedule: 'G20'
          },
          {
            min: 250000,
            max: 600000,
            cost: 7.84,
            schedule: 'G70'
          }
        ],
        41: [
          {
            min: 50000,
            max: 200000,
            cost: 9.07,
            schedule: 'G21'
          },
          {
            min: 250000,
            max: 600000,
            cost: 8.43,
            schedule: 'G71'
          }
        ],
        42: [
          {
            min: 50000,
            max: 200000,
            cost: 9.95,
            schedule: 'G22'
          },
          {
            min: 250000,
            max: 600000,
            cost: 9.25,
            schedule: 'G72'
          }
        ],
        43: [
          {
            min: 50000,
            max: 200000,
            cost: 10.96,
            schedule: 'G23'
          },
          {
            min: 250000,
            max: 600000,
            cost: 10.2,
            schedule: 'G73'
          }
        ],
        44: [
          {
            min: 50000,
            max: 200000,
            cost: 11.95,
            schedule: 'G24'
          },
          {
            min: 250000,
            max: 600000,
            cost: 11.11,
            schedule: 'G74'
          }
        ],
        45: [
          {
            min: 50000,
            max: 200000,
            cost: 13.92,
            schedule: 'G25'
          },
          {
            min: 250000,
            max: 600000,
            cost: 12.95,
            schedule: 'G75'
          }
        ],
        46: [
          {
            min: 50000,
            max: 200000,
            cost: 14.97,
            schedule: 'G26'
          },
          {
            min: 250000,
            max: 600000,
            cost: 13.92,
            schedule: 'G76'
          }
        ],
        47: [
          {
            min: 50000,
            max: 200000,
            cost: 16.05,
            schedule: 'G27'
          },
          {
            min: 250000,
            max: 600000,
            cost: 14.93,
            schedule: 'G77'
          }
        ],
        48: [
          {
            min: 50000,
            max: 200000,
            cost: 17.13,
            schedule: 'G28'
          },
          {
            min: 250000,
            max: 600000,
            cost: 15.93,
            schedule: 'G78'
          }
        ],
        49: [
          {
            min: 50000,
            max: 200000,
            cost: 18.58,
            schedule: 'G29'
          },
          {
            min: 250000,
            max: 600000,
            cost: 17.28,
            schedule: 'G79'
          }
        ],
        50: [
          {
            min: 50000,
            max: 200000,
            cost: 21.01,
            schedule: 'G30'
          },
          {
            min: 250000,
            max: 600000,
            cost: 19.54,
            schedule: 'G80'
          }
        ],
        51: [
          {
            min: 50000,
            max: 200000,
            cost: 21.85,
            schedule: 'G31'
          },
          {
            min: 250000,
            max: 600000,
            cost: 20.32,
            schedule: 'G81'
          }
        ],
        52: [
          {
            min: 50000,
            max: 200000,
            cost: 27.97,
            schedule: 'G32'
          },
          {
            min: 250000,
            max: 600000,
            cost: 26.01,
            schedule: 'G82'
          }
        ],
        53: [
          {
            min: 50000,
            max: 200000,
            cost: 32.32,
            schedule: 'G33'
          },
          {
            min: 250000,
            max: 600000,
            cost: 30.05,
            schedule: 'G83'
          }
        ],
        54: [
          {
            min: 50000,
            max: 200000,
            cost: 37.11,
            schedule: 'G34'
          },
          {
            min: 250000,
            max: 600000,
            cost: 34.51,
            schedule: 'G84'
          }
        ]
      }
    }
  },
  preferred: {
    M: {
      10: {
        20: [
          {
            min: 50000,
            max: 200000,
            cost: 2.92,
            schedule: 'B00'
          },
          {
            min: 250000,
            max: 600000,
            cost: 2.71,
            schedule: ' B50'
          }
        ],
        21: [
          {
            min: 50000,
            max: 200000,
            cost: 2.92,
            schedule: 'B01'
          },
          {
            min: 250000,
            max: 600000,
            cost: 2.71,
            schedule: 'B51'
          }
        ],
        22: [
          {
            min: 50000,
            max: 200000,
            cost: 2.92,
            schedule: 'B02'
          },
          {
            min: 250000,
            max: 600000,
            cost: 2.71,
            schedule: 'B52'
          }
        ],
        23: [
          {
            min: 50000,
            max: 200000,
            cost: 2.92,
            schedule: 'B03'
          },
          {
            min: 250000,
            max: 600000,
            cost: 2.71,
            schedule: 'B53'
          }
        ],
        24: [
          {
            min: 50000,
            max: 200000,
            cost: 2.92,
            schedule: 'B04'
          },
          {
            min: 250000,
            max: 600000,
            cost: 2.71,
            schedule: 'B54'
          }
        ],
        25: [
          {
            min: 50000,
            max: 200000,
            cost: 2.92,
            schedule: 'B05'
          },
          {
            min: 250000,
            max: 600000,
            cost: 2.71,
            schedule: 'B55'
          }
        ],
        26: [
          {
            min: 50000,
            max: 200000,
            cost: 2.92,
            schedule: 'B06'
          },
          {
            min: 250000,
            max: 600000,
            cost: 2.71,
            schedule: 'B56'
          }
        ],
        27: [
          {
            min: 50000,
            max: 200000,
            cost: 2.92,
            schedule: 'B07'
          },
          {
            min: 250000,
            max: 600000,
            cost: 2.71,
            schedule: 'B57'
          }
        ],
        28: [
          {
            min: 50000,
            max: 200000,
            cost: 2.92,
            schedule: 'B08'
          },
          {
            min: 250000,
            max: 600000,
            cost: 2.71,
            schedule: 'B58'
          }
        ],
        29: [
          {
            min: 50000,
            max: 200000,
            cost: 2.92,
            schedule: 'B09'
          },
          {
            min: 250000,
            max: 600000,
            cost: 2.71,
            schedule: 'B59'
          }
        ],
        30: [
          {
            min: 50000,
            max: 200000,
            cost: 3,
            schedule: 'B10'
          },
          {
            min: 250000,
            max: 600000,
            cost: 2.79,
            schedule: 'B60'
          }
        ],
        31: [
          {
            min: 50000,
            max: 200000,
            cost: 3,
            schedule: 'B11'
          },
          {
            min: 250000,
            max: 600000,
            cost: 2.79,
            schedule: 'B61'
          }
        ],
        32: [
          {
            min: 50000,
            max: 200000,
            cost: 3,
            schedule: 'B12'
          },
          {
            min: 250000,
            max: 600000,
            cost: 2.79,
            schedule: 'B62'
          }
        ],
        33: [
          {
            min: 50000,
            max: 200000,
            cost: 3,
            schedule: 'B13'
          },
          {
            min: 250000,
            max: 600000,
            cost: 2.79,
            schedule: 'B63'
          }
        ],
        34: [
          {
            min: 50000,
            max: 200000,
            cost: 3.12,
            schedule: 'B14'
          },
          {
            min: 250000,
            max: 600000,
            cost: 2.9,
            schedule: 'B64'
          }
        ],
        35: [
          {
            min: 50000,
            max: 200000,
            cost: 3.12,
            schedule: 'B15'
          },
          {
            min: 250000,
            max: 600000,
            cost: 2.9,
            schedule: 'B65'
          }
        ],
        36: [
          {
            min: 50000,
            max: 200000,
            cost: 3.19,
            schedule: 'B16'
          },
          {
            min: 250000,
            max: 600000,
            cost: 2.97,
            schedule: 'B66'
          }
        ],
        37: [
          {
            min: 50000,
            max: 200000,
            cost: 3.27,
            schedule: 'B17'
          },
          {
            min: 250000,
            max: 600000,
            cost: 3.04,
            schedule: 'B67'
          }
        ],
        38: [
          {
            min: 50000,
            max: 200000,
            cost: 3.46,
            schedule: 'B18'
          },
          {
            min: 250000,
            max: 600000,
            cost: 3.22,
            schedule: 'B68'
          }
        ],
        39: [
          {
            min: 50000,
            max: 200000,
            cost: 3.65,
            schedule: 'B19'
          },
          {
            min: 250000,
            max: 600000,
            cost: 3.39,
            schedule: 'B69'
          }
        ],
        40: [
          {
            min: 50000,
            max: 200000,
            cost: 3.8,
            schedule: 'B20'
          },
          {
            min: 250000,
            max: 600000,
            cost: 3.54,
            schedule: 'B70'
          }
        ],
        41: [
          {
            min: 50000,
            max: 200000,
            cost: 3.99,
            schedule: 'B21'
          },
          {
            min: 250000,
            max: 600000,
            cost: 3.71,
            schedule: 'B71'
          }
        ],
        42: [
          {
            min: 50000,
            max: 200000,
            cost: 4.26,
            schedule: 'B22'
          },
          {
            min: 250000,
            max: 600000,
            cost: 3.96,
            schedule: 'B72'
          }
        ],
        43: [
          {
            min: 50000,
            max: 200000,
            cost: 4.56,
            schedule: 'B23'
          },
          {
            min: 250000,
            max: 600000,
            cost: 4.24,
            schedule: 'B73'
          }
        ],
        44: [
          {
            min: 50000,
            max: 200000,
            cost: 4.83,
            schedule: 'B24'
          },
          {
            min: 250000,
            max: 600000,
            cost: 4.49,
            schedule: 'B74'
          }
        ],
        45: [
          {
            min: 50000,
            max: 200000,
            cost: 5.69,
            schedule: 'B25'
          },
          {
            min: 250000,
            max: 600000,
            cost: 5.29,
            schedule: 'B75'
          }
        ],
        46: [
          {
            min: 50000,
            max: 200000,
            cost: 6.18,
            schedule: 'B26'
          },
          {
            min: 250000,
            max: 600000,
            cost: 5.75,
            schedule: 'B76'
          }
        ],
        47: [
          {
            min: 50000,
            max: 200000,
            cost: 6.72,
            schedule: 'B27'
          },
          {
            min: 250000,
            max: 600000,
            cost: 6.25,
            schedule: 'B77'
          }
        ],
        48: [
          {
            min: 50000,
            max: 200000,
            cost: 7.21,
            schedule: 'B28'
          },
          {
            min: 250000,
            max: 600000,
            cost: 6.7,
            schedule: 'B78'
          }
        ],
        49: [
          {
            min: 50000,
            max: 200000,
            cost: 7.9,
            schedule: 'B29'
          },
          {
            min: 250000,
            max: 600000,
            cost: 7.35,
            schedule: 'B79'
          }
        ],
        50: [
          {
            min: 50000,
            max: 200000,
            cost: 9.21,
            schedule: 'B30'
          },
          {
            min: 250000,
            max: 600000,
            cost: 8.57,
            schedule: 'B80'
          }
        ],
        51: [
          {
            min: 50000,
            max: 200000,
            cost: 10.0,
            schedule: 'B31'
          },
          {
            min: 250000,
            max: 600000,
            cost: 9.3,
            schedule: 'B81'
          }
        ],
        52: [
          {
            min: 50000,
            max: 200000,
            cost: 10.71,
            schedule: 'B32'
          },
          {
            min: 250000,
            max: 600000,
            cost: 9.96,
            schedule: 'B82'
          }
        ],
        53: [
          {
            min: 50000,
            max: 200000,
            cost: 11.58,
            schedule: 'B33'
          },
          {
            min: 250000,
            max: 600000,
            cost: 10.77,
            schedule: 'B83'
          }
        ],
        54: [
          {
            min: 50000,
            max: 200000,
            cost: 12.64,
            schedule: 'B34'
          },
          {
            min: 250000,
            max: 600000,
            cost: 11.75,
            schedule: 'B84'
          }
        ],
        55: [
          {
            min: 50000,
            max: 200000,
            cost: 14.05,
            schedule: 'B35'
          },
          {
            min: 250000,
            max: 600000,
            cost: 13.07,
            schedule: 'B85'
          }
        ],
        56: [
          {
            min: 50000,
            max: 200000,
            cost: 15.32,
            schedule: 'B36'
          },
          {
            min: 250000,
            max: 600000,
            cost: 14.25,
            schedule: 'B86'
          }
        ],
        57: [
          {
            min: 50000,
            max: 200000,
            cost: 16.5,
            schedule: 'B37'
          },
          {
            min: 250000,
            max: 600000,
            cost: 15.35,
            schedule: 'B87'
          }
        ],
        58: [
          {
            min: 50000,
            max: 200000,
            cost: 18.04,
            schedule: 'B38'
          },
          {
            min: 250000,
            max: 600000,
            cost: 16.78,
            schedule: 'B88'
          }
        ],
        59: [
          {
            min: 50000,
            max: 200000,
            cost: 19.72,
            schedule: 'B39'
          },
          {
            min: 250000,
            max: 600000,
            cost: 18.34,
            schedule: 'B89'
          }
        ],
        60: [
          {
            min: 50000,
            max: 200000,
            cost: 21.67,
            schedule: 'B40'
          },
          {
            min: 250000,
            max: 600000,
            cost: 20.15,
            schedule: 'B90'
          }
        ],
        61: [
          {
            min: 50000,
            max: 200000,
            cost: 24.9,
            schedule: 'B41'
          },
          {
            min: 250000,
            max: 600000,
            cost: 23.15,
            schedule: 'B91'
          }
        ],
        62: [
          {
            min: 50000,
            max: 200000,
            cost: 27.35,
            schedule: 'B42'
          },
          {
            min: 250000,
            max: 600000,
            cost: 25.43,
            schedule: 'B92'
          }
        ],
        63: [
          {
            min: 50000,
            max: 200000,
            cost: 30.32,
            schedule: 'B43'
          },
          {
            min: 250000,
            max: 600000,
            cost: 28.2,
            schedule: 'B93'
          }
        ],
        64: [
          {
            min: 50000,
            max: 200000,
            cost: 33.76,
            schedule: 'B44'
          },
          {
            min: 250000,
            max: 600000,
            cost: 31.4,
            schedule: 'B94'
          }
        ]
      },
      20: {
        20: [
          {
            min: 50000,
            max: 200000,
            cost: 3.09,
            schedule: 'F00'
          },
          {
            min: 250000,
            max: 600000,
            cost: 2.88,
            schedule: 'F50'
          }
        ],
        21: [
          {
            min: 50000,
            max: 200000,
            cost: 3.09,
            schedule: 'F01'
          },
          {
            min: 250000,
            max: 600000,
            cost: 2.88,
            schedule: 'F51'
          }
        ],
        22: [
          {
            min: 50000,
            max: 200000,
            cost: 3.09,
            schedule: 'F02'
          },
          {
            min: 250000,
            max: 600000,
            cost: 2.88,
            schedule: 'F52'
          }
        ],
        23: [
          {
            min: 50000,
            max: 200000,
            cost: 3.09,
            schedule: 'F03'
          },
          {
            min: 250000,
            max: 600000,
            cost: 2.88,
            schedule: 'F53'
          }
        ],
        24: [
          {
            min: 50000,
            max: 200000,
            cost: 3.09,
            schedule: 'F04'
          },
          {
            min: 250000,
            max: 600000,
            cost: 2.88,
            schedule: 'F54'
          }
        ],
        25: [
          {
            min: 50000,
            max: 200000,
            cost: 3.09,
            schedule: 'F05'
          },
          {
            min: 250000,
            max: 600000,
            cost: 2.88,
            schedule: 'F55'
          }
        ],
        26: [
          {
            min: 50000,
            max: 200000,
            cost: 3.09,
            schedule: 'F06'
          },
          {
            min: 250000,
            max: 600000,
            cost: 2.88,
            schedule: 'F56'
          }
        ],
        27: [
          {
            min: 50000,
            max: 200000,
            cost: 3.09,
            schedule: 'F07'
          },
          {
            min: 250000,
            max: 600000,
            cost: 2.88,
            schedule: 'F57'
          }
        ],
        28: [
          {
            min: 50000,
            max: 200000,
            cost: 3.09,
            schedule: 'F08'
          },
          {
            min: 250000,
            max: 600000,
            cost: 2.88,
            schedule: 'F58'
          }
        ],
        29: [
          {
            min: 50000,
            max: 200000,
            cost: 3.09,
            schedule: 'F09'
          },
          {
            min: 250000,
            max: 600000,
            cost: 2.88,
            schedule: 'F59'
          }
        ],
        30: [
          {
            min: 50000,
            max: 200000,
            cost: 3.99,
            schedule: 'F10'
          },
          {
            min: 250000,
            max: 600000,
            cost: 3.71,
            schedule: 'F60'
          }
        ],
        31: [
          {
            min: 50000,
            max: 200000,
            cost: 3.99,
            schedule: 'F11'
          },
          {
            min: 250000,
            max: 600000,
            cost: 3.71,
            schedule: 'F61'
          }
        ],
        32: [
          {
            min: 50000,
            max: 200000,
            cost: 3.99,
            schedule: 'F12'
          },
          {
            min: 250000,
            max: 600000,
            cost: 3.71,
            schedule: 'F62'
          }
        ],
        33: [
          {
            min: 50000,
            max: 200000,
            cost: 3.99,
            schedule: 'F13'
          },
          {
            min: 250000,
            max: 600000,
            cost: 3.71,
            schedule: 'F63'
          }
        ],
        34: [
          {
            min: 50000,
            max: 200000,
            cost: 3.99,
            schedule: 'F14'
          },
          {
            min: 250000,
            max: 600000,
            cost: 3.71,
            schedule: 'F64'
          }
        ],
        35: [
          {
            min: 50000,
            max: 200000,
            cost: 4.94,
            schedule: 'F15'
          },
          {
            min: 250000,
            max: 600000,
            cost: 4.59,
            schedule: 'F65'
          }
        ],
        36: [
          {
            min: 50000,
            max: 200000,
            cost: 5.16,
            schedule: 'F16'
          },
          {
            min: 250000,
            max: 600000,
            cost: 4.8,
            schedule: 'F66'
          }
        ],
        37: [
          {
            min: 50000,
            max: 200000,
            cost: 5.45,
            schedule: 'F17'
          },
          {
            min: 250000,
            max: 600000,
            cost: 5.07,
            schedule: 'F67'
          }
        ],
        38: [
          {
            min: 50000,
            max: 200000,
            cost: 5.85,
            schedule: 'F18'
          },
          {
            min: 250000,
            max: 600000,
            cost: 5.44,
            schedule: 'F68'
          }
        ],
        39: [
          {
            min: 50000,
            max: 200000,
            cost: 6.28,
            schedule: 'F19'
          },
          {
            min: 250000,
            max: 600000,
            cost: 5.84,
            schedule: 'F69'
          }
        ],
        40: [
          {
            min: 50000,
            max: 200000,
            cost: 8.02,
            schedule: 'F20'
          },
          {
            min: 250000,
            max: 600000,
            cost: 7.46,
            schedule: 'F70'
          }
        ],
        41: [
          {
            min: 50000,
            max: 200000,
            cost: 8.62,
            schedule: 'F21'
          },
          {
            min: 250000,
            max: 600000,
            cost: 8.01,
            schedule: 'F71'
          }
        ],
        42: [
          {
            min: 50000,
            max: 200000,
            cost: 9.43,
            schedule: 'F22'
          },
          {
            min: 250000,
            max: 600000,
            cost: 8.77,
            schedule: 'F72'
          }
        ],
        43: [
          {
            min: 50000,
            max: 200000,
            cost: 10.38,
            schedule: 'F23'
          },
          {
            min: 250000,
            max: 600000,
            cost: 9.65,
            schedule: 'F73'
          }
        ],
        44: [
          {
            min: 50000,
            max: 200000,
            cost: 11.23,
            schedule: 'F24'
          },
          {
            min: 250000,
            max: 600000,
            cost: 10.45,
            schedule: 'F74'
          }
        ],
        45: [
          {
            min: 50000,
            max: 200000,
            cost: 12.38,
            schedule: 'F25'
          },
          {
            min: 250000,
            max: 600000,
            cost: 11.51,
            schedule: 'F75'
          }
        ],
        46: [
          {
            min: 50000,
            max: 200000,
            cost: 13.34,
            schedule: 'F26'
          },
          {
            min: 250000,
            max: 600000,
            cost: 12.41,
            schedule: 'F76'
          }
        ],
        47: [
          {
            min: 50000,
            max: 200000,
            cost: 14.55,
            schedule: 'F27'
          },
          {
            min: 250000,
            max: 600000,
            cost: 13.53,
            schedule: 'F77'
          }
        ],
        48: [
          {
            min: 50000,
            max: 200000,
            cost: 15.64,
            schedule: 'F28'
          },
          {
            min: 250000,
            max: 600000,
            cost: 14.54,
            schedule: 'F78'
          }
        ],
        49: [
          {
            min: 50000,
            max: 200000,
            cost: 17.18,
            schedule: 'F29'
          },
          {
            min: 250000,
            max: 600000,
            cost: 15.98,
            schedule: 'F79'
          }
        ],
        50: [
          {
            min: 50000,
            max: 200000,
            cost: 18.87,
            schedule: 'F30'
          },
          {
            min: 250000,
            max: 600000,
            cost: 17.55,
            schedule: 'F80'
          }
        ],
        51: [
          {
            min: 50000,
            max: 200000,
            cost: 20.83,
            schedule: 'F31'
          },
          {
            min: 250000,
            max: 600000,
            cost: 19.37,
            schedule: 'F81'
          }
        ],
        52: [
          {
            min: 50000,
            max: 200000,
            cost: 23.11,
            schedule: 'F32'
          },
          {
            min: 250000,
            max: 600000,
            cost: 21.49,
            schedule: 'F82'
          }
        ],
        53: [
          {
            min: 50000,
            max: 200000,
            cost: 25.7,
            schedule: 'F33'
          },
          {
            min: 250000,
            max: 600000,
            cost: 23.9,
            schedule: 'F83'
          }
        ],
        54: [
          {
            min: 50000,
            max: 200000,
            cost: 28.73,
            schedule: 'F34'
          },
          {
            min: 250000,
            max: 600000,
            cost: 26.72,
            schedule: 'F84'
          }
        ]
      }
    },
    F: {
      10: {
        20: [
          {
            min: 50000,
            max: 200000,
            cost: 2.48,
            schedule: 'D00'
          },
          {
            min: 250000,
            max: 600000,
            cost: 2.31,
            schedule: 'D50'
          }
        ],
        21: [
          {
            min: 50000,
            max: 200000,
            cost: 2.48,
            schedule: 'D01'
          },
          {
            min: 250000,
            max: 600000,
            cost: 2.31,
            schedule: 'D51'
          }
        ],
        22: [
          {
            min: 50000,
            max: 200000,
            cost: 2.48,
            schedule: 'D02'
          },
          {
            min: 250000,
            max: 600000,
            cost: 2.31,
            schedule: 'D52'
          }
        ],
        23: [
          {
            min: 50000,
            max: 200000,
            cost: 2.48,
            schedule: 'D03'
          },
          {
            min: 250000,
            max: 600000,
            cost: 2.31,
            schedule: 'D53'
          }
        ],
        24: [
          {
            min: 50000,
            max: 200000,
            cost: 2.48,
            schedule: 'D04'
          },
          {
            min: 250000,
            max: 600000,
            cost: 2.31,
            schedule: 'D54'
          }
        ],
        25: [
          {
            min: 50000,
            max: 200000,
            cost: 2.48,
            schedule: 'D05'
          },
          {
            min: 250000,
            max: 600000,
            cost: 2.31,
            schedule: 'D55'
          }
        ],
        26: [
          {
            min: 50000,
            max: 200000,
            cost: 2.48,
            schedule: 'D06'
          },
          {
            min: 250000,
            max: 600000,
            cost: 2.31,
            schedule: 'D56'
          }
        ],
        27: [
          {
            min: 50000,
            max: 200000,
            cost: 2.48,
            schedule: 'D07'
          },
          {
            min: 250000,
            max: 600000,
            cost: 2.31,
            schedule: 'D57'
          }
        ],
        28: [
          {
            min: 50000,
            max: 200000,
            cost: 2.48,
            schedule: 'D08'
          },
          {
            min: 250000,
            max: 600000,
            cost: 2.31,
            schedule: 'D58'
          }
        ],
        29: [
          {
            min: 50000,
            max: 200000,
            cost: 2.48,
            schedule: 'D09'
          },
          {
            min: 250000,
            max: 600000,
            cost: 2.31,
            schedule: 'D59'
          }
        ],
        30: [
          {
            min: 50000,
            max: 200000,
            cost: 2.55,
            schedule: 'D10'
          },
          {
            min: 250000,
            max: 600000,
            cost: 2.37,
            schedule: 'D60'
          }
        ],
        31: [
          {
            min: 50000,
            max: 200000,
            cost: 2.55,
            schedule: 'D11'
          },
          {
            min: 250000,
            max: 600000,
            cost: 2.37,
            schedule: 'D61'
          }
        ],
        32: [
          {
            min: 50000,
            max: 200000,
            cost: 2.55,
            schedule: 'D12'
          },
          {
            min: 250000,
            max: 600000,
            cost: 2.37,
            schedule: 'D62'
          }
        ],
        33: [
          {
            min: 50000,
            max: 200000,
            cost: 2.55,
            schedule: 'D13'
          },
          {
            min: 250000,
            max: 600000,
            cost: 2.37,
            schedule: 'D63'
          }
        ],
        34: [
          {
            min: 50000,
            max: 200000,
            cost: 2.65,
            schedule: 'D14'
          },
          {
            min: 250000,
            max: 600000,
            cost: 2.46,
            schedule: 'D64'
          }
        ],
        35: [
          {
            min: 50000,
            max: 200000,
            cost: 2.65,
            schedule: 'D15'
          },
          {
            min: 250000,
            max: 600000,
            cost: 2.46,
            schedule: 'D65'
          }
        ],
        36: [
          {
            min: 50000,
            max: 200000,
            cost: 2.71,
            schedule: 'D16'
          },
          {
            min: 250000,
            max: 600000,
            cost: 2.52,
            schedule: 'D66'
          }
        ],
        37: [
          {
            min: 50000,
            max: 200000,
            cost: 2.78,
            schedule: 'D17'
          },
          {
            min: 250000,
            max: 600000,
            cost: 2.59,
            schedule: 'D67'
          }
        ],
        38: [
          {
            min: 50000,
            max: 200000,
            cost: 2.94,
            schedule: 'D18'
          },
          {
            min: 250000,
            max: 600000,
            cost: 2.74,
            schedule: 'D68'
          }
        ],
        39: [
          {
            min: 50000,
            max: 200000,
            cost: 3.1,
            schedule: 'D19'
          },
          {
            min: 250000,
            max: 600000,
            cost: 2.89,
            schedule: 'D69'
          }
        ],
        40: [
          {
            min: 50000,
            max: 200000,
            cost: 3.23,
            schedule: 'D20'
          },
          {
            min: 250000,
            max: 600000,
            cost: 3.01,
            schedule: 'D70'
          }
        ],
        41: [
          {
            min: 50000,
            max: 200000,
            cost: 3.39,
            schedule: 'D21'
          },
          {
            min: 250000,
            max: 600000,
            cost: 3.16,
            schedule: 'D71'
          }
        ],
        42: [
          {
            min: 50000,
            max: 200000,
            cost: 3.62,
            schedule: 'D22'
          },
          {
            min: 250000,
            max: 600000,
            cost: 3.37,
            schedule: 'D72'
          }
        ],
        43: [
          {
            min: 50000,
            max: 200000,
            cost: 3.88,
            schedule: 'D23'
          },
          {
            min: 250000,
            max: 600000,
            cost: 3.61,
            schedule: 'D73'
          }
        ],
        44: [
          {
            min: 50000,
            max: 200000,
            cost: 4.1,
            schedule: 'D24'
          },
          {
            min: 250000,
            max: 600000,
            cost: 3.82,
            schedule: 'D74'
          }
        ],
        45: [
          {
            min: 50000,
            max: 200000,
            cost: 4.84,
            schedule: 'D25'
          },
          {
            min: 250000,
            max: 600000,
            cost: 4.5,
            schedule: 'D75'
          }
        ],
        46: [
          {
            min: 50000,
            max: 200000,
            cost: 5.26,
            schedule: 'D26'
          },
          {
            min: 250000,
            max: 600000,
            cost: 4.89,
            schedule: 'D76'
          }
        ],
        47: [
          {
            min: 50000,
            max: 200000,
            cost: 5.71,
            schedule: 'D27'
          },
          {
            min: 250000,
            max: 600000,
            cost: 5.31,
            schedule: 'D77'
          }
        ],
        48: [
          {
            min: 50000,
            max: 200000,
            cost: 6.13,
            schedule: 'D28'
          },
          {
            min: 250000,
            max: 600000,
            cost: 5.7,
            schedule: 'D78'
          }
        ],
        49: [
          {
            min: 50000,
            max: 200000,
            cost: 6.72,
            schedule: 'D29'
          },
          {
            min: 250000,
            max: 600000,
            cost: 6.25,
            schedule: 'D79'
          }
        ],
        50: [
          {
            min: 50000,
            max: 200000,
            cost: 7.83,
            schedule: 'D30'
          },
          {
            min: 250000,
            max: 600000,
            cost: 7.28,
            schedule: 'D80'
          }
        ],
        51: [
          {
            min: 50000,
            max: 200000,
            cost: 8.5,
            schedule: 'D31'
          },
          {
            min: 250000,
            max: 600000,
            cost: 7.91,
            schedule: 'D81'
          }
        ],
        52: [
          {
            min: 50000,
            max: 200000,
            cost: 9.1,
            schedule: 'D32'
          },
          {
            min: 250000,
            max: 600000,
            cost: 8.46,
            schedule: 'D82'
          }
        ],
        53: [
          {
            min: 50000,
            max: 200000,
            cost: 9.85,
            schedule: 'D33'
          },
          {
            min: 250000,
            max: 600000,
            cost: 9.16,
            schedule: 'D83'
          }
        ],
        54: [
          {
            min: 50000,
            max: 200000,
            cost: 10.74,
            schedule: 'D34'
          },
          {
            min: 250000,
            max: 600000,
            cost: 9.99,
            schedule: 'D84'
          }
        ],
        55: [
          {
            min: 50000,
            max: 200000,
            cost: 11.95,
            schedule: 'D35'
          },
          {
            min: 250000,
            max: 600000,
            cost: 11.11,
            schedule: 'D85'
          }
        ],
        56: [
          {
            min: 50000,
            max: 200000,
            cost: 13.03,
            schedule: 'D36'
          },
          {
            min: 250000,
            max: 600000,
            cost: 12.11,
            schedule: 'D86'
          }
        ],
        57: [
          {
            min: 50000,
            max: 200000,
            cost: 14.03,
            schedule: 'D37'
          },
          {
            min: 250000,
            max: 600000,
            cost: 13.05,
            schedule: 'D87'
          }
        ],
        58: [
          {
            min: 50000,
            max: 200000,
            cost: 15.34,
            schedule: 'D38'
          },
          {
            min: 250000,
            max: 600000,
            cost: 14.26,
            schedule: 'D88'
          }
        ],
        59: [
          {
            min: 50000,
            max: 200000,
            cost: 16.76,
            schedule: 'D39'
          },
          {
            min: 250000,
            max: 600000,
            cost: 15.59,
            schedule: 'D89'
          }
        ],
        60: [
          {
            min: 50000,
            max: 200000,
            cost: 18.42,
            schedule: 'D40'
          },
          {
            min: 250000,
            max: 600000,
            cost: 17.13,
            schedule: 'D90'
          }
        ],
        61: [
          {
            min: 50000,
            max: 200000,
            cost: 21.16,
            schedule: 'D41'
          },
          {
            min: 250000,
            max: 600000,
            cost: 19.68,
            schedule: 'D91'
          }
        ],
        62: [
          {
            min: 50000,
            max: 200000,
            cost: 23.25,
            schedule: 'D42'
          },
          {
            min: 250000,
            max: 600000,
            cost: 21.62,
            schedule: 'D92'
          }
        ],
        63: [
          {
            min: 50000,
            max: 200000,
            cost: 25.77,
            schedule: 'D43'
          },
          {
            min: 250000,
            max: 600000,
            cost: 23.97,
            schedule: 'D93'
          }
        ],
        64: [
          {
            min: 50000,
            max: 200000,
            cost: 28.7,
            schedule: 'D44'
          },
          {
            min: 250000,
            max: 600000,
            cost: 26.69,
            schedule: 'D94'
          }
        ]
      },
      20: {
        20: [
          {
            min: 50000,
            max: 200000,
            cost: 2.63,
            schedule: 'H00'
          },
          {
            min: 250000,
            max: 600000,
            cost: 2.45,
            schedule: 'H50'
          }
        ],
        21: [
          {
            min: 50000,
            max: 200000,
            cost: 2.63,
            schedule: 'H01'
          },
          {
            min: 250000,
            max: 600000,
            cost: 2.45,
            schedule: 'H51'
          }
        ],
        22: [
          {
            min: 50000,
            max: 200000,
            cost: 2.63,
            schedule: 'H02'
          },
          {
            min: 250000,
            max: 600000,
            cost: 2.45,
            schedule: 'H52'
          }
        ],
        23: [
          {
            min: 50000,
            max: 200000,
            cost: 2.63,
            schedule: 'H03'
          },
          {
            min: 250000,
            max: 600000,
            cost: 2.45,
            schedule: 'H53'
          }
        ],
        24: [
          {
            min: 50000,
            max: 200000,
            cost: 2.63,
            schedule: 'H04'
          },
          {
            min: 250000,
            max: 600000,
            cost: 2.45,
            schedule: 'H54'
          }
        ],
        25: [
          {
            min: 50000,
            max: 200000,
            cost: 2.63,
            schedule: 'H05'
          },
          {
            min: 250000,
            max: 600000,
            cost: 2.45,
            schedule: 'H55'
          }
        ],
        26: [
          {
            min: 50000,
            max: 200000,
            cost: 2.63,
            schedule: 'H06'
          },
          {
            min: 250000,
            max: 600000,
            cost: 2.45,
            schedule: 'H56'
          }
        ],
        27: [
          {
            min: 50000,
            max: 200000,
            cost: 2.63,
            schedule: 'H07'
          },
          {
            min: 250000,
            max: 600000,
            cost: 2.45,
            schedule: 'H57'
          }
        ],
        28: [
          {
            min: 50000,
            max: 200000,
            cost: 2.63,
            schedule: 'H08'
          },
          {
            min: 250000,
            max: 600000,
            cost: 2.45,
            schedule: 'H58'
          }
        ],
        29: [
          {
            min: 50000,
            max: 200000,
            cost: 2.63,
            schedule: 'H09'
          },
          {
            min: 250000,
            max: 600000,
            cost: 2.45,
            schedule: 'H59'
          }
        ],
        30: [
          {
            min: 50000,
            max: 200000,
            cost: 3.39,
            schedule: 'H10'
          },
          {
            min: 250000,
            max: 600000,
            cost: 3.15,
            schedule: 'H60'
          }
        ],
        31: [
          {
            min: 50000,
            max: 200000,
            cost: 3.39,
            schedule: 'H11'
          },
          {
            min: 250000,
            max: 600000,
            cost: 3.15,
            schedule: 'H61'
          }
        ],
        32: [
          {
            min: 50000,
            max: 200000,
            cost: 3.39,
            schedule: 'H12'
          },
          {
            min: 250000,
            max: 600000,
            cost: 3.15,
            schedule: 'H62'
          }
        ],
        33: [
          {
            min: 50000,
            max: 200000,
            cost: 3.39,
            schedule: 'H13'
          },
          {
            min: 250000,
            max: 600000,
            cost: 3.15,
            schedule: 'H63'
          }
        ],
        34: [
          {
            min: 50000,
            max: 200000,
            cost: 3.39,
            schedule: 'H14'
          },
          {
            min: 250000,
            max: 600000,
            cost: 3.15,
            schedule: 'H64'
          }
        ],
        35: [
          {
            min: 50000,
            max: 200000,
            cost: 4.2,
            schedule: 'H15'
          },
          {
            min: 250000,
            max: 600000,
            cost: 3.9,
            schedule: 'H65'
          }
        ],
        36: [
          {
            min: 50000,
            max: 200000,
            cost: 4.38,
            schedule: 'H16'
          },
          {
            min: 250000,
            max: 600000,
            cost: 4.08,
            schedule: 'H66'
          }
        ],
        37: [
          {
            min: 50000,
            max: 200000,
            cost: 4.63,
            schedule: 'H17'
          },
          {
            min: 250000,
            max: 600000,
            cost: 4.31,
            schedule: 'H67'
          }
        ],
        38: [
          {
            min: 50000,
            max: 200000,
            cost: 4.97,
            schedule: 'H18'
          },
          {
            min: 250000,
            max: 600000,
            cost: 4.62,
            schedule: 'H68'
          }
        ],
        39: [
          {
            min: 50000,
            max: 200000,
            cost: 5.34,
            schedule: 'H19'
          },
          {
            min: 250000,
            max: 600000,
            cost: 4.97,
            schedule: 'H69'
          }
        ],
        40: [
          {
            min: 50000,
            max: 200000,
            cost: 6.81,
            schedule: 'H20'
          },
          {
            min: 250000,
            max: 600000,
            cost: 6.34,
            schedule: 'H70'
          }
        ],
        41: [
          {
            min: 50000,
            max: 200000,
            cost: 7.33,
            schedule: 'H21'
          },
          {
            min: 250000,
            max: 600000,
            cost: 6.81,
            schedule: 'H71'
          }
        ],
        42: [
          {
            min: 50000,
            max: 200000,
            cost: 8.02,
            schedule: 'H22'
          },
          {
            min: 250000,
            max: 600000,
            cost: 7.46,
            schedule: 'H72'
          }
        ],
        43: [
          {
            min: 50000,
            max: 200000,
            cost: 8.82,
            schedule: 'H23'
          },
          {
            min: 250000,
            max: 600000,
            cost: 8.2,
            schedule: 'H73'
          }
        ],
        44: [
          {
            min: 50000,
            max: 200000,
            cost: 9.55,
            schedule: 'H24'
          },
          {
            min: 250000,
            max: 600000,
            cost: 8.88,
            schedule: 'H74'
          }
        ],
        45: [
          {
            min: 50000,
            max: 200000,
            cost: 10.52,
            schedule: 'H25'
          },
          {
            min: 250000,
            max: 600000,
            cost: 9.79,
            schedule: 'H75'
          }
        ],
        46: [
          {
            min: 50000,
            max: 200000,
            cost: 11.34,
            schedule: 'H26'
          },
          {
            min: 250000,
            max: 600000,
            cost: 10.55,
            schedule: 'H76'
          }
        ],
        47: [
          {
            min: 50000,
            max: 200000,
            cost: 12.37,
            schedule: 'H27'
          },
          {
            min: 250000,
            max: 600000,
            cost: 11.5,
            schedule: 'H77'
          }
        ],
        48: [
          {
            min: 50000,
            max: 200000,
            cost: 13.29,
            schedule: 'H28'
          },
          {
            min: 250000,
            max: 600000,
            cost: 12.36,
            schedule: 'H78'
          }
        ],
        49: [
          {
            min: 50000,
            max: 200000,
            cost: 14.6,
            schedule: 'H29'
          },
          {
            min: 250000,
            max: 600000,
            cost: 13.58,
            schedule: 'H79'
          }
        ],
        50: [
          {
            min: 50000,
            max: 200000,
            cost: 16.04,
            schedule: 'H30'
          },
          {
            min: 250000,
            max: 600000,
            cost: 14.92,
            schedule: 'H80'
          }
        ],
        51: [
          {
            min: 50000,
            max: 200000,
            cost: 17.71,
            schedule: 'H31'
          },
          {
            min: 250000,
            max: 600000,
            cost: 16.47,
            schedule: 'H81'
          }
        ],
        52: [
          {
            min: 50000,
            max: 200000,
            cost: 19.64,
            schedule: 'H32'
          },
          {
            min: 250000,
            max: 600000,
            cost: 18.26,
            schedule: 'H82'
          }
        ],
        53: [
          {
            min: 50000,
            max: 200000,
            cost: 21.85,
            schedule: 'H33'
          },
          {
            min: 250000,
            max: 600000,
            cost: 20.32,
            schedule: 'H83'
          }
        ],
        54: [
          {
            min: 50000,
            max: 200000,
            cost: 24.42,
            schedule: 'H34'
          },
          {
            min: 250000,
            max: 600000,
            cost: 22.71,
            schedule: 'H84'
          }
        ]
      }
    }
  }
};

export default rates;
