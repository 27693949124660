import { certificates as coreCertificates } from 'config/certificates';
import { getCertificatesHelpers } from 'helpers/applications';
import { getFileLink } from 'helpers/resources';

export const certificates = {
  ...coreCertificates,
  nwNexgenChildCert: {
    link: getFileLink('file-cert-nexgen-child-nw-cert'),
    label: 'NW NexGen Dependent Certificate'
  },
  akNexgenChildCert: {
    link: getFileLink('file-cert-nexgen-child-ak-cert'),
    label: 'AK NexGen Dependent Certificate'
  },
  laNexgenChildCert: {
    link: getFileLink('file-cert-nexgen-child-la-cert'),
    label: 'LA NexGen Dependent Certificate'
  },
  mdNexgenChildCert: {
    link: getFileLink('file-cert-nexgen-child-md-cert'),
    label: 'MD NexGen Dependent Certificate'
  },
  mtNexgenChildCert: {
    link: getFileLink('file-cert-nexgen-child-mt-cert'),
    label: 'MT NexGen Dependent Certificate'
  },
  ncNexgenChildCert: {
    link: getFileLink('file-cert-nexgen-child-nc-cert'),
    label: 'NC NexGen Dependent Certificate'
  },
  nhNexgenChildCert: {
    link: getFileLink('file-cert-nexgen-child-nh-cert'),
    label: 'NH NexGen Dependent Certificate'
  },
  nyNexgenChildCert: {
    link: getFileLink('file-cert-nexgen-child-ny-cert'),
    label: 'NY NexGen Dependent Certificate'
  },
  txNexgenChildCert: {
    link: getFileLink('file-cert-nexgen-child-tx-cert'),
    label: 'TX NexGen Dependent Certificate'
  },
  utNexgenChildCert: {
    link: getFileLink('file-cert-nexgen-child-ut-cert'),
    label: 'UT NexGen Dependent Certificate'
  },
  waNexgenChildCert: {
    link: getFileLink('file-cert-nexgen-child-wa-cert'),
    label: 'WA NexGen Dependent Certificate'
  }
};

export const nationalWideConfig = [
  'nwNexgenChildCert',
  'nwChildRider',
  'howWePay1Rider'
];

export const stateSpecificConfig = [
  // Alaska
  {
    states: ['AK'],
    certs: ['akNexgenChildCert', 'akChildRider', 'howWePay1Rider']
  },
  // California
  {
    states: ['CA'],
    certs: [
      'nwNexgenChildCert',
      'nwChildRider',
      'howWePay1Rider',
      'caProofRider'
    ]
  },
  // Idaho
  {
    states: ['ID'],
    certs: ['nwNexgenChildCert', 'idChildRider', 'howWePay1Rider']
  },
  // Luisiana
  {
    states: ['LA'],
    certs: ['laNexgenChildCert', 'nwChildRider', 'howWePay1Rider']
  },
  // Maryland
  {
    states: ['MD'],
    certs: ['mdNexgenChildCert', 'nwChildRider', 'howWePay2Rider']
  },
  // Montana
  {
    states: ['MT'],
    certs: ['mtNexgenChildCert', 'mtChildRider', 'howWePay1Rider']
  },
  // North Carolina
  {
    states: ['NC'],
    certs: ['ncNexgenChildCert', 'nwChildRider', 'howWePay1Rider']
  },
  // New Hampshire
  {
    states: ['NH'],
    certs: ['nhNexgenChildCert', 'nwChildRider', 'howWePay1Rider']
  },
  // New Mexico
  {
    states: ['NM'],
    certs: ['nwNexgenChildCert', 'nmChildRider', 'howWePay1Rider']
  },
  // New York
  {
    states: ['NY'],
    certs: ['nyNexgenChildCert', 'nwChildRider', 'howWePay1Rider']
  },
  // Texas
  {
    states: ['TX'],
    certs: ['txNexgenChildCert', 'txChildRider', 'howWePay1Rider']
  },
  // Utah
  {
    states: ['UT'],
    certs: ['utNexgenChildCert', 'nwChildRider', 'howWePay1Rider']
  },
  // Washington
  {
    states: ['WA'],
    certs: ['waNexgenChildCert', 'waChildRider', 'howWePay2Rider']
  },
  {
    states: ['AR', 'CT', 'DE', 'FL', 'MI', 'NJ', 'OH', 'RI'],
    certs: ['nwNexgenChildCert', 'nwChildRider', 'howWePay2Rider']
  }
];

export function getCertificates(state) {
  const stateConfig = getCertificatesHelpers.getStateSpecificConfig({
    state,
    stateSpecificConfig
  });

  return {
    nw: getCertificatesHelpers.getCertificates({
      certsConfig: nationalWideConfig,
      certificates
    }),
    certs: getCertificatesHelpers.getCertificates({
      certsConfig: stateConfig,
      certificates
    })
  };
}
