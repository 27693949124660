import { BRAND } from 'config/core';
import { getFileLink, getPageLink } from 'helpers/resources';

import { getCertificates } from './getCertificates';

const levelTermConfig = {
  common: {
    brand: BRAND.metlife,
    type: 'L6',
    term20MaxAge: 54,
    maxAge: 64,
    minAge: 20,
    coverageAmount: {
      min: 50000,
      max: 600000,
      step: 50000
    },
    notAvailableForStates: ['AA', 'AE', 'AP', 'ME', 'MP', 'OR'],
    shortName: 'Level Term',
    getCertificates
  },
  'level-term': {
    shortName: 'Level Term Life Insurance',
    appLink: getPageLink('page-level-term-app'),
    pdf: getFileLink('file-level-term-brochure')
  },
  'level-term-spouse': {
    shortName: 'Level Term Life Insurance',
    appLink: getPageLink('page-level-term-spouse-app'),
    pdf: getFileLink('file-level-term-spouse-brochure'),
    dutyStatuses: ['I', '2', '1', 'N-', 'N', '3', '7'],
    memberStatuses: ['N1', 'N-1', 'A3', 'C1']
  },
  'federal-civilian-level-term': {
    shortName: 'Current Federal Employee Level Term Life Insurance',
    appLink: getPageLink('page-federal-civilian-level-term-app'),
    pdf: getFileLink('file-federal-civilian-level-term-brochure'),
    coverageAmount: {
      min: 50000,
      max: 500000,
      step: 50000
    }
  },
  'federal-civilian-level-term-spouse': {
    shortName: 'Current Federal Employee Level Term Life Insurance',
    appLink: getPageLink('page-federal-civilian-level-term-spouse-app'),
    pdf: getFileLink('file-federal-civilian-level-term-spouse-brochure'),
    coverageAmount: {
      min: 50000,
      max: 500000,
      step: 50000
    }
  }
};

export default levelTermConfig;
