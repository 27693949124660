import _find from 'lodash/find';

import gtmEcommerce from './gtm-ecommerce';

// console.log('window.GTDATALAYER', window.GTDATALAYER);
// console.log("window['google_tag_manager']", window['google_tag_manager']);

const dataLayer = window.GTDATALAYER;
const dataLayerIsset = !!(dataLayer && Array.isArray(dataLayer));

function getEvent(desc) {
  return dataLayerIsset ? _find(dataLayer, desc) : null;
}

function pushEvent(event) {
  dataLayerIsset && dataLayer.push(event);
}

function ecommerce(eventName, args) {
  const eventCb = gtmEcommerce[eventName];

  // console.log(args);

  if (!eventCb) {
    console.warn(`Wrong GTM event name "${eventName}"!`);

    return;
  }

  try {
    const ecommerce = eventCb(args);

    // console.log('ecommerce', ecommerce);

    pushEvent({ ecommerce: null });

    pushEvent({ event: eventName, ecommerce });
  } catch (error) {
    console.error(`Error on "${eventName}" ecommerce event!`);
  }
}

const GTM = {
  getEvent,
  pushEvent,
  ecommerce
};

export default GTM;
