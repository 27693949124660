import PropTypes from 'prop-types';
import React from 'react';

const propTypes = {
  src: PropTypes.string.isRequired,
  alt: PropTypes.string.isRequired,
  webp: PropTypes.string
};

const Image = ({ src, alt, webp, ...args }) => {
  const imgEl = <img src={src} alt={alt} {...args} />;

  return webp ? (
    <picture>
      <source srcSet={webp} type="image/webp" />
      {imgEl}
    </picture>
  ) : (
    imgEl
  );
};

Image.propTypes = propTypes;

export default Image;
