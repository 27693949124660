import dateAdd from 'date-fns/add';
import dateDifferenceInYears from 'date-fns/differenceInYears';
import dateIsValid from 'date-fns/isValid';
import dateIsWithinInterval from 'date-fns/isWithinInterval';
import dateParse from 'date-fns/parse';
import dateSub from 'date-fns/sub';

import { consoleMessage } from './helpers';

export function dateIsWithingPendingPeriod(
  dateString,
  pendingPeriod,
  units = 'hours'
) {
  const validUnits = [
    'years',
    'months',
    'weeks',
    'days',
    'hours',
    'minutes',
    'seconds'
  ];
  if (validUnits.indexOf(units) === -1)
    throw new Error(
      `Not valid units passed into "dateIsWithingPendingPeriod": ${units}`
    );
  if (!dateString || typeof dateString !== 'string') return false;
  pendingPeriod = parseInt(pendingPeriod);
  if (!pendingPeriod) return false;
  const targetDate = new Date(dateString);
  if (!dateIsValid(targetDate)) return false;
  const now = new Date();
  const minDate = dateSub(now, { [units]: pendingPeriod });
  return dateIsWithinInterval(targetDate, {
    start: minDate,
    end: now
  });
}

export function _getAge(birthDateString, format, now) {
  try {
    const dateOfBirth = dateParse(birthDateString, format, new Date());
    return dateIsValid(dateOfBirth) && dateIsValid(now)
      ? dateDifferenceInYears(now, dateOfBirth)
      : null;
  } catch (error) {
    consoleMessage('Error in _getAge', error);
    return null;
  }
}

export function getAge(birthDateString, format = 'MM/dd/yyyy') {
  return _getAge(birthDateString, format, new Date());
}

export function getCurrentYear() {
  return new Date().getFullYear();
}

/**
 * Adds duration to current date and returns this date
 */
export function getDate(duration) {
  return dateAdd(new Date(), duration);
}

export function getMbaLocalDate() {
  // TODO: should return date for 'America/New_York'
  return new Date();
}
