const filterCallback = data => {
  return data.reduce((memo, { path, seo }) => {
    if (path && seo && (seo.title || seo.description || seo.canonical)) {
      memo[path] = seo;
    }

    return memo;
  }, {});
};

export default filterCallback;
