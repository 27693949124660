import ErrorStrings from 'config/error-strings';
import _cloneDeep from 'lodash/cloneDeep';
import _concat from 'lodash/concat';

import * as Types from './types';

const initialState = {
  loading: false,
  data: null,
  next: 0,
  hasNext: false,
  errorMessage: ''
};

const reducer = (state = initialState, action = {}) => {
  const { type, payload = {} } = action;
  let newState;

  switch (type) {
    case Types.REQUEST_FOR_OUTREACHES:
      newState = _cloneDeep(state);
      newState.loading = true;
      newState.errorMessage = '';
      return newState;

    case Types.GET_OUTREACHES_SUCCESS:
      newState = _cloneDeep(state);
      const {
        data,
        pages: { next, hasNext }
      } = payload;
      newState.loading = false;
      newState.data = _concat(newState.data || [], data);
      newState.next = next;
      newState.hasNext = hasNext;
      newState.errorMessage = '';
      return newState;

    case Types.GET_OUTREACHES_ERROR:
      newState = _cloneDeep(state);
      newState.loading = false;
      newState.errorMessage = ErrorStrings.apiError;
      return newState;

    default:
      return state;
  }
};

const reducerConfig = {
  initialState,
  reducer,
  filter: ['data', 'next', 'hasNext']
};

export default reducerConfig;
