import { all } from 'redux-saga/effects';
import request from 'store/middlewares/request/sagas';
import applications from 'store/parts/applications/sagas';
import user from 'store/parts/user/sagas';

function* sagas() {
  yield all([...request, ...applications, ...user]);
}

export default sagas;
