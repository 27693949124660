import { getAppConfig } from 'helpers/applications';
import _get from 'lodash/get';

// custom event
function startApp(args) {
  return {
    app_step: args.step,
    item_id: args.formId,
    item_name: args.formId,
    item_brand: getAppConfig(args.appId, args.formId, 'brand'),
    item_category: args.appId,
    items: [
      {
        item_id: args.formId,
        item_name: args.formId,
        item_brand: getAppConfig(args.appId, args.formId, 'brand'),
        item_category: args.appId
      }
    ]
  };
}

// custom event
function startAppStep(args) {
  const data = {
    item_id: args.formId,
    item_name: args.formId,
    item_brand: getAppConfig(args.appId, args.formId, 'brand'),
    item_category: args.appId
  };

  return {
    app_step: args.step,
    ...data,
    items: [data]
  };
}

// custom event
function finishAppStep(args) {
  const data = {
    item_id: args.formId,
    item_name: args.formId,
    item_brand: getAppConfig(args.appId, args.formId, 'brand'),
    item_category: args.appId
  };

  return {
    app_step: args.step,
    ...data,
    items: [data]
  };
}

// https://developers.google.com/analytics/devguides/collection/ga4/reference/events?client_type=gtm#purchase
function purchase(args) {
  const appId = args.appType;

  const formId = args.formType;

  // get calculated amount if exists
  let value = _get(args, 'payment.amount', false);

  // try to calculate if not
  if (value === false) {
    const premium = _get(args, 'policy.premium', 0);

    const dependentsPremium = _get(args, 'policy.dependents.premium', 0);

    const spousePremium = _get(args, 'policy.spouse.premium', 0);

    value = premium + dependentsPremium + spousePremium;
  }

  const schedule = _get(args, 'policy.schedule');

  const baseData = {
    item_id: formId,
    item_name: formId,
    item_brand: getAppConfig(appId, formId, 'brand'),
    item_category: appId
  };

  return {
    ...baseData,
    currency: 'USD',
    transaction_id: args.rdAppId,
    value,
    app_step: args.step,
    items: [
      {
        ...baseData,
        item_variant: schedule,
        price: value
      }
    ]
  };
}

const gtmEcommerce = {
  start_app: startApp,
  start_app_step: startAppStep,
  finish_app_step: finishAppStep,
  purchase
};

export default gtmEcommerce;
