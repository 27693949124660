// the same codes can have different names or different meaning in aqua
// should be handled by id

const dutyStatuses = [
  { id: '2', code: '2', name: 'Full-time Active Duty' },
  { id: '2.1', code: '2', name: 'Active' },

  { id: '1', code: '1', name: 'Ready Reserve' },
  { id: '1.1', code: '1', name: 'Reservist' },

  // "C" will be converted to "1" in Aqua
  { id: 'C', code: 'C', name: 'Academy Cadet' },

  { id: '11', code: '1', name: 'Academy Cadet' },

  {
    id: '3',
    code: '3',
    name: 'Retired',
    isFormer: true,
    typeOfPayLabel: 'Type of pay for Retired'
  },

  {
    id: '7',
    code: '7',
    name: 'Separated from Military',
    isFormer: true,
    typeOfPayLabel: 'Type of pay for Separated'
  },
  { id: 'N-', code: 'N-', name: 'National Guard/Retired' },

  { id: 'N', code: 'N', name: 'National Guard/Active' },
  { id: 'N1', code: 'N', name: 'National Guard' },

  // "I" will be converted to "2" in Aqua
  { id: 'I', code: 'I', name: 'An insured Member of MBA' },

  { id: '5', code: '5', name: 'Honorably Discharged Veteran (Not Retired)' },

  { id: '8', code: '8', name: 'Current Federal Employee' },
  { id: '8.1', code: '8', name: 'Federal Civilian Employee' },

  {
    id: '9',
    code: '9',
    name: 'Spouse (of any of the above)',
    isSpouse: true
  },
  {
    id: '9.1',
    code: '9',
    name: 'Sponsored Spouse',
    isSpouse: true
  }
];

export default dutyStatuses;
