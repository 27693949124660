import { mergeAndReplaceArrays } from 'helpers/helpers';
import _cloneDeep from 'lodash/cloneDeep';
import _get from 'lodash/get';
import _set from 'lodash/set';
import _unset from 'lodash/unset';

import * as Types from './types';

const initialState = {
  loading: {},
  data: {}, // submitted screens data
  tempData: {}, // active screen data
  utilData: {
    hospital: {
      intermediate: {}
    },
    tricare: {
      intermediate: {}
    }
  }, // additional apps data
  errorMessage: {},
  lastModifiedAt: null // used for currently opened app
};

const reducer = (state = initialState, action = {}) => {
  const { type, payload = {} } = action;
  let newState;
  let appPath;

  switch (type) {
    // this will completely rewrite data for target application
    case Types.STORE_APPLICATION_DATA:
      newState = _cloneDeep(state);

      appPath = [payload.appId, payload.formId];

      _set(newState.data, appPath, payload.appStore);

      if (payload.appIntermediateStore) {
        appPath = ['hospital', 'tricare'].includes(payload.appId)
          ? [payload.appId, 'intermediate']
          : [payload.appId, payload.formId, 'intermediate'];

        _set(newState.utilData, appPath, payload.appIntermediateStore);
      }

      return newState;

    case Types.STORE_APPLICATION_TEMP_DATA:
      newState = _cloneDeep(state);

      appPath = [payload.appId, payload.formId];

      // merge current and new temp data
      _set(
        newState.tempData,
        appPath,
        mergeAndReplaceArrays(
          {},
          _get(newState.tempData, appPath, {}),
          payload.values
        )
      );

      return newState;

    // set application temp data from source application
    case Types.SET_APPLICATION_TEMP_DATA_FROM_SOURCE:
      newState = _cloneDeep(state);

      _set(
        newState.tempData,
        [payload.targetAppId, payload.targetAppFormId],
        mergeAndReplaceArrays(
          {},
          // current target temp data
          _get(
            newState.tempData,
            [payload.targetAppId, payload.targetAppFormId],
            {}
          ),
          // source temp data
          _get(
            newState.tempData,
            [payload.sourceAppId, payload.sourceAppFormId],
            {}
          ),
          // source submitted data
          _get(
            newState.data,
            [payload.sourceAppId, payload.sourceAppFormId],
            {}
          )
        )
      );

      // unset util parameters of source app in target app temp data
      ['tempAppId', 'lastPassedStep'].forEach(key => {
        _unset(newState.tempData, [
          payload.targetAppId,
          payload.targetAppFormId,
          key
        ]);
      });

      return newState;

    case Types.STORE_HOSPITAL_INTERMEDIATE_DATA:
      newState = _cloneDeep(state);

      appPath = 'hospital.intermediate';

      _set(newState.utilData, appPath, payload);

      return newState;

    case Types.STORE_TRICARE_INTERMEDIATE_DATA:
      newState = _cloneDeep(state);

      appPath = 'tricare.intermediate';

      _set(newState.utilData, appPath, payload);

      return newState;

    // send application step request
    case Types.APPLICATION_STEP_REQUEST:
      newState = _cloneDeep(state);
      appPath = [
        _get(payload, 'data.data.appId'),
        _get(payload, 'data.data.formId')
      ];
      _set(newState.loading, appPath, true);
      return newState;

    case Types.APPLICATION_STEP_REQUEST_SUCCESS:
      newState = _cloneDeep(state);
      appPath = [payload.appId, payload.formId];
      _set(newState.loading, appPath, false);
      return newState;

    case Types.APPLICATION_STEP_REQUEST_ERROR:
      newState = _cloneDeep(state);
      appPath = [payload.appId, payload.formId];
      _set(newState.loading, appPath, false);
      return newState;

    // finish application step
    case Types.FINISH_APPLICATION_STEP:
      newState = _cloneDeep(state);
      appPath = [payload.appId, payload.formId];
      let currentAppData = _get(newState.data, appPath, {});
      // enrich app data
      currentAppData = mergeAndReplaceArrays({}, currentAppData, payload.data);
      // set last passed step
      _set(currentAppData, 'lastPassedStep', payload.step);
      // set new app data
      _set(newState.data, appPath, currentAppData);
      return newState;

    // finish application
    case Types.FINISH_APPLICATION:
      newState = _cloneDeep(state);

      appPath = [payload.appId, payload.formId];

      // remove app submitted data
      _unset(newState.data, appPath);

      // remove app form temp data
      _unset(newState.tempData, appPath);

      return newState;

    case Types.SET_APPLICATION_LAST_PASSED_STEP:
      newState = _cloneDeep(state);

      // set last passed step
      _set(
        newState.data,
        [payload.appId, payload.formId, 'lastPassedStep'],
        payload.step
      );

      return newState;

    case Types.SET_APPLICATION_LAST_MODIFIED_TIME:
      newState = _cloneDeep(state);

      newState.lastModifiedAt = payload;

      return newState;

    default:
      return state;
  }
};

const reducerConfig = { initialState, reducer, filter: ['data', 'utilData'] };

export default reducerConfig;
