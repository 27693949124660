import { getPageLink } from 'helpers/resources';

const petConfig = {
  startDate: '12/01/2022 15:00',
  // should note have end date
  endDate: '12/01/2099 15:00',
  tz: 'America/Chicago',
  dateFormatString: 'MM/dd/yyyy HH:mm',
  applyLink: getPageLink('page-pet-insurance-lp')
};

export default petConfig;
