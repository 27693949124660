import { BRAND } from 'config/core';

import { getCertificates } from './getCertificates';

const term90 = {
  common: {
    brand: BRAND.metlife,
    type: 'L3',
    notAvailableForStates: [],
    getCertificates,
    shortName: 'Term 90 Life Insurance'
  },
  'term-90': {}
};

export default term90;
