import { BRAND } from 'config/core';

import { getCertificates } from './getCertificates';

const levelTermL1L2Config = {
  common: {
    brand: BRAND.metlife,
    notAvailableForStates: [],
    shortName: 'Level Term Life Insurance I/II',
    getCertificates
  },
  'level-term-l1': {
    type: 'L1',
    shortName: 'Level Term Life Insurance I'
  },
  'level-term-l2': {
    type: 'L2',
    shortName: 'Level Term Life Insurance II'
  }
};

export default levelTermL1L2Config;
