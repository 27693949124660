import { certificates as coreCertificates } from 'config/certificates';
import { getCertificatesHelpers } from 'helpers/applications';
import { getFileLink } from 'helpers/resources';

export const certificates = {
  ...coreCertificates,
  nwTerm90L3Cert: {
    link: getFileLink('file-cert-term-90-l3-nw-cert'),
    label: 'NW Term 90 Certificate'
  },
  term90L3Rates: {
    link: getFileLink('file-cert-term-90-l3-rates'),
    label: 'Term 90 Rates'
  },
  akTerm90L3Cert: {
    link: getFileLink('file-cert-term-90-l3-ak-cert'),
    label: 'AK Term 90 Certificate'
  },
  ksTerm90L3AboRider: {
    link: getFileLink('file-cert-term-90-l3-ks-abo-rider'),
    label: 'KS Term 90 ABO Rider'
  },
  laTerm90L3Cert: {
    link: getFileLink('file-cert-term-90-l3-la-cert'),
    label: 'LA Term 90 Certificate'
  },
  mdTerm90L3Cert: {
    link: getFileLink('file-cert-term-90-l3-md-cert'),
    label: 'MD Term 90 Certificate'
  },
  mtTerm90L3Cert: {
    link: getFileLink('file-cert-term-90-l3-mt-cert'),
    label: 'MT Term 90 Certificate'
  },
  ncTerm90L3Cert: {
    link: getFileLink('file-cert-term-90-l3-nc-cert'),
    label: 'NC Term 90 Certificate'
  },
  nhTerm90L3Cert: {
    link: getFileLink('file-cert-term-90-l3-nh-cert'),
    label: 'NH Term 90 Certificate'
  },
  nyTerm90L3Cert: {
    link: getFileLink('file-cert-term-90-l3-ny-cert'),
    label: 'NY Term 90 Certificate'
  },
  txTerm90L3Cert: {
    link: getFileLink('file-cert-term-90-l3-tx-cert'),
    label: 'TX Term 90 Certificate'
  },
  utTerm90L3Cert: {
    link: getFileLink('file-cert-term-90-l3-ut-cert'),
    label: 'UT Term 90 Certificate'
  },
  waTerm90L3Cert: {
    link: getFileLink('file-cert-term-90-l3-wa-cert'),
    label: 'WA Term 90 Certificate'
  }
};

export const nationalWideConfig = [
  'nwTerm90L3Cert',
  'nwChildRider',
  'nwFamilyRider',
  'howWePay1Rider',
  'term90L3Rates'
];

export const stateSpecificConfig = [
  // Alaska
  {
    states: ['AK'],
    certs: [
      'akTerm90L3Cert',
      'akChildRider',
      'akFamilyRider',
      'howWePay1Rider',
      'term90L3Rates'
    ]
  },
  // California
  {
    states: ['CA'],
    certs: [
      'nwTerm90L3Cert',
      'nwChildRider',
      'nwFamilyRider',
      'howWePay1Rider',
      'term90L3Rates',
      'caProofRider'
    ]
  },
  // Guam
  {
    states: ['GU'],
    certs: [
      'nwTerm90L3Cert',
      'nwChildRider',
      'nwFamilyRider',
      'howWePay1Rider',
      'term90L3Rates',
      'guPowMiaRider'
    ]
  },
  // Idaho
  {
    states: ['ID'],
    certs: [
      'nwTerm90L3Cert',
      'idChildRider',
      'nwFamilyRider',
      'howWePay1Rider',
      'term90L3Rates'
    ]
  },
  // Kansas
  {
    states: ['KS'],
    certs: [
      'nwTerm90L3Cert',
      'nwChildRider',
      'nwFamilyRider',
      'howWePay1Rider',
      'term90L3Rates',
      'ksTerm90L3AboRider'
    ]
  },
  // Luisiana
  {
    states: ['LA'],
    certs: [
      'laTerm90L3Cert',
      'laChildRider',
      'laFamilyRider',
      'howWePay1Rider',
      'term90L3Rates'
    ]
  },
  // Maryland
  {
    states: ['MD'],
    certs: [
      'mdTerm90L3Cert',
      'nwChildRider',
      'mdFamilyRider',
      'howWePay2Rider',
      'term90L3Rates'
    ]
  },
  // Montana
  {
    states: ['MT'],
    certs: [
      'mtTerm90L3Cert',
      'mtChildRider',
      'nwFamilyRider',
      'howWePay1Rider',
      'term90L3Rates'
    ]
  },
  // North Carolina
  {
    states: ['NC'],
    certs: [
      'ncTerm90L3Cert',
      'nwChildRider',
      'nwFamilyRider',
      'howWePay1Rider',
      'term90L3Rates'
    ]
  },
  // New Hampshire
  {
    states: ['NH'],
    certs: [
      'nhTerm90L3Cert',
      'nwChildRider',
      'nwFamilyRider',
      'howWePay1Rider',
      'term90L3Rates'
    ]
  },
  // New Mexico
  {
    states: ['NM'],
    certs: [
      'nwTerm90L3Cert',
      'nmChildRider',
      'nwFamilyRider',
      'howWePay1Rider',
      'term90L3Rates'
    ]
  },
  // New York
  {
    states: ['NY'],
    certs: [
      'nyTerm90L3Cert',
      'nyChildRider',
      'nyFamilyRider',
      'howWePay1Rider',
      'term90L3Rates'
    ]
  },
  // Puerto Rico
  {
    states: ['PR'],
    certs: [
      'nwTerm90L3Cert',
      'nwChildRider',
      'nwFamilyRider',
      'howWePay1Rider',
      'term90L3Rates',
      'prPowMiaRider'
    ]
  },
  // Texas
  {
    states: ['TX'],
    certs: [
      'txTerm90L3Cert',
      'txChildRider',
      'txFamilyRider',
      'howWePay1Rider',
      'term90L3Rates'
    ]
  },
  // Utah
  {
    states: ['UT'],
    certs: [
      'utTerm90L3Cert',
      'nwChildRider',
      'nwFamilyRider',
      'howWePay1Rider',
      'term90L3Rates'
    ]
  },
  // Washington
  {
    states: ['WA'],
    certs: [
      'waTerm90L3Cert',
      'waChildRider',
      'waFamilyRider',
      'howWePay2Rider',
      'term90L3Rates'
    ]
  },
  {
    states: ['AR', 'CT', 'DE', 'FL', 'MI', 'NJ', 'OH', 'RI'],
    certs: [
      'nwTerm90L3Cert',
      'nwChildRider',
      'nwFamilyRider',
      'howWePay2Rider',
      'term90L3Rates'
    ]
  }
];

export function getCertificates(state) {
  const stateConfig = getCertificatesHelpers.getStateSpecificConfig({
    state,
    stateSpecificConfig
  });

  return {
    nw: getCertificatesHelpers.getCertificates({
      certsConfig: nationalWideConfig,
      certificates
    }),
    certs: getCertificatesHelpers.getCertificates({
      certsConfig: stateConfig,
      certificates
    })
  };
}
