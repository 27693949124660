import _cloneDeep from 'lodash/cloneDeep';

import * as Types from './types';

const initialState = {
  loading: false
};

const reducer = (state = initialState, action = {}) => {
  const { type } = action;
  let newState;

  switch (type) {
    case Types.REQUEST_FOR_UPDATE_PAYMENT_METHOD:
      newState = _cloneDeep(state);
      newState.loading = true;
      return newState;

    case Types.UPDATE_PAYMENT_METHOD_SUCCESS:
    case Types.UPDATE_PAYMENT_METHOD_ERROR:
      newState = _cloneDeep(state);
      newState.loading = false;
      return newState;

    default:
      return state;
  }
};

const reducerConfig = { initialState, reducer };

export default reducerConfig;
