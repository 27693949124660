import './PageLoading.scss';

import classnames from 'classnames';
import Loading from 'components/partials/loadings/components/loading/Loading';
import PropTypes from 'prop-types';
import React from 'react';

const propTypes = {
  className: PropTypes.string
};

const PageLoading = ({ className }) => {
  return (
    <div className={classnames('page-loading', className)}>
      <Loading />
    </div>
  );
};

PageLoading.propTypes = propTypes;

export default PageLoading;
