import ErrorStrings from 'config/error-strings';
import _cloneDeep from 'lodash/cloneDeep';

import * as Types from './types';

const initialState = {
  loading: false,
  slug: null,
  formId: null,
  urlParams: null,
  errorMessage: ''
};

const reducer = (state = initialState, action = {}) => {
  const { type, payload } = action;
  let newState;

  switch (type) {
    case Types.REQUEST_FOR_AGENT_URL:
      newState = _cloneDeep(state);
      newState.loading = true;
      newState.errorMessage = '';
      return newState;

    case Types.GET_AGENT_URL_SUCCESS:
      newState = _cloneDeep(state);
      const { slug, formId, urlParams } = payload;
      newState.slug = slug;
      newState.formId = formId;
      newState.urlParams = urlParams;
      newState.loading = false;
      newState.errorMessage = '';
      return newState;

    case Types.GET_AGENT_URL_ERROR:
      newState = _cloneDeep(state);
      newState.loading = false;
      newState.errorMessage = '';
      newState.slug = '';
      newState.formId = '';
      newState.urlParams = {};
      newState.errorMessage = ErrorStrings.nothingFound;
      return newState;

    default:
      return state;
  }
};

const reducerConfig = { initialState, reducer };

export default reducerConfig;
