import _cloneDeep from 'lodash/cloneDeep';

import * as Types from './types';

const initialState = {
  loading: {},
  errorMessage: {}
};

const reducer = (state = initialState, action = {}) => {
  const { type, payload } = action;
  let newState;

  switch (type) {
    case Types.REQUEST_FOR_EMAIL_BENCHMARK:
      newState = _cloneDeep(state);
      newState.loading.benchmark = true;
      newState.errorMessage.benchmark = '';
      return newState;

    case Types.EMAIL_SUCCESS_BENCHMARK:
      newState = _cloneDeep(state);
      newState.loading.benchmark = false;
      return newState;

    case Types.EMAIL_ERROR_BENCHMARK:
      newState = _cloneDeep(state);
      newState.loading.benchmark = false;
      newState.errorMessage.benchmark = payload;
      return newState;

    case Types.REQUEST_FOR_EMAIL_SHAREAPP:
      newState = _cloneDeep(state);
      newState.loading.shareApplication = true;
      newState.errorMessage.shareApplication = '';
      return newState;

    case Types.EMAIL_SUCCESS_SHAREAPP:
      newState = _cloneDeep(state);
      newState.loading.shareApplication = false;
      return newState;

    case Types.EMAIL_ERROR_SHAREAPP:
      newState = _cloneDeep(state);
      newState.loading.shareApplication = false;
      newState.errorMessage.shareApplication = payload;
      return newState;

    default:
      return state;
  }
};

const reducerConfig = {
  reducer
};

export default reducerConfig;
