import ErrorStrings from 'config/error-strings';
import _cloneDeep from 'lodash/cloneDeep';

import * as Types from './types';

const initialState = {
  loading: false,
  data: null,
  errorMessage: ''
};

const reducer = (state = initialState, action = {}) => {
  const { type, payload = {} } = action;
  let newState;

  switch (type) {
    case Types.REQUEST_FOR_SEO:
      newState = _cloneDeep(state);
      newState.loading = true;
      newState.errorMessage = '';
      return newState;

    case Types.GET_SEO_SUCCESS:
      newState = _cloneDeep(state);
      newState.loading = false;
      newState.data = payload;
      newState.errorMessage = '';
      return newState;

    case Types.GET_SEO_ERROR:
      newState = _cloneDeep(state);
      newState.loading = false;
      newState.errorMessage = ErrorStrings.apiError;
      return newState;

    default:
      return state;
  }
};

const reducerConfig = { initialState, reducer };

export default reducerConfig;
