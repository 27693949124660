import axios from 'axios';

import RequestError from './errors/request-error';

const request = async config => {
  // https://github.com/axios/axios#request-config
  return await axios(config)
    .then(({ data }) => {
      return data;
    })
    .catch(error => {
      // https://www.npmjs.com/package/axios#handling-errors
      throw new RequestError(error);
    });
};

export default request;
