import GTM from './gtm';

export function trackPage(location) {
  GTM.pushEvent({
    event: 'App Page View',
    appPagePath: location.pathname,
    appPageQuery: location.search,
    appPageHash: location.hash
  });
}
