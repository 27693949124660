import _cloneDeep from 'lodash/cloneDeep';
import _unset from 'lodash/unset';

/**
 * 0, 1, 2, N are versions
 * "Change state if user's current store version is not N"
 *
 * https://github.com/rt2zz/redux-persist/blob/master/docs/migrations.md
 */

const migrations = {
  1: state => {
    const _state = _cloneDeep(state);

    // clear data for tricare app
    _unset(_state, 'applications.data.tricare');

    return _state;
  }
};

export default migrations;
