// digital signup request
export const REQUEST_FOR_DIGITAL_SIGNUP = 'REQUEST_FOR_DIGITAL_SIGNUP';
export const DIGITAL_SIGNUP_REQUEST_SUCCESS = 'DIGITAL_SIGNUP_REQUEST_SUCCESS';
export const DIGITAL_SIGNUP_REQUEST_ERROR = 'DIGITAL_SIGNUP_REQUEST_ERROR';
// digital signup events request
export const REQUEST_FOR_DIGITAL_SIGNUP_EVENTS =
  'REQUEST_FOR_DIGITAL_SIGNUP_EVENTS';
export const DIGITAL_SIGNUP_EVENTS_REQUEST_SUCCESS =
  'DIGITAL_SIGNUP_EVENTS_REQUEST_SUCCESS';
export const DIGITAL_SIGNUP_EVENTS_REQUEST_ERROR =
  'DIGITAL_SIGNUP_EVENTS_REQUEST_ERROR';
