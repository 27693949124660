import { certificates as coreCertificates } from 'config/certificates';
import { getCertificatesHelpers } from 'helpers/applications';
import { getFileLink } from 'helpers/resources';

export const certificates = {
  ...coreCertificates,
  nwNexgenAdultCert: {
    link: getFileLink('file-cert-nexgen-adult-nw-cert'),
    label: 'NW NexGen Adult Certificate'
  },
  akNexgenAdultCert: {
    link: getFileLink('file-cert-nexgen-adult-ak-cert'),
    label: 'AK NexGen Adult Certificate'
  },
  ksNexgenAdultAboRider: {
    link: getFileLink('file-cert-nexgen-adult-ks-abo-rider'),
    label: 'KS NexGen Adult ABO Rider'
  },
  laNexgenAdultCert: {
    link: getFileLink('file-cert-nexgen-adult-la-cert'),
    label: 'LA NexGen Adult Certificate'
  },
  mdNexgenAdultCert: {
    link: getFileLink('file-cert-nexgen-adult-md-cert'),
    label: 'MD NexGen Adult Certificate'
  },
  mtNexgenAdultCert: {
    link: getFileLink('file-cert-nexgen-adult-mt-cert'),
    label: 'MT NexGen Adult Certificate'
  },
  ncNexgenAdultCert: {
    link: getFileLink('file-cert-nexgen-adult-nc-cert'),
    label: 'NC NexGen Adult Certificate'
  },
  nhNexgenAdultCert: {
    link: getFileLink('file-cert-nexgen-adult-nh-cert'),
    label: 'NH NexGen Adult Certificate'
  },
  nyNexgenAdultCert: {
    link: getFileLink('file-cert-nexgen-adult-ny-cert'),
    label: 'NY NexGen Adult Certificate'
  },
  txNexgenAdultCert: {
    link: getFileLink('file-cert-nexgen-adult-tx-cert'),
    label: 'TX NexGen Adult Certificate'
  },
  utNexgenAdultCert: {
    link: getFileLink('file-cert-nexgen-adult-ut-cert'),
    label: 'UT NexGen Adult Certificate'
  },
  waNexgenAdultCert: {
    link: getFileLink('file-cert-nexgen-adult-wa-cert'),
    label: 'WA NexGen Adult Certificate'
  },
  nexgenAdultRates: {
    link: getFileLink('file-cert-nexgen-adult-rates'),
    label: 'NexGen Adult Rates'
  }
};

export const nationalWideConfig = [
  'nwNexgenAdultCert',
  'nwChildRider',
  'nexgenAdultRates',
  'howWePay1Rider'
];

export const stateSpecificConfig = [
  // Alaska
  {
    states: ['AK'],
    certs: [
      'akNexgenAdultCert',
      'akChildRider',
      'nexgenAdultRates',
      'howWePay1Rider'
    ]
  },
  // California
  {
    states: ['CA'],
    certs: [
      'nwNexgenAdultCert',
      'nwChildRider',
      'nexgenAdultRates',
      'howWePay1Rider',
      'caProofRider'
    ]
  },
  // Idaho
  {
    states: ['ID'],
    certs: [
      'nwNexgenAdultCert',
      'idChildRider',
      'nexgenAdultRates',
      'howWePay1Rider'
    ]
  },
  // Kansas
  {
    states: ['KS'],
    certs: [
      'nwNexgenAdultCert',
      'nwChildRider',
      'nexgenAdultRates',
      'howWePay1Rider',
      'ksNexgenAdultAboRider'
    ]
  },
  // Luisiana
  {
    states: ['LA'],
    certs: [
      'laNexgenAdultCert',
      'nwChildRider',
      'nexgenAdultRates',
      'howWePay1Rider'
    ]
  },
  // Maryland
  {
    states: ['MD'],
    certs: [
      'mdNexgenAdultCert',
      'nwChildRider',
      'nexgenAdultRates',
      'howWePay2Rider'
    ]
  },
  // Montana
  {
    states: ['MT'],
    certs: [
      'mtNexgenAdultCert',
      'mtChildRider',
      'nexgenAdultRates',
      'howWePay1Rider'
    ]
  },
  // North Carolina
  {
    states: ['NC'],
    certs: [
      'ncNexgenAdultCert',
      'nwChildRider',
      'nexgenAdultRates',
      'howWePay1Rider'
    ]
  },
  // New Hampshire
  {
    states: ['NH'],
    certs: [
      'nhNexgenAdultCert',
      'nwChildRider',
      'nexgenAdultRates',
      'howWePay1Rider'
    ]
  },
  // New Mexico
  {
    states: ['NM'],
    certs: [
      'nwNexgenAdultCert',
      'nmChildRider',
      'nexgenAdultRates',
      'howWePay1Rider'
    ]
  },
  // New York
  {
    states: ['NY'],
    certs: [
      'nyNexgenAdultCert',
      'nwChildRider',
      'nexgenAdultRates',
      'howWePay1Rider'
    ]
  },
  // Texas
  {
    states: ['TX'],
    certs: [
      'txNexgenAdultCert',
      'txChildRider',
      'nexgenAdultRates',
      'howWePay1Rider'
    ]
  },
  // Utah
  {
    states: ['UT'],
    certs: [
      'utNexgenAdultCert',
      'nwChildRider',
      'nexgenAdultRates',
      'howWePay1Rider'
    ]
  },
  // Washington
  {
    states: ['WA'],
    certs: [
      'waNexgenAdultCert',
      'waChildRider',
      'nexgenAdultRates',
      'howWePay2Rider'
    ]
  },
  {
    states: ['AR', 'CT', 'DE', 'FL', 'MI', 'NJ', 'OH', 'RI'],
    certs: [
      'nwNexgenAdultCert',
      'nwChildRider',
      'nexgenAdultRates',
      'howWePay2Rider'
    ]
  }
];

export function getCertificates(state) {
  const stateConfig = getCertificatesHelpers.getStateSpecificConfig({
    state,
    stateSpecificConfig
  });

  return {
    nw: getCertificatesHelpers.getCertificates({
      certsConfig: nationalWideConfig,
      certificates
    }),
    certs: getCertificatesHelpers.getCertificates({
      certsConfig: stateConfig,
      certificates
    })
  };
}
