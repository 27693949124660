const paymentTypes = [
  { code: 'same' },
  { code: 'eft' },
  { code: 'cc' },
  { code: 'check' },
  { code: 'maa' },
  { code: 'ngallotment' },
  { code: 'ngdirect' },
  { code: 'ngbill' }
];

export default paymentTypes;
