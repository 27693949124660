import { UTM_COOKIES } from 'config/core';
import { getDate } from 'helpers/dates';
import { utmValue as utmValueSchema } from 'helpers/yup/yup-schemas';
import _isArray from 'lodash/isArray';
import _isString from 'lodash/isString';
import qs from 'query-string';

import * as Cookies from './cookies';

const names = UTM_COOKIES.names;

export function _getCookieValue(rawValue) {
  let value = '';

  if (_isString(rawValue)) {
    value = rawValue;
  } else if (_isArray(rawValue)) {
    value = rawValue.length ? rawValue[rawValue.length - 1] : '';
  }

  return utmValueSchema().isValidSync(value) ? value : '';
}

export function _getAll(obj) {
  const result = {};
  for (const key in obj) {
    if (obj.hasOwnProperty(key)) {
      result[key.replace('utm_', '')] = _getCookieValue(obj[key]);
    }
  }
  return result;
}

export function getAll() {
  return _getAll(Cookies.getAll(names));
}

export function setAll(locationSearch) {
  if (!locationSearch) return;
  const query = qs.parse(locationSearch);
  Cookies.setAll(names, query, { expires: getDate(UTM_COOKIES.expires) });
}

export function removeAll() {
  Cookies.removeAll(names);
}
