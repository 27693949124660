const errorStrings = {
  requiredField: 'This field is required',
  invalidField: 'This field is invalid',
  stringField: 'This field must be a string',
  integerField: 'This field must be a number',
  emailField: 'This field must be a valid email',
  dateField: 'This field must be a valid date',
  ssnField: 'Must be a valid SSN',
  ssnNotValid: 'SSN is not valid',
  zipPattern: 'Must match this ##### or this #####-#### pattern',
  phonePattern: 'Must match this pattern ###-###-####',
  plainPhonePattern: 'Must match this pattern ##########',
  confirmPasswordField: 'Passwords must match',
  strictStringField: 'Contains invalid character',
  creditCardNumberField: 'This field must be a valid credit card number',
  creditCardExpirationDatePattern: 'Must match this pattern mm/yyyy',
  creditCardExpirationDateField: 'This field must be a valid expiration date',
  onlyNumbers: 'Only numbers allowed',
  sharePercentSummary: 'Summary Share % for beneficiaries must be 100',
  excludedState:
    'Sorry, but we currently do not provide this service in your state',
  id: 'This field must only contain alpha-numeric and underscore characters',
  length: length => `This field must be exactly ${length} characters`,
  maxLength: max => `The maximum length of this field ${max} characters`,
  minLength: min => `The minimum length of this field ${min} characters`,
  maxNumber: max => `This value can't be greater then ${max}`,
  minNumber: min => `This value can't be less than ${min}`,
  moreThanNumber: more => `This value must be greater then ${more}`,
  lessThanNumber: less => `This value must be less than ${less}`,
  minArray: min =>
    `This field must have at least ${min} item${min > 1 ? 's' : ''}`,
  maxArray: max =>
    `This field mast have less than or equal to ${max} item${
      max > 1 ? 's' : ''
    }`,
  oneOf: 'This value is not valid for this field',
  ageField: "Sorry, your age doesn't match the plan requirements",
  apiError:
    'MBA system is currently unavailable.  Please try again in a few minutes or call MBA during normal business hours.',
  validationError: 'Check form fields and try again',
  nothingFound: 'Sorry, nothing found',
  unhandled: 'Something went wrong. Please try again.'
};

export default errorStrings;
