import 'bootstrap/dist/css/bootstrap.css';
import 'assets/styles/reset.css';
import 'assets/styles/icon-set.css';

import faviconImg from 'assets/images/favicon.ico';
import CheckEnvContainer from 'components/containers/check-env-container/CheckEnvContainer';
import SeoContainer from 'components/containers/seo-container/SeoContainer';
import UserContainer from 'components/containers/user-container/UserContainer';
import UtmContainer from 'components/containers/utm-container/UtmContainer';
import AppCoreLayout from 'components/layouts/app-core-layout/AppCoreLayout';
import LazyRouteLoading from 'components/partials/loadings/components/lazy-route-loading/LazyRouteLoading';
import { pathFormValid } from 'helpers/helpers';
import { trackPage } from 'helpers/tracking';
import _get from 'lodash/get';
import PropTypes from 'prop-types';
import React, { Suspense, lazy, useEffect } from 'react';
import { Helmet } from 'react-helmet';
import { connect } from 'react-redux';
import { Route } from 'react-router-dom';

const HomePage = lazy(() => import('components/pages/home-page/HomePage'));

const RoutesList = lazy(() => import('./RoutesList'));

const propTypes = {
  // store
  location: PropTypes.object.isRequired
};

const App = ({ location }) => {
  useEffect(() => {
    if (pathFormValid(location.pathname)) {
      trackPage(location);
    }
  }, [location]);

  return (
    <>
      <Helmet>
        <link rel="shortcut icon" href={faviconImg} />
      </Helmet>
      <CheckEnvContainer>
        <UserContainer>
          <UtmContainer>
            <SeoContainer>
              <AppCoreLayout pathname={location.pathname}>
                <Suspense fallback={<LazyRouteLoading />}>
                  {location.pathname === '/' ? (
                    /* render home page only */
                    <Route
                      key="home-page"
                      path="/"
                      render={routeProps => <HomePage {...routeProps} />}
                    />
                  ) : (
                    <RoutesList location={location} />
                  )}
                </Suspense>
              </AppCoreLayout>
            </SeoContainer>
          </UtmContainer>
        </UserContainer>
      </CheckEnvContainer>
    </>
  );
};

App.propTypes = propTypes;

const mapStateToProps = state => ({
  location: _get(state, 'router.location')
});

export default connect(mapStateToProps)(App);
