import { consoleMessage } from 'helpers/helpers';
import request from 'helpers/request';
import { call, put, takeEvery } from 'redux-saga/effects';

import * as Actions from './actions';

const makeRequest = function* ({
  validationSchema,
  filterCallback,
  requestCallback,
  successType,
  errorType,
  payload
}) {
  try {
    let resp;
    // make external request
    yield put(Actions.requestExternal());
    resp = yield call(request, payload);
    // validate response
    if (validationSchema) {
      const isValid = yield call([validationSchema, 'isValid'], resp);
      if (!isValid) {
        yield put(Actions.validationFailed());
        throw new Error('Returned data not valid');
      }
    }
    // filter response
    if (typeof filterCallback === 'function') {
      resp = yield call(filterCallback, resp);
    }
    // return data to store
    yield put({ type: successType, payload: resp });
    if (typeof requestCallback === 'function') {
      requestCallback(null, resp);
    }
  } catch (error) {
    consoleMessage('Request Error', error);
    yield put({ type: errorType, payload: error });
    if (typeof requestCallback === 'function') {
      requestCallback(error);
    }
  }
};

const sagas = [
  takeEvery(({ type }) => type.match(/^REQUEST_FOR_/), makeRequest)
];

export default sagas;
