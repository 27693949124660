import classnames from 'classnames';
import LazyLoadComponent from 'components/partials/lazy-load/components/lazy-load-component/LazyLoadComponent';
import ScrollToTop from 'components/partials/scroll-to-top/ScrollToTop';
import {
  EXCLUDE_APP_CORE_LAYOUT_REGEXP,
  EXCLUDE_SCROLL_TO_TOP_REGEXP,
  PURL_REGEXP
} from 'config/core';
import _get from 'lodash/get';
import PropTypes from 'prop-types';
import React, { Suspense, lazy, useEffect, useState } from 'react';
import { connect } from 'react-redux';

const TopBarAlert = lazy(() =>
  import('components/partials/alerts/components/top-bar-alert/TopBarAlert')
);
// const BallotAlert = lazy(() =>
//   import('components/partials/ballot/ballot-alert/BallotAlert')
// );

const PetAlert = lazy(() =>
  import('components/partials/alerts/pet-alert/PetAlert')
);

const Header = lazy(() => import('components/partials/header/Header'));

const Footer = lazy(() => import('components/partials/footer/Footer'));

const AuthModal = lazy(() =>
  import('components/partials/auth-modal/components/auth-modal/AuthModal')
);
const SignupModal = lazy(() =>
  import('components/partials/signup-modal/components/signup-modal/SignupModal')
);

const propTypes = {
  pathname: PropTypes.string.isRequired,
  children: PropTypes.node,
  // store
  userIsLoggedIn: PropTypes.bool.isRequired,
  signUpModalIsOpened: PropTypes.bool.isRequired,
  authModalIsOpened: PropTypes.bool.isRequired,
  isMobile: PropTypes.bool.isRequired,
  isWebp: PropTypes.bool.isRequired,
  is404: PropTypes.bool.isRequired
};

// load some components lazy on mobile devices
const wrapLazy = (isMobile, component) =>
  isMobile ? <LazyLoadComponent>{component}</LazyLoadComponent> : component;

const AppCoreLayout = ({
  pathname,
  children,
  userIsLoggedIn,
  signUpModalIsOpened,
  authModalIsOpened,
  isMobile,
  isWebp,
  is404
}) => {
  const [layoutVisible, setLayoutVisible] = useState(true);

  const [scrollToTop, setScrollToTop] = useState(true);

  useEffect(() => {
    const nextLayoutVisible =
      is404 ||
      (!PURL_REGEXP.test(pathname) &&
        !EXCLUDE_APP_CORE_LAYOUT_REGEXP.test(pathname));

    setLayoutVisible(nextLayoutVisible);

    const nextScrollToTop = !EXCLUDE_SCROLL_TO_TOP_REGEXP.test(pathname);

    setScrollToTop(nextScrollToTop);
  }, [pathname, is404]);

  return (
    <div id="app-root" className={classnames({ 'no-webp': !isWebp })}>
      {scrollToTop && <ScrollToTop />}
      <Suspense fallback={null}>
        {layoutVisible && (
          <>
            <TopBarAlert />
            {/* <BallotAlert /> */}
            <PetAlert />
            <Header />
          </>
        )}
      </Suspense>
      {children}
      <Suspense fallback={null}>
        {layoutVisible && wrapLazy(isMobile, <Footer />)}
      </Suspense>
      <Suspense fallback={null}>
        {!!(layoutVisible && !userIsLoggedIn && authModalIsOpened) && (
          <AuthModal />
        )}
        {!!(layoutVisible && !userIsLoggedIn && signUpModalIsOpened) && (
          <SignupModal />
        )}
      </Suspense>
    </div>
  );
};

AppCoreLayout.propTypes = propTypes;

const mapStateToProps = state => ({
  userIsLoggedIn: _get(state, 'user.isLoggedIn'),
  authModalIsOpened: _get(state, 'authModal.isOpened'),
  signUpModalIsOpened: _get(state, 'signupModal.isOpened'),
  isMobile: _get(state, 'main.isMobile'),
  isWebp: _get(state, 'main.isWebp'),
  is404: _get(state, 'main.is404')
});

export default connect(mapStateToProps)(AppCoreLayout);
