import { USER_COOKIES } from 'config/core';
import { getDate } from 'helpers/dates';

import * as Cookies from './cookies';

const names = USER_COOKIES.names;

export const getAll = () => Cookies.getAll(names);

export const setAll = data => {
  Cookies.setAll(names, data, {
    expires: getDate(USER_COOKIES.expires)
  });
};

export const removeAll = () => Cookies.removeAll(names);
