import ErrorStrings from 'config/error-strings';
import _cloneDeep from 'lodash/cloneDeep';

import * as Types from './types';

const initialState = {
  loading: false,
  redirectUrl: null,
  purlId: null,
  errorMessage: ''
};

const reducer = (state = initialState, action = {}) => {
  const { type, payload } = action;
  let newState;

  switch (type) {
    case Types.REQUEST_FOR_PURL:
      newState = _cloneDeep(state);
      newState.loading = true;
      newState.errorMessage = '';
      return newState;

    case Types.GET_PURL_SUCCESS:
      newState = _cloneDeep(state);
      const { redirectUrl, purlId } = payload;
      newState.redirectUrl = redirectUrl;
      newState.purlId = purlId;
      newState.loading = false;
      newState.errorMessage = '';
      return newState;

    case Types.GET_PURL_ERROR:
      newState = _cloneDeep(state);
      const requestError = payload;
      newState.loading = false;
      if (requestError.status >= 400 && requestError.status < 500) {
        newState.errorMessage = '';
        newState.redirectUrl = '';
        newState.purlId = '';
      } else {
        newState.errorMessage = ErrorStrings.apiError;
      }
      return newState;

    default:
      return state;
  }
};

const reducerConfig = { initialState, reducer };

export default reducerConfig;
