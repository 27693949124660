import adAndD from './ad-and-d';
import decreasingGroup from './decreasing-group';
import hospital from './hospital';
import levelTerm from './level-term';
import levelTermL1L2 from './level-term-l1-l2';
import metlawLegal from './metlaw-legal';
import nationalGuard from './national-guard';
import nextgen from './nextgen';
import term90 from './term-90';
import term90Plus from './term-90-plus';
import tricare from './tricare';

const appConfigs = {
  'ad-and-d': adAndD,
  'decreasing-group': decreasingGroup,
  hospital,
  'level-term': levelTerm,
  'level-term-l1-l2': levelTermL1L2,
  'metlaw-legal': metlawLegal,
  nextgen,
  'national-guard': nationalGuard,
  tricare,
  'term-90': term90,
  'term-90-plus': term90Plus
};

export default appConfigs;
