// https://github.com/facebook/create-react-app/issues/9906
/** @jsxRuntime classic */

/* import-sort-ignore */

// do not import anything before polyfills
import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';
import 'helpers/polyfills/element-closest-polyfill';

import React from 'react';
import ReactDOM from 'react-dom';

import { ConnectedRouter } from 'connected-react-router';
import { Provider } from 'react-redux';
import { PersistGate } from 'redux-persist/integration/react';

import App from './App';
import PageLogoLoading from 'components/partials/loadings/components/page-logo-loading/PageLogoLoading';
import { consoleMessage } from 'helpers/helpers';
import history from 'helpers/history';
import configureStore from 'store/store';

consoleMessage('process.env', process.env);

const initialState = {};
const { store, persistor } = configureStore(initialState, history);

ReactDOM.render(
  <Provider store={store}>
    <PersistGate loading={<PageLogoLoading />} persistor={persistor}>
      <ConnectedRouter history={history}>
        <App history={history} />
      </ConnectedRouter>
    </PersistGate>
  </Provider>,
  document.getElementById('root')
);
