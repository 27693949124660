const memberStatuses = [
  {
    id: 'N',
    code: 'N',
    name: 'New Member'
  },
  {
    id: 'N1',
    code: 'N',
    name: 'New Sponsored Spouse'
  },
  {
    id: 'N2',
    code: 'N',
    name: 'New Sponsored Spouse Member'
  },
  {
    id: 'N3',
    code: 'N',
    name: 'Insured Member'
  },
  {
    id: 'N-',
    code: 'N-',
    name: 'Member Non Insured'
  },
  {
    id: 'N-1',
    code: 'N-',
    name: 'Current Sponsored Spouse Non Insured'
  },
  {
    id: 'N-2',
    code: 'N-',
    name: 'Non-Insured Member'
  },
  {
    id: 'A',
    code: 'A',
    name: 'Request Additional Insurance',
    isChange: true
  },
  {
    id: 'A1',
    code: 'A',
    name: 'Current Member Requesting Additional Insurance',
    isChange: true
  },
  {
    id: 'A2',
    code: 'A',
    name: 'Current Member Request Additional Insurance',
    isChange: true
  },
  {
    id: 'A3',
    code: 'A',
    name: 'Current Sponsored Spouse Requesting Additional Insurance',
    isChange: true
  },
  {
    id: 'A4',
    code: 'A',
    name: 'Request Additional Coverage',
    isChange: true
  },
  {
    id: 'C',
    code: 'C',
    name: 'Request Change in Coverage',
    isChange: true
  },
  {
    id: 'C1',
    code: 'C',
    name: 'Current Sponsored Spouse Requesting Change of Coverage',
    isChange: true
  },
  {
    id: 'C2',
    code: 'C',
    name: 'Current Member Request Change in Coverage',
    isChange: true
  },

  {
    id: 'NS',
    code: 'NS',
    name: 'New Sponsored Spouse',
    isSpouse: true
  },
  {
    id: 'NS-',
    code: 'NS-',
    name: 'Current Sponsored Spouse Non Insured',
    isSpouse: true
  },

  {
    id: 'AS',
    code: 'AS',
    name: 'Current Sponsored Spouse Requesting Additional Insurance',
    isSpouse: true,
    isChange: true
  },

  {
    id: 'CS',
    code: 'CS',
    name: 'Current Sponsored Spouse Requesting Change of Coverage',
    isSpouse: true,
    isChange: true
  }
];

export default memberStatuses;
