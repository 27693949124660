const accountStatuses = [
  //Any other Value – Special Member
  { code: 'I', name: 'Inforce', inForce: true, ballot: true },
  { code: 'S', name: 'Grace Period', inForce: true, ballot: true },
  { code: 'B', name: 'Uninsured', ballot: true },
  {
    code: 'E',
    name:
      'Expired Insured Member  (what an uninsured member becomes after 2 years)'
  },
  { code: 'T', name: 'Terminated' },
  { code: 'N', name: 'Sponsoring Spouse', inForce: true },
  { code: 'U', name: 'Unidentified Allotment' },
  { code: 'P', name: 'Pending' }
];

export default accountStatuses;
