export const STORE_APPLICATION_DATA = 'STORE_APPLICATION_DATA';
export const STORE_APPLICATION_TEMP_DATA = 'STORE_APPLICATION_TEMP_DATA';
export const SET_APPLICATION_TEMP_DATA_FROM_SOURCE =
  'SET_APPLICATION_TEMP_DATA_FROM_SOURCE';

export const STORE_HOSPITAL_INTERMEDIATE_DATA =
  'STORE_HOSPITAL_INTERMEDIATE_DATA';
export const STORE_TRICARE_INTERMEDIATE_DATA =
  'STORE_TRICARE_INTERMEDIATE_DATA';

export const APPLICATION_STEP_REQUEST = 'APPLICATION_STEP_REQUEST';
export const APPLICATION_STEP_REQUEST_SUCCESS =
  'APPLICATION_STEP_REQUEST_SUCCESS';
export const APPLICATION_STEP_REQUEST_ERROR = 'APPLICATION_STEP_REQUEST_ERROR';
// finish application step
export const FINISH_APPLICATION_STEP = 'FINISH_APPLICATION_STEP';
// finish application
export const FINISH_APPLICATION = 'FINISH_APPLICATION';

export const SET_APPLICATION_LAST_PASSED_STEP =
  'SET_APPLICATION_LAST_PASSED_STEP';

export const SET_APPLICATION_LAST_MODIFIED_TIME =
  'SET_APPLICATION_LAST_MODIFIED_TIME';
