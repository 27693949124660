import ErrorStrings from 'config/error-strings';
import { mergeExistingKeys } from 'helpers/helpers';
import _cloneDeep from 'lodash/cloneDeep';

import * as Types from './types';

const initialState = {
  form: {
    dutyStatus: '',
    familyStatus: '',
    zipCode: '',
    monthBorn: '',
    yearBorn: '',
    tobaccoUse: '0',
    income: '',
    yearsAmount: '',
    childrenCount: '',
    outstandingMortgageAmount: '',
    outstandingDebtAmount: '',
    educationAmount: '',
    finalExpensesAmount: '',
    additionalAmount: ''
  },
  estimation: null,
  email: {
    loading: false,
    errorMessage: ''
  },
  pdf: {
    loading: false,
    data: null,
    errorMessage: ''
  }
};

const reducer = (state = initialState, action = {}) => {
  const { type, payload = {} } = action;
  let newState;

  switch (type) {
    case Types.SAVE_LIVE_CALCULATOR:
      newState = _cloneDeep(state);
      newState.form = mergeExistingKeys(newState.form, payload.formData);
      newState.estimation = payload.estimation;
      // clear pdf data on every form change
      newState.pdf.data = initialState.pdf.data;
      return newState;

    case Types.REQUEST_FOR_LIVE_CALCULATOR_PDF:
      newState = _cloneDeep(state);
      newState.pdf.loading = true;
      newState.pdf.errorMessage = '';
      return newState;

    case Types.LIVE_CALCULATOR_PDF_REQUEST_SUCCESS:
      newState = _cloneDeep(state);
      newState.pdf.loading = false;
      newState.pdf.data = payload;
      newState.pdf.errorMessage = '';
      return newState;

    case Types.LIVE_CALCULATOR_PDF_REQUEST_ERROR:
      newState = _cloneDeep(state);
      newState.pdf.loading = false;
      newState.pdf.errorMessage = ErrorStrings.apiError;
      return newState;

    case Types.REQUEST_FOR_LIVE_CALCULATOR_EMAIL:
      newState = _cloneDeep(state);
      newState.email.loading = true;
      newState.email.errorMessage = '';
      return newState;

    case Types.LIVE_CALCULATOR_EMAIL_REQUEST_SUCCESS:
      newState = _cloneDeep(state);
      newState.email.loading = false;
      newState.email.errorMessage = '';
      return newState;

    case Types.LIVE_CALCULATOR_EMAIL_REQUEST_ERROR:
      newState = _cloneDeep(state);
      newState.email.loading = false;
      newState.email.errorMessage = ErrorStrings.apiError;
      return newState;

    default:
      return state;
  }
};

const reducerConfig = { initialState, reducer, filter: ['form'] };

export default reducerConfig;
