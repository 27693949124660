import { consoleMessage } from 'helpers/helpers';
import request from 'helpers/request';
import _get from 'lodash/get';
import { call, put, takeEvery } from 'redux-saga/effects';

import * as Types from './types';

const makeRequest = function* ({
  requestCallback,
  successType,
  errorType,
  payload
}) {
  const { appId, formId, step } = _get(payload, 'data.data');
  try {
    // make external request
    const resp = yield call(request, payload);
    // return data to store
    yield put({
      type: successType,
      payload: { appId, formId, step, data: resp }
    });
    requestCallback(null, resp);
  } catch (error) {
    consoleMessage('Request Error', error);
    yield put({
      type: errorType,
      payload: { appId, formId, step, data: error }
    });
    requestCallback(error);
  }
};

const sagas = [takeEvery(Types.APPLICATION_STEP_REQUEST, makeRequest)];

export default sagas;
