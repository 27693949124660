import { setAll as setUtmCookies } from 'helpers/cookies/utm-cookies';
import PropTypes from 'prop-types';
import { useLocation } from 'react-router-dom';

const propTypes = {
  children: PropTypes.node.isRequired
};

const UtmContainer = ({ children }) => {
  // set utm cookies
  const { search: locationSearch } = useLocation();
  setUtmCookies(locationSearch);
  return children;
};

UtmContainer.propTypes = propTypes;

export default UtmContainer;
