import './LazyRouteLoading.scss';

import PageLoading from 'components/partials/loadings/components/page-loading/PageLoading';
import React from 'react';

const LazyRouteLoading = () => {
  return <PageLoading className="lazy-route-loading" />;
};

export default LazyRouteLoading;
