import ErrorStrings from 'config/error-strings';
import _cloneDeep from 'lodash/cloneDeep';

import * as Types from './types';

const initialState = {
  loading: false,
  data: {},
  relatedData: {},
  errorMessage: ''
};

const reducer = (state = initialState, action = {}) => {
  const { type, payload = {} } = action;
  let newState;

  switch (type) {
    case Types.REQUEST_FOR_GET_EDUCATED_ITEM:
      newState = _cloneDeep(state);
      newState.loading = true;
      newState.errorMessage = '';
      return newState;

    case Types.GET_GET_EDUCATED_ITEM_SUCCESS:
      newState = _cloneDeep(state);
      const { slug } = payload.data || {};
      if (slug) {
        newState.data[slug] = payload.data;
        newState.relatedData = {
          ...newState.relatedData,
          ...payload.relatedData
        };
        newState.errorMessage = '';
      } else {
        newState.errorMessage = ErrorStrings.nothingFound;
      }
      newState.loading = false;
      return newState;

    case Types.GET_GET_EDUCATED_ITEM_ERROR:
      newState = _cloneDeep(state);
      newState.loading = false;
      newState.errorMessage = ErrorStrings.apiError;
      return newState;

    default:
      return state;
  }
};

const reducerConfig = {
  initialState,
  reducer,
  filter: ['data', 'relatedData']
};

export default reducerConfig;
