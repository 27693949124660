import MbaLogoImg from 'assets/images/mba-logo.png';

export const SITE_NAME = 'Military Benefit Association';

export const SITE_DESCRIPTION =
  'Military members, protect your family with competitive rates on term life & health insurance, financial tools & benefits. Military Benefits Association.';

export const SITE_IMAGE = {
  path: MbaLogoImg,
  width: '400',
  height: '568'
};

export const CONTACTS = {
  leads: {
    email: 'leads@militarybenefit.org'
  },
  members: {
    phone: '800-336-0100',
    email: 'memberservices@militarybenefit.org',
    workingHours: [
      'Monday – Thursday:',
      '8am – 6pm ET',
      'Friday:',
      '8am – 4pm ET'
    ]
  },
  agents: {
    phone: '888-MBA-8511',
    phoneCode: '888-MBA-8511',
    email: 'mba_agents@militarybenefit.org',
    workingHours: [
      'Monday – Thursday:',
      '8am – 6pm ET',
      'Friday:',
      '8am – 4pm ET'
    ]
  },
  billing: {
    email: 'accounting@militarybenefit.org'
  },
  applications: {
    email: 'approcessing@militarybenefit.org'
  },
  lifeClaims: {
    email: 'lifeclaims@militarybenefit.org'
  },
  tricare: {
    phone: '1 855-688-1302',
    email: 'tricare@militarybenefit.org'
  },
  certificates: {
    email: 'certificates@militarybenefit.org'
  },
  mailAddress: [
    'Military Benefit Association',
    '14605 Avion Parkway',
    'PO Box 221110',
    'Chantilly VA 20153-1110'
  ]
};

export const SOCIAL = {
  twitter: 'http://twitter.com/MilitaryBenefit ',
  twitterId: '@MilitaryBenefit',
  facebook: 'https://www.facebook.com/MilitaryBenefit',
  instagram: 'https://www.instagram.com/MilitaryBenefit',
  linkedin: 'https://www.linkedin.com/company/militarybenefit',
  youtube: 'https://www.youtube.com/@militarybenefit'
};

export const BBB = {
  url:
    'https://www.bbb.org/us/va/chantilly/profile/insurance-consultant/military-benefit-association-0241-12976/#sealclick',
  logo:
    'https://seal-dc-easternpa.bbb.org/seals/blue-seal-200-42-whitetxt-bbb-12976.png'
};

export const APP_LINKS = {
  google:
    'https://play.google.com/store/apps/details?id=org.militarybenefit.app'
};

export const USER = {
  udpatePaymentMethodPandingPeriod: 24, // hours
  udpateBenePandingPeriod: 48 // hours
};

export const WEBAPI = {
  url: process.env.REACT_APP_WEB_API_URL,
  key: process.env.REACT_APP_WEB_API_KEY
};

export const SEO_STORE = {
  url: process.env.REACT_APP_S3_SEO_ORIGIN,
  key: 'sitemap.json'
};

export const GOOGLE_MAPS_API_KEY = process.env.REACT_APP_GOOGLE_MAPS_API_KEY;

export const DOCS_PDF = {
  dxpHost: 'https://dxp-dh-production.s3.amazonaws.com',
  mbaHost: 'https://docs.militarybenefit.org'
};

export const MAA = [
  'USA: K002111',
  'USN: N060025',
  'USAF: N060025',
  'USMC: 0065',
  'USCG: 065',
  'RETIRED: 181'
];

export const DYNAMIC_APPS = {
  host: process.env.REACT_APP_DYNAMIC_APPS_HOST
};

export const USER_COOKIES = {
  names: ['memberId', 'memberToken', 'memberName'],
  expires: { days: 30 }
};

export const UTM_COOKIES = {
  names: [
    'utm_source',
    'utm_medium',
    'utm_term',
    'utm_campaign',
    'utm_content',
    'utm_agent',
    'utm_agency'
  ],
  expires: { hours: 1 }
};

export const USER_MAP = {
  mbaId: 'mbaId',
  memberId: 'memberId',
  firstName: 'firstName',
  middleInitial: 'middleInitial',
  lastName: 'lastName',
  gender: 'gender',
  relationshipStatus: 'relationshipStatus',
  dateOfBirth: 'dateOfBirth',
  street: 'mailing.street',
  zip: 'mailing.zip',
  city: 'mailing.city',
  state: 'mailing.state',
  homePhone: 'contact.phone.home',
  workPhone: 'contact.phone.work',
  primaryEmail: 'contact.email.primary',
  secondaryEmail: 'contact.email.secondary',
  emailOptOut: 'contact.email.emailOptOut',
  dutyStatus: 'military.dutyStatus',
  retirementDate: 'military.retirementDate',
  branch: 'service.branch',
  rank: 'service.rank',
  unit: 'service.unit',
  ssn: 'ssn'
};

/*
  purl examples:
  /4721
  /FB4721
  /DM0304A
  /EMFED312
  /purl/AB12
*/
export const PURL_REGEXP_ROUTER =
  '(/[0-9]+|/[a-zA-Z]{2}[0-9]{4}[a-zA-Z]?|/[a-zA-Z]{5}[0-9]{3}?|/purl/[a-zA-Z0-9]+)';

export const PURL_REGEXP = /(^\/[0-9]+)|(^\/[a-zA-Z]{2}[0-9]{4}[a-zA-Z]?)|(^\/purl\/[a-zA-Z0-9]+|(^\/[a-zA-Z]{5}[0-9]{3}))/;

// do not show app core layouts for next pages
export const EXCLUDE_APP_CORE_LAYOUT_REGEXP = /(^\/events\/)|(^\/term90dynamicapp\/)|(^\/hospitaldynamicapp\/)|(^\/campaigns\/|(^\/agent\/))|(^\/shareapp\/)/;

// do not scroll to top on these pages
export const EXCLUDE_SCROLL_TO_TOP_REGEXP = /^\/certificates\//;

export const BRAND = {
  metlife: 'MetLife',
  hartford: 'Hartford',
  ng: 'NG'
};

export const SOURCE_IDS = {
  getEducated: '5fe3523776ec35005a87f341'
};

export const APP_TEMPLATE = {
  base: 'base',
  progressBar: 'progress-bar'
};

export const APP_IDLE_TIMEOUT = 1000 * 60 * 2; // 2 minutes
