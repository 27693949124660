import webApi from 'helpers/web-api';

import * as Filters from './filters';
import * as Schemas from './schemas';
import * as Types from './types';

export const loadUser = () => ({
  type: Types.LOAD_USER,
  successType: Types.SET_USER_AUTH
});

export const setUserAuth = data => ({
  type: Types.SET_USER_AUTH,
  payload: data
});

export const sendLoginRequest = (data, requestCallback) => ({
  type: Types.REQUEST_FOR_USER_LOGIN,
  validationSchema: Schemas.login,
  filterCallback: Filters.login,
  requestCallback,
  successType: Types.SET_USER_AUTH,
  errorType: Types.USER_LOGIN_ERROR,
  payload: webApi({
    api: 'aqua',
    action: 'login',
    data
  })
});

export const sendLoginSuperAdminRequest = (data, requestCallback) => ({
  type: Types.REQUEST_FOR_SUPER_ADMIN_LOGIN,
  validationSchema: Schemas.login,
  filterCallback: Filters.login,
  requestCallback,
  successType: Types.SET_USER_AUTH,
  errorType: Types.SUPER_ADMIN_LOGIN_ERROR,
  payload: webApi({
    api: 'aqua',
    action: 'loginSuperAdmin',
    data
  })
});

export const logoutUser = () => ({
  type: Types.LOGOUT_USER,
  successType: Types.LOGOUT_USER_SUCCESS
});

export const sendRegRequest = (data, requestCallback) => ({
  type: Types.REQUEST_FOR_USER_REG,
  validationSchema: Schemas.reg,
  filterCallback: Filters.reg,
  requestCallback,
  successType: Types.SET_USER_AUTH,
  errorType: Types.USER_REG_ERROR,
  payload: webApi({
    api: 'aqua',
    action: 'signup',
    data
  })
});

export const sendSetupUsernameRequest = (data, requestCallback) => ({
  type: Types.REQUEST_FOR_SETUP_USERNAME,
  requestCallback,
  successType: Types.SETUP_USERNAME_SUCCESS,
  errorType: Types.SETUP_USERNAME_ERROR,
  payload: webApi({
    api: 'aqua',
    action: 'setupUsername',
    data
  })
});

export const sendForgotPasswordRequest = (data, requestCallback) => ({
  type: Types.REQUEST_FOR_FORGOT_PASSWORD,
  requestCallback,
  successType: Types.FORGOT_PASSWORD_SUCCESS,
  errorType: Types.FORGOT_PASSWORD_ERROR,
  payload: webApi({
    api: 'aqua',
    action: 'forgotPassword',
    data
  })
});

export const sendFindYourUsernameRequest = (data, requestCallback) => ({
  type: Types.REQUEST_FOR_FIND_YOUR_USERNAME,
  requestCallback,
  successType: Types.FIND_YOUR_USERNAME_SUCCESS,
  errorType: Types.FIND_YOUR_USERNAME_ERROR,
  payload: webApi({
    api: 'aqua',
    action: 'findYourUsername',
    data
  })
});

export const sendResetPasswordRequest = (data, requestCallback) => ({
  type: Types.REQUEST_FOR_RESET_PASSWORD,
  requestCallback,
  successType: Types.RESET_PASSWORD_SUCCESS,
  errorType: Types.RESET_PASSWORD_ERROR,
  payload: webApi({
    api: 'aqua',
    action: 'resetPassword',
    data
  })
});

export const sendGetUserRequest = (memberId, memberToken) => ({
  type: Types.REQUEST_FOR_GET_USER,
  validationSchema: Schemas.userData,
  filterCallback: Filters.userData,
  successType: Types.GET_USER_SUCCESS,
  errorType: Types.GET_USER_ERROR,
  payload: webApi({
    api: 'aqua',
    action: 'getUser',
    data: {
      memberId,
      memberToken
    }
  })
});

export const sendUpdatePasswordRequest = (
  memberId,
  memberToken,
  data,
  requestCallback
) => ({
  type: Types.REQUEST_FOR_UPDATE_PASSWORD,
  requestCallback,
  successType: Types.UPDATE_PASSWORD_SUCCESS,
  errorType: Types.UPDATE_PASSWORD_ERROR,
  payload: webApi({
    api: 'aqua',
    action: 'updatePassword',
    data: {
      ...data,
      memberId,
      memberToken
    }
  })
});

export const sendUpdateContactInfoRequest = (
  memberId,
  memberToken,
  data,
  requestCallback
) => ({
  type: Types.REQUEST_FOR_UPDATE_CONTACT_INFO,
  validationSchema: Schemas.userData,
  filterCallback: Filters.userData,
  requestCallback,
  successType: Types.UPDATE_CONTACT_INFO_SUCCESS,
  errorType: Types.UPDATE_CONTACT_INFO_ERROR,
  payload: webApi({
    api: 'aqua',
    action: 'updateContactInfo',
    data: {
      ...data,
      memberId,
      memberToken
    }
  })
});

export const updateUserData = (path, value) => ({
  type: Types.UPDATE_USER_DATA,
  payload: {
    path,
    value
  }
});
