import { WEBAPI } from 'config/core';

const { url, key } = WEBAPI || {};

const setCacheHeader = headers => {
  delete headers['Cache-Control'];
};

const removeCacheHeader = headers => {
  // This header invalidates cache per requested key on api gateway
  // https://docs.aws.amazon.com/apigateway/latest/developerguide/api-gateway-caching.html#invalidate-method-caching
  headers['Cache-Control'] = 'max-age=0';
};

const webApi = ({ method = 'post', cache = true, api, action, data = {} }) => {
  const { location = {}, document = {} } = window || {};
  const _data = { ...data };
  const _headers = {};

  method = method.toLocaleLowerCase();

  // Not production site (staging, preview)
  if (process.env.REACT_APP_SERVER_ENV !== 'production') {
    // Show not promoted dxp data
    if (api === 'dxp') {
      _data.previewData = true;
    }
  }

  if (method === 'get') {
    // Don't cache request on preview site or if cache disabled in request parameters
    if (!cache || process.env.REACT_APP_SERVER_ENV === 'preview') {
      removeCacheHeader(_headers);
    }

    // Allow to test cached/uncached requests
    if (location.search) {
      if (/\bcache\b/i.test(location.search)) {
        // "cache" parameter signals API to cach current request
        setCacheHeader(_headers);

        console.log('Cached request sent');
      } else if (/\bnocache\b/i.test(location.search)) {
        // "nocache" parameter signals API to sho not cached request and invalidates it
        removeCacheHeader(_headers);

        console.log('Not cached request sent');
      }
    }
  }

  if (method !== 'get') {
    _headers['x-cookie'] = document.cookie;
  }

  const requestData = {};

  if (method === 'get') {
    requestData.params = {
      api,
      action,
      ..._data
    };
  } else {
    requestData.data = {
      api,
      action,
      data: _data
    };
  }

  return {
    url,
    timeoute: 30000, // 30 sec
    method,
    headers: {
      'x-api-key': key,
      'x-pathname': location.pathname,
      ..._headers
    },
    ...requestData
  };
};

export default webApi;
