import { useScrollToTop } from 'helpers/hooks';
import { useLocation } from 'react-router-dom';

const ScrollToTop = () => {
  const { pathname } = useLocation();
  useScrollToTop(pathname);
  return null;
};

export default ScrollToTop;
