export function scrollTo(selector) {
  // TODO: implement scroll to selector
  window.scrollTo(0, 0);
}

export function elementTopIsInViewport(element) {
  const rect = element.getBoundingClientRect();
  return rect.top >= 0;
}

// https://stackoverflow.com/a/27232658
export function checkSupportFormatWebp() {
  var elem = document.createElement('canvas');

  if (!!(elem.getContext && elem.getContext('2d'))) {
    // was able or not to get WebP representation
    return elem.toDataURL('image/webp').indexOf('data:image/webp') === 0;
  } else {
    // very old browser like IE 8, canvas not supported
    return false;
  }
}

export function scrollToSelector(selector, defaultToTop) {
  try {
    const targetEl = document.querySelector(selector);

    if (targetEl) {
      targetEl.scrollIntoView({ behavior: 'smooth' });

      return true;
    } else if (defaultToTop) {
      window.scrollTo(0, 0);
    }
  } catch (e) {
    console.warn(e);
  }
}
