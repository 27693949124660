import { routerMiddleware } from 'connected-react-router';
import { applyMiddleware, compose, createStore } from 'redux';
import { persistStore } from 'redux-persist';
import createSagaMiddleware from 'redux-saga';

import getRootReducer from './reducers';
import sagas from './sagas';

const sagaMiddleware = createSagaMiddleware();

const configureStore = (initialState = {}, history) => {
  const middleware = [sagaMiddleware, routerMiddleware(history)];

  const rootReducer = getRootReducer(history);

  let store;

  if (
    window.__REDUX_DEVTOOLS_EXTENSION__ &&
    process.env.REACT_APP_SERVER_ENV !== 'production'
  ) {
    store = createStore(
      rootReducer,
      initialState,
      compose(
        applyMiddleware(...middleware),
        window.__REDUX_DEVTOOLS_EXTENSION__ &&
          window.__REDUX_DEVTOOLS_EXTENSION__()
      )
    );
  } else {
    store = createStore(
      rootReducer,
      initialState,
      compose(applyMiddleware(...middleware))
    );
  }

  const persistor = persistStore(store);

  sagaMiddleware.run(sagas);

  return { store, persistor };
};

export default configureStore;
