import { DOCS_PDF } from 'config/core';
import _cloneDeep from 'lodash/cloneDeep';
import _filter from 'lodash/filter';
import _find from 'lodash/find';
import _get from 'lodash/get';
import _isArray from 'lodash/isArray';
import _isPlainObject from 'lodash/isPlainObject';
import _merge from 'lodash/merge';
import _mergeWith from 'lodash/mergeWith';
import _set from 'lodash/set';
import _unset from 'lodash/unset';
import qs from 'query-string';
import React from 'react';

export function addQueryVars(link, queryVars) {
  if (!link || !queryVars) return link;
  const [path, queryString] = link.split('?');
  const queryObj = _merge(qs.parse(queryString), queryVars);
  return `${path}?${qs.stringify(queryObj)}`;
}

export const combinePathQueries = (...queries) => {
  const total = Object.assign({}, ...queries);
  const res = [];
  for (const key in total) {
    if (total.hasOwnProperty(key)) {
      res.push(`${key}=${total[key]}`);
    }
  }
  return encodeURI(`?${res.join('&')}`);
};

export function chooseFunction(sourceFunc, defaultFunc) {
  return typeof sourceFunc === 'function' ? sourceFunc : defaultFunc;
}

export const formatCurrency = (number, minimumFractionDigits) => {
  return (Number(number) || 0).toLocaleString('en-US', {
    style: 'currency',
    currency: 'USD',
    minimumFractionDigits:
      minimumFractionDigits < 0
        ? 0
        : minimumFractionDigits > 2
        ? 2
        : !minimumFractionDigits
        ? 0
        : minimumFractionDigits,
    maximumFractionDigits: 2
  });
};

export const objectContains = (sourceObj, ObjToCompare) => {
  for (const key in sourceObj) {
    if (ObjToCompare[key] !== sourceObj[key]) return false;
  }
  return true;
};

export const consoleMessage = (title, data, type = 'log') => {
  if (process.env.REACT_APP_SERVER_ENV !== 'production') {
    const types = ['error', 'warn', 'log'];
    type = types.indexOf(type) > -1 ? type : 'log';
    console[type](title, data || '');
  }
};

export const pick = (collection = [], condition = {}, path = '', def = '') => {
  return _get(_find(collection, condition), path, def);
};

export const pickAll = (collection = [], condition = {}, path = '') => {
  return (_filter(collection, condition) || []).reduce((memo, current) => {
    const val = _get(current, path);
    if (val) {
      memo.push(val);
    }
    return memo;
  }, []);
};

export const cutString = (
  str = '',
  maxLength = 0,
  toCount = 'chars',
  suffix = '...'
) => {
  switch (toCount) {
    case 'words':
      const strArray = str.split(' ');
      return strArray.length > maxLength
        ? `${strArray.slice(0, maxLength).join(' ')}${suffix}`
        : str;
    default:
      return str.length > maxLength
        ? str.substring(0, maxLength - suffix.length) + suffix
        : str;
  }
};

export const filterDocsLink = link => {
  return link.replace(DOCS_PDF.dxpHost, DOCS_PDF.mbaHost);
};

export function forInKeysDeep(obj, callback) {
  let path = [];
  const _forInDeep = (obj, depth) => {
    for (const key in obj) {
      path = path.slice(0, depth);
      if (obj.hasOwnProperty(key)) {
        path.push(key);
        if (_isPlainObject(obj[key])) {
          _forInDeep(obj[key], depth + 1);
        } else {
          callback(path.join('.'));
        }
      }
    }
  };
  _forInDeep(obj, 0);
}

export function mergeExistingKeys(targetObj, sourceObj) {
  const resObj = _cloneDeep(targetObj);
  sourceObj = _cloneDeep(sourceObj);
  forInKeysDeep(targetObj, path => {
    const sourceValue = _get(sourceObj, path);
    if (sourceValue !== undefined) {
      _set(resObj, path, sourceValue);
    }
  });
  return resObj;
}

/*
  Example:

  const targetObj = {
  k: {
    l: 3
  },
  x: {
    y: undefined
  },
  y: {
    z: undefined
  }
};

// this object describes targetObj and contains all paths of targetObj
const targetMap = { kl: 'k.l', xy: 'x.y', yz: 'y.z' };

const sourceObj = {
  wrapper: {
    a: {
      b: 0
    },
    x: {
      y: 1
    },
    y: {
      z: 2
    }
  }
};

// this object uses keys from targetObj to connect paths from sourceObj
const sourceMap = {
  xy: 'wrapper.x.y', // "x.y" in targetObj
  yz: 'wrapper.y.z' // "y.z" in targetObj
};

const result = {
  k: {
    l: 3
  },
  x: {
    y: 1
  },
  y: {
    z: 2
  }
};

*/
export function mergeByMap(targetObj, targetMap, sourceObj, sourceMap) {
  const resObj = _cloneDeep(targetObj);
  sourceObj = _cloneDeep(sourceObj);
  for (const key in targetMap) {
    if (
      targetMap.hasOwnProperty(key) &&
      sourceMap.hasOwnProperty(key) &&
      targetMap[key] &&
      sourceMap[key]
    ) {
      const sourceValue = _get(sourceObj, sourceMap[key]);
      if (sourceValue !== undefined) {
        _set(resObj, targetMap[key], sourceValue);
      }
    }
  }
  return resObj;
}

export function formatString(str, pattern, delimiter) {
  if (str === undefined) return '';
  if (!pattern || !delimiter) return str;
  const delimiterRegExp = new RegExp(delimiter, 'g');
  const maxLength = pattern.replace(delimiterRegExp, '').length;
  const delimiterIndexes = getIndexes(pattern, delimiter);
  str = Array.isArray(str)
    ? str.join('')
    : String(str).replace(delimiterRegExp, '').substring(0, maxLength);
  delimiterIndexes.forEach(index => {
    if (str.length > index) {
      str = str.substring(0, index) + '-' + str.substring(index);
    }
  });
  return str;
}

export function getIndexes(str, substr) {
  if (!str || !substr) return [];
  const indexes = [];
  for (var i = 0; i < str.length; i++) {
    if (str[i] === substr) indexes.push(i);
  }
  return indexes;
}

export function isIdValid(id) {
  return !!(id && /^[A-Za-z]+[\w\-:.]*$/.test(id));
}

export function uniqueId(str, delimiter) {
  delimiter = typeof delimiter === 'string' ? delimiter : '-';
  return (
    (str && typeof str === 'string' && isIdValid(str) ? str + delimiter : '') +
    'a' +
    Math.random().toString(36).substr(2, 9).padEnd(9, '0')
  );
}

export function convertArrayToString(arr, separator) {
  return (arr || []).filter(item => item).join(separator);
}

export function processObject(obj, { merge, add, update, remove }) {
  if (merge && merge.length) {
    merge.forEach(item => _merge(obj, item));
  }
  if (add && add.length) {
    add.forEach(item => _set(obj, item.path, item.value));
  }
  if (update && update.length) {
    update.forEach(item => item.callback(obj, item.path));
  }
  if (remove && remove.length) {
    remove.forEach(path => _unset(obj, path));
  }
  return obj;
}

export function mergeAndConcatArrays(...sources) {
  return _mergeWith(...sources, (objValue, srcValue) => {
    if (_isArray(objValue)) {
      return objValue.concat(srcValue);
    }
  });
}

export function mergeAndReplaceArrays(...sources) {
  return _mergeWith(...sources, (_, srcValue) => {
    if (_isArray(srcValue)) {
      return srcValue;
    }
  });
}

/**
 * Convert to float properly.
 * Note: this function used in level term policy calculator
 * on wp site
 */
export function toFloat(val, isReturnRounded = true) {
  let amount = parseFloat(val || 0);
  amount = isNaN(amount) ? 0 : amount;
  if (isReturnRounded) {
    // round up to 2 digits after comma
    return Number(amount.toFixed(2));
  }
  return amount;
}

export function checkUniqParamsInCollection(collection, paramName) {
  if (!collection || !collection.length || !paramName) return true;
  const paramValues = [];
  for (let index = 0; index < collection.length; index++) {
    const entry = collection[index];
    if (entry && entry[paramName]) {
      if (paramValues.indexOf(entry[paramName]) !== -1) {
        return false;
      } else {
        paramValues.push(entry[paramName]);
      }
    }
  }
  return true;
}
export function wrap(W, c) {
  return W ? <W>{c}</W> : c;
}

export function pathFormValid(pathname) {
  // trailing slash exists
  return /\/$/.test(pathname);
}

export function createRankFullValue(branch, rank) {
  return `${branch}++${rank}`;
}
