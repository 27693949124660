import { BRAND } from 'config/core';
import { getFileLink, getPageLink } from 'helpers/resources';

import { getCertificates as getCertificatesAdult } from './getCertificatesAdult';
import { getCertificates as getCertificatesChild } from './getCertificatesChild';

const nextGenConfig = {
  common: {
    brand: BRAND.metlife,
    maxAge: 69,
    notAvailableForStates: ['AA', 'AE', 'AP', 'ME', 'MP', 'OR'],
    paymentMethods: ['eft', 'cc', 'check', 'maa']
  },
  'nextgen-adult': {
    type: 'FD', // ADULT FORMER DEPENDENT
    shortName: 'NexGen Adult Term Life',
    appLink: getPageLink('page-nextgen-adult-app'),
    pdf: getFileLink('file-nextgen-adult-brochure'),
    coverageAmount: {
      min: 50000,
      max: 250000,
      step: 50000
    },
    getCertificates: getCertificatesAdult
  },
  'nextgen-child': {
    type: 'NG', // NEXGEN DEPENDENT TERM
    shortName: 'NexGen Dependent Term Life Insurance',
    memberStatuses: ['N3', 'N-2', 'A4', 'C'],
    appLink: getPageLink('page-nextgen-child-app'),
    pdf: getFileLink('file-nextgen-child-brochure'),
    ssnConfirmationAge: 18,
    coverageAmount: {
      list: [10000, 20000, 25000]
    },
    getCertificates: getCertificatesChild
  }
};

export default nextGenConfig;
