import policies from 'config/dictionaries/policies';
import ErrorStrings from 'config/error-strings';
import { mergeAndReplaceArrays } from 'helpers/helpers';
import * as UserHelpers from 'helpers/user';
import _cloneDeep from 'lodash/cloneDeep';
import _get from 'lodash/get';
import _merge from 'lodash/merge';
import _set from 'lodash/set';

import * as Types from './types';

const initialState = {
  loading: {},
  data: null, // user personal data
  auth: null, // user auth data (token, id etc)
  errorMessage: {},
  isLoggedIn: false,
  dataLoaded: false
};

const reducer = (state = initialState, action = {}) => {
  const { type, payload = {} } = action;
  let newState;

  switch (type) {
    // set user auth
    case Types.SET_USER_AUTH:
      newState = _cloneDeep(state);
      newState.loading = {};
      newState.auth = payload;
      newState.isLoggedIn = !!(
        _get(newState, 'auth.memberToken') && _get(newState, 'auth.memberId')
      );
      return newState;

    // login user
    case Types.REQUEST_FOR_USER_LOGIN:
      newState = _cloneDeep(state);
      newState.loading.login = true;
      return newState;

    case Types.USER_LOGIN_ERROR:
      newState = _cloneDeep(state);
      newState.loading.login = false;
      return newState;

    // login super admin request
    case Types.REQUEST_FOR_SUPER_ADMIN_LOGIN:
      newState = _cloneDeep(state);
      newState.loading.loginSuperAdmin = true;
      return newState;

    case Types.SUPER_ADMIN_LOGIN_ERROR:
      newState = _cloneDeep(state);
      newState.loading.loginSuperAdmin = false;
      return newState;

    // logout user
    case Types.LOGOUT_USER_SUCCESS:
      newState = _cloneDeep(state);
      newState = {
        ...newState,
        loading: {},
        data: {},
        auth: {},
        errorMessage: {},
        isLoggedIn: false,
        dataLoaded: false
      };
      return newState;

    // registration request
    case Types.REQUEST_FOR_USER_REG:
      newState = _cloneDeep(state);
      newState.loading.reg = true;
      return newState;

    case Types.USER_REG_ERROR:
      newState = _cloneDeep(state);
      newState.loading.reg = false;
      return newState;

    // setup username request
    case Types.REQUEST_FOR_SETUP_USERNAME:
      newState = _cloneDeep(state);
      newState.loading.setupUsername = true;
      return newState;

    case Types.SETUP_USERNAME_SUCCESS:
    case Types.SETUP_USERNAME_ERROR:
      newState = _cloneDeep(state);
      newState.loading.setupUsername = false;
      return newState;

    // forgot password request
    case Types.REQUEST_FOR_FORGOT_PASSWORD:
      newState = _cloneDeep(state);
      newState.loading.forgotPassword = true;
      return newState;

    case Types.FORGOT_PASSWORD_SUCCESS:
    case Types.FORGOT_PASSWORD_ERROR:
      newState = _cloneDeep(state);
      newState.loading.forgotPassword = false;
      return newState;

    // find your username request
    case Types.REQUEST_FOR_FIND_YOUR_USERNAME:
      newState = _cloneDeep(state);
      newState.loading.findYourUsername = true;
      return newState;

    case Types.FIND_YOUR_USERNAME_SUCCESS:
    case Types.FIND_YOUR_USERNAME_ERROR:
      newState = _cloneDeep(state);
      newState.loading.findYourUsername = false;
      return newState;

    // reset password request
    case Types.REQUEST_FOR_RESET_PASSWORD:
      newState = _cloneDeep(state);
      newState.loading.resetPassword = true;
      return newState;

    case Types.RESET_PASSWORD_SUCCESS:
    case Types.RESET_PASSWORD_ERROR:
      newState = _cloneDeep(state);
      newState.loading.resetPassword = false;
      return newState;

    // get user request
    case Types.REQUEST_FOR_GET_USER:
      newState = _cloneDeep(state);
      newState.loading.getUser = true;
      newState.errorMessage.getUser = '';
      return newState;

    case Types.GET_USER_SUCCESS:
      newState = _cloneDeep(state);

      newState.loading.getUser = false;

      newState.data = mergeAndReplaceArrays(newState.data, payload);

      newState.data.ballotAllowed = UserHelpers.ballotAllowed(newState.data);

      newState.data.paymentAllowed = UserHelpers.paymentAllowed(newState.data);

      newState.data.paymentMethodUpdatePending = UserHelpers.paymentMethodUpdatePending(
        newState.data
      );

      newState.data.hasVoted = UserHelpers.userHasVoted(newState.data);

      newState.data.beneUpdatePending = UserHelpers.beneUpdatePending(
        newState.data
      );

      // TODO: use this object to handle all access restictions
      newState.permissions = {
        payment: newState.data.paymentAllowed,
        paymentMethodUpdate: newState.data.paymentMethodUpdatePending,
        beneUpdate: newState.data.beneUpdatePending,
        nationalGuardCert: UserHelpers.nationalGuardCertAllowed(newState.data),
        ballot: newState.data.ballotAllowed,
        vote: newState.data.hasVoted
      };

      if (newState.data.applications) {
        newState.data.applications = UserHelpers.normalizeApplicationsByType(
          newState.data.applications,
          policies
        );

        newState.data.submittedApplications = UserHelpers.getSubmittedApplications(
          newState.data.applications
        );

        newState.data.beneficiaries = UserHelpers.getBeneficiariesFromApplications(
          newState.data.applications,
          'approved'
        );
      }

      newState.dataLoaded = true;

      return newState;

    case Types.GET_USER_ERROR:
      newState = _cloneDeep(state);
      newState.loading.getUser = false;
      newState.errorMessage.getUser = ErrorStrings.apiError;
      newState.dataLoaded = false;
      return newState;

    // update password request
    case Types.REQUEST_FOR_UPDATE_PASSWORD:
      newState = _cloneDeep(state);
      newState.loading.updatePassword = true;
      return newState;

    case Types.UPDATE_PASSWORD_SUCCESS:
    case Types.UPDATE_PASSWORD_ERROR:
      newState = _cloneDeep(state);
      newState.loading.updatePassword = false;
      return newState;

    // update contact info request
    case Types.REQUEST_FOR_UPDATE_CONTACT_INFO:
      newState = _cloneDeep(state);
      newState.loading.updateContactInfo = true;
      return newState;

    case Types.UPDATE_CONTACT_INFO_SUCCESS:
      newState = _cloneDeep(state);
      newState.loading.updateContactInfo = false;
      newState.data = _merge(newState.data, payload);
      return newState;

    case Types.UPDATE_CONTACT_INFO_ERROR:
      newState = _cloneDeep(state);
      newState.loading.updateContactInfo = false;
      return newState;

    // update user data
    case Types.UPDATE_USER_DATA:
      newState = _cloneDeep(state);
      _set(newState.data, payload.path, payload.value);
      return newState;

    default:
      return state;
  }
};

const reducerConfig = { initialState, reducer };

export default reducerConfig;
