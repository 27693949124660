import { certificates as coreCertificates } from 'config/certificates';
import { getCertificatesHelpers } from 'helpers/applications';
import { getFileLink } from 'helpers/resources';

export const certificates = {
  ...coreCertificates,
  nwTerm90PlusCert: {
    link: getFileLink('file-cert-term-90-plus-nw-cert'),
    label: 'NW Term 90 Plus Certificate'
  },
  akTerm90PlusCert: {
    link: getFileLink('file-cert-term-90-plus-ak-cert'),
    label: 'AK Term 90 Plus Certificate'
  },
  laTerm90PlusCert: {
    link: getFileLink('file-cert-term-90-plus-la-cert'),
    label: 'LA Term 90 Plus Certificate'
  },
  mdTerm90PlusCert: {
    link: getFileLink('file-cert-term-90-plus-md-cert'),
    label: 'MD Term 90 Plus Certificate'
  },
  mtTerm90PlusCert: {
    link: getFileLink('file-cert-term-90-plus-mt-cert'),
    label: 'MT Term 90 Plus Certificate'
  },
  ncTerm90PlusCert: {
    link: getFileLink('file-cert-term-90-plus-nc-cert'),
    label: 'NC Term 90 Plus Certificate'
  },
  nhTerm90PlusCert: {
    link: getFileLink('file-cert-term-90-plus-nh-cert'),
    label: 'NH Term 90 Plus Certificate'
  },
  nyTerm90PlusCert: {
    link: getFileLink('file-cert-term-90-plus-ny-cert'),
    label: 'NY Term 90 Plus Certificate'
  },
  txTerm90PlusCert: {
    link: getFileLink('file-cert-term-90-plus-tx-cert'),
    label: 'TX Term 90 Plus Certificate'
  },
  utTerm90PlusCert: {
    link: getFileLink('file-cert-term-90-plus-ut-cert'),
    label: 'UT Term 90 Plus Certificate'
  },
  waTerm90PlusCert: {
    link: getFileLink('file-cert-term-90-plus-wa-cert'),
    label: 'WA Term 90 Plus Certificate'
  },
  ksTerm90PlusRider: {
    link: getFileLink('file-cert-term-90-plus-ks-term90-rider'),
    label: 'KS Term 90 Plus Rider'
  },
  term90PlusRatesTob: {
    link: getFileLink('file-cert-term-90-plus-rates-tob'),
    label: 'Term 90 Plus Rates (Tobacco)'
  },
  term90PlusRatesNoTob: {
    link: getFileLink('file-cert-term-90-plus-rates-no-tob'),
    label: 'Term 90 Plus Rates (No Tobacco)'
  }
};

export const nationalWideConfig = [
  'nwTerm90PlusCert',
  'nwChildRider',
  'nwVeteranRider',
  'howWePay1Rider',
  'term90PlusRatesTob',
  'term90PlusRatesNoTob'
];

export const stateSpecificConfig = [
  // Alaska
  {
    states: ['AK'],
    certs: [
      'akTerm90PlusCert',
      'akChildRider',
      'nwVeteranRider',
      'howWePay1Rider',
      'term90PlusRatesTob',
      'term90PlusRatesNoTob'
    ]
  },
  // California
  {
    states: ['CA'],
    certs: [
      'nwTerm90PlusCert',
      'caProofRider',
      'nwChildRider',
      'nwVeteranRider',
      'howWePay1Rider',
      'term90PlusRatesTob',
      'term90PlusRatesNoTob'
    ]
  },
  // Guam
  {
    states: ['GU'],
    certs: [
      'nwTerm90PlusCert',
      'guPowMiaRider',
      'nwChildRider',
      'nwVeteranRider',
      'howWePay1Rider',
      'term90PlusRatesTob',
      'term90PlusRatesNoTob'
    ]
  },
  // Idaho
  {
    states: ['ID'],
    certs: [
      'nwTerm90PlusCert',
      'idChildRider',
      'idVeteranRider',
      'howWePay1Rider',
      'term90PlusRatesTob',
      'term90PlusRatesNoTob'
    ]
  },
  // Kansas
  {
    states: ['KS'],
    certs: [
      'nwTerm90PlusCert',
      'ksTerm90PlusRider',
      'nwChildRider',
      'nwVeteranRider',
      'howWePay1Rider',
      'term90PlusRatesTob',
      'term90PlusRatesNoTob'
    ]
  },
  // Luisiana
  {
    states: ['LA'],
    certs: [
      'laTerm90PlusCert',
      'laChildRider',
      'laVeteranRider',
      'howWePay1Rider',
      'term90PlusRatesTob',
      'term90PlusRatesNoTob'
    ]
  },
  // Maryland
  {
    states: ['MD'],
    certs: [
      'mdTerm90PlusCert',
      'nwChildRider',
      'mdVeteranRider',
      'howWePay2Rider',
      'term90PlusRatesTob',
      'term90PlusRatesNoTob'
    ]
  },
  // Montana
  {
    states: ['MT'],
    certs: [
      'mtTerm90PlusCert',
      'mtChildRider',
      'mtVeteranRider',
      'term90PlusRatesTob',
      'term90PlusRatesNoTob'
    ]
  },
  // New Hampshire
  {
    states: ['NH'],
    certs: [
      'nhTerm90PlusCert',
      'nwChildRider',
      'nwVeteranRider',
      'howWePay1Rider',
      'term90PlusRatesTob',
      'term90PlusRatesNoTob'
    ]
  },
  // New Mexico
  {
    states: ['NM'],
    certs: [
      'nwTerm90PlusCert',
      'nmChildRider',
      'nwVeteranRider',
      'howWePay1Rider',
      'term90PlusRatesTob',
      'term90PlusRatesNoTob'
    ]
  },
  // New York
  {
    states: ['NY'],
    certs: [
      'nyTerm90PlusCert',
      'nyChildRider',
      'nyVeteranRider',
      'howWePay1Rider',
      'term90PlusRatesTob',
      'term90PlusRatesNoTob'
    ]
  },
  // North Carolina
  {
    states: ['NC'],
    certs: [
      'ncTerm90PlusCert',
      'nwChildRider',
      'nwVeteranRider',
      'howWePay1Rider',
      'term90PlusRatesTob',
      'term90PlusRatesNoTob'
    ]
  },
  // Puerto Rico
  {
    states: ['PR'],
    certs: [
      'nwTerm90PlusCert',
      'prPowMiaRider',
      'nwChildRider',
      'nwVeteranRider',
      'howWePay1Rider',
      'term90PlusRatesTob',
      'term90PlusRatesNoTob'
    ]
  },
  // Texas
  {
    states: ['TX'],
    certs: [
      'txTerm90PlusCert',
      'txChildRider',
      'txVeteranRider',
      'howWePay1Rider',
      'term90PlusRatesTob',
      'term90PlusRatesNoTob'
    ]
  },
  // Utah
  {
    states: ['UT'],
    certs: [
      'utTerm90PlusCert',
      'nwChildRider',
      'utVeteranRider',
      'howWePay1Rider',
      'term90PlusRatesTob',
      'term90PlusRatesNoTob'
    ]
  },
  // Washington
  {
    states: ['WA'],
    certs: [
      'waTerm90PlusCert',
      'waChildRider',
      'waVeteranRider',
      'howWePay2Rider',
      'term90PlusRatesTob',
      'term90PlusRatesNoTob'
    ]
  },
  {
    states: ['AR', 'CT', 'DE', 'FL', 'MI', 'NJ', 'OH', 'RI'],
    certs: [
      'nwTerm90PlusCert',
      'nwChildRider',
      'nwVeteranRider',
      'howWePay2Rider',
      'term90PlusRatesTob',
      'term90PlusRatesNoTob'
    ]
  }
];

export function getCertificates(state) {
  const stateConfig = getCertificatesHelpers.getStateSpecificConfig({
    state,
    stateSpecificConfig
  });

  return {
    nw: getCertificatesHelpers.getCertificates({
      certsConfig: nationalWideConfig,
      certificates
    }),
    certs: getCertificatesHelpers.getCertificates({
      certsConfig: stateConfig,
      certificates
    })
  };
}
