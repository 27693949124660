import { SEO_STORE } from 'config/core';

import filterCallback from './filterCallback';
import validationSchema from './schema';
import * as Types from './types';

export const getData = () => ({
  type: Types.REQUEST_FOR_SEO,
  filterCallback,
  validationSchema,
  successType: Types.GET_SEO_SUCCESS,
  errorType: Types.GET_SEO_ERROR,
  payload: {
    url: `/${SEO_STORE.key}`,
    baseURL: SEO_STORE.url,
    timeout: 3000,
    responseType: 'json',
    method: 'get'
  }
});
