import { certificates as coreCertificates } from 'config/certificates';
import { getCertificatesHelpers } from 'helpers/applications';
import { getFileLink } from 'helpers/resources';

import getTermByPolicyDetails from './helpers/getTermByPolicyDetails';

export const certConfig = {
  certificates: {
    ...coreCertificates,
    nw10LevelTermCert: {
      link: getFileLink('file-cert-level-term-10-nw-cert'),
      label: 'NW Level Term 10 Yr Certificate'
    },
    nw20LevelTermCert: {
      link: getFileLink('file-cert-level-term-20-nw-cert'),
      label: 'NW Level Term 20 Yr Certificate'
    },
    ak10LevelTermCert: {
      link: getFileLink('file-cert-level-term-10-ak-cert'),
      label: 'AK Level Term 10 Yr Certificate'
    },
    ak20LevelTermCert: {
      link: getFileLink('file-cert-level-term-20-ak-cert'),
      label: 'AK Level Term 20 Yr Certificate'
    },
    ks10LevelTermRider: {
      link: getFileLink('file-cert-level-term-10-ks-rider'),
      label: 'KS Level Term 10 Yr Rider'
    },
    ks20LevelTermRider: {
      link: getFileLink('file-cert-level-term-20-ks-rider'),
      label: 'KS Level Term 20 Yr Rider'
    },
    la10LevelTermCert: {
      link: getFileLink('file-cert-level-term-10-la-cert'),
      label: 'LA Level Term 10 Yr Certificate'
    },
    la20LevelTermCert: {
      link: getFileLink('file-cert-level-term-20-la-cert'),
      label: 'LA Level Term 20 Yr Certificate'
    },
    md10LevelTermCert: {
      link: getFileLink('file-cert-level-term-10-md-cert'),
      label: 'MD Level Term 10 Yr Certificate'
    },
    md20LevelTermCert: {
      link: getFileLink('file-cert-level-term-20-md-cert'),
      label: 'MD Level Term 20 Yr Certificate'
    },
    mt10LevelTermCert: {
      link: getFileLink('file-cert-level-term-10-mt-cert'),
      label: 'MT Level Term 10 Yr Certificate'
    },
    mt20LevelTermCert: {
      link: getFileLink('file-cert-level-term-20-mt-cert'),
      label: 'MT Level Term 20 Yr Certificate'
    },
    nh10LevelTermCert: {
      link: getFileLink('file-cert-level-term-10-nh-cert'),
      label: 'NH Level Term 10 Yr Certificate'
    },
    nh20LevelTermCert: {
      link: getFileLink('file-cert-level-term-20-nh-cert'),
      label: 'NH Level Term 20 Yr Certificate'
    },
    nm10LevelTermCert: {
      link: getFileLink('file-cert-level-term-10-nm-cert'),
      label: 'NM Level Term 10 Yr Certificate'
    },
    nm20LevelTermCert: {
      link: getFileLink('file-cert-level-term-20-nm-cert'),
      label: 'NM Level Term 20 Yr Certificate'
    },
    nc10LevelTermCert: {
      link: getFileLink('file-cert-level-term-10-nc-cert'),
      label: 'NC Level Term 10 Yr Certificate'
    },
    nc20LevelTermCert: {
      link: getFileLink('file-cert-level-term-20-nc-cert'),
      label: 'NC Level Term 20 Yr Certificate'
    },
    tx10LevelTermCert: {
      link: getFileLink('file-cert-level-term-10-tx-cert'),
      label: 'TX Level Term 10 Yr Certificate'
    },
    tx20LevelTermCert: {
      link: getFileLink('file-cert-level-term-20-tx-cert'),
      label: 'TX Level Term 20 Yr Certificate'
    },
    ut10LevelTermCert: {
      link: getFileLink('file-cert-level-term-10-ut-cert'),
      label: 'UT Level Term 10 Yr Certificate'
    },
    ut20LevelTermCert: {
      link: getFileLink('file-cert-level-term-20-ut-cert'),
      label: 'UT Level Term 20 Yr Certificate'
    },
    wa10LevelTermCert: {
      link: getFileLink('file-cert-level-term-10-wa-cert'),
      label: 'WA Level Term 10 Yr Certificate'
    },
    wa20LevelTermCert: {
      link: getFileLink('file-cert-level-term-20-wa-cert'),
      label: 'WA Level Term 20 Yr Certificate'
    }
  },
  term10: {
    nationalWideConfig: ['nw10LevelTermCert', 'nwChildRider', 'howWePay1Rider'],
    stateSpecificConfig: [
      // Alaska
      {
        states: ['AK'],
        certs: ['ak10LevelTermCert', 'akChildRider', 'howWePay1Rider']
      },
      // California
      {
        states: ['CA'],
        certs: [
          'nw10LevelTermCert',
          'nwChildRider',
          'howWePay1Rider',
          'caProofRider'
        ]
      },
      // Idaho
      {
        states: ['ID'],
        certs: ['nw10LevelTermCert', 'idChildRider', 'howWePay1Rider']
      },
      // Kansas
      {
        states: ['KS'],
        certs: [
          'nw10LevelTermCert',
          'ks10LevelTermRider',
          'nwChildRider',
          'howWePay1Rider'
        ]
      },
      // Louisiana
      {
        states: ['LA'],
        certs: ['la10LevelTermCert', 'nwChildRider', 'howWePay1Rider']
      },
      // Maryland
      {
        states: ['MD'],
        certs: ['md10LevelTermCert', 'nwChildRider', 'howWePay2Rider']
      },
      // Montana
      {
        states: ['MT'],
        certs: ['mt10LevelTermCert', 'mtChildRider', 'mtVeteranRider']
      },
      // New Hampshire
      {
        states: ['NH'],
        certs: ['nh10LevelTermCert', 'nwChildRider', 'howWePay1Rider']
      },
      // New Mexico
      {
        states: ['NM'],
        certs: ['nm10LevelTermCert', 'nmChildRider', 'howWePay1Rider']
      },
      // North Carolina
      {
        states: ['NC'],
        certs: ['nc10LevelTermCert', 'nwChildRider', 'howWePay1Rider']
      },
      // Texas
      {
        states: ['TX'],
        certs: ['tx10LevelTermCert', 'txChildRider', 'howWePay1Rider']
      },
      // Utah
      {
        states: ['UT'],
        certs: ['ut10LevelTermCert', 'nwChildRider', 'howWePay1Rider']
      },
      // Washington
      {
        states: ['WA'],
        certs: [
          'wa10LevelTermCert',
          'waChildRider',
          'howWePay2Rider',
          'waDisclosureRider'
        ]
      },
      {
        states: ['AR', 'CT', 'DE', 'FL', 'MI', 'NJ', 'OH', 'RI'],
        certs: ['nw10LevelTermCert', 'nwChildRider', 'howWePay2Rider']
      }
    ]
  },
  term20: {
    nationalWideConfig: ['nw20LevelTermCert', 'nwChildRider', 'howWePay1Rider'],
    stateSpecificConfig: [
      // Alaska
      {
        states: ['AK'],
        certs: ['ak20LevelTermCert', 'akChildRider', 'howWePay1Rider']
      },
      // California
      {
        states: ['CA'],
        certs: [
          'nw20LevelTermCert',
          'nwChildRider',
          'howWePay1Rider',
          'caProofRider'
        ]
      },
      // Idaho
      {
        states: ['ID'],
        certs: ['nw20LevelTermCert', 'idChildRider', 'howWePay1Rider']
      },
      // Kansas
      {
        states: ['KS'],
        certs: [
          'nw20LevelTermCert',
          'ks20LevelTermRider',
          'nwChildRider',
          'howWePay1Rider'
        ]
      },
      // Louisiana
      {
        states: ['LA'],
        certs: ['la20LevelTermCert', 'nwChildRider', 'howWePay1Rider']
      },
      // Maryland
      {
        states: ['MD'],
        certs: ['md20LevelTermCert', 'nwChildRider', 'howWePay2Rider']
      },
      // Montana
      {
        states: ['MT'],
        certs: ['mt20LevelTermCert', 'mtChildRider', 'mtVeteranRider']
      },
      // New Hampshire
      {
        states: ['NH'],
        certs: ['nh20LevelTermCert', 'nwChildRider', 'howWePay1Rider']
      },
      // New Mexico
      {
        states: ['NM'],
        certs: ['nm20LevelTermCert', 'nmChildRider', 'howWePay1Rider']
      },
      // North Carolina
      {
        states: ['NC'],
        certs: ['nc20LevelTermCert', 'nwChildRider', 'howWePay1Rider']
      },
      // Texas
      {
        states: ['TX'],
        certs: ['tx20LevelTermCert', 'txChildRider', 'howWePay1Rider']
      },
      // Utah
      {
        states: ['UT'],
        certs: ['ut20LevelTermCert', 'nwChildRider', 'howWePay1Rider']
      },
      // Washington
      {
        states: ['WA'],
        certs: [
          'wa20LevelTermCert',
          'waChildRider',
          'howWePay2Rider',
          'waDisclosureRider'
        ]
      },
      {
        states: ['AR', 'CT', 'DE', 'FL', 'MI', 'NJ', 'OH', 'RI'],
        certs: ['nw20LevelTermCert', 'nwChildRider', 'howWePay2Rider']
      }
    ]
  }
};

export function getCertificates(state, params = {}) {
  let { term = '', policySchedule, policyType } = params;

  if (!term && policySchedule && policyType) {
    term = getTermByPolicyDetails(policySchedule, policyType);
  }

  const { certificates } = certConfig;

  const { stateSpecificConfig, nationalWideConfig } =
    term === '20' ? certConfig.term20 : certConfig.term10;

  const stateConfig = getCertificatesHelpers.getStateSpecificConfig({
    state,
    stateSpecificConfig
  });

  return {
    nw: getCertificatesHelpers.getCertificates({
      certsConfig: nationalWideConfig,
      certificates
    }),
    certs: getCertificatesHelpers.getCertificates({
      certsConfig: stateConfig,
      certificates
    })
  };
}
