/*

  Assets in DXP:
  https://firebrandlab.com/admin/assets?tags[]=mba-web

*/

const assets = [];

export default assets;
