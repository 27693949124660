import rates from '../rates';

export default function getTermByPolicyDetails(policySchedule, policyType) {
  for (const planKey in rates) {
    const plan = rates[planKey];

    for (const genderKey in plan) {
      const gender = plan[genderKey];

      for (const termKey in gender) {
        const term = gender[termKey];

        for (const ageKey in term) {
          const policyConfigs = term[ageKey];

          const found = !!policyConfigs.find(row => {
            return policySchedule === row.schedule;
          });

          if (found) {
            return String(termKey);
          }
        }
      }
    }
  }
}
