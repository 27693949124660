import * as UserCookies from 'helpers/cookies/user-cookies';
import { call, put, takeLatest } from 'redux-saga/effects';

import * as Types from './types';

const loadUser = function* ({ successType }) {
  const auth = yield call([UserCookies, 'getAll']);
  yield put({
    type: successType,
    payload: auth
  });
};

const logoutUser = function* ({ successType }) {
  yield call([UserCookies, 'removeAll']);
  yield put({
    type: successType
  });
};

const setUser = function* ({ payload }) {
  yield call([UserCookies, 'setAll'], payload);
};

const sagas = [
  takeLatest(Types.LOAD_USER, loadUser),
  takeLatest(Types.LOGOUT_USER, logoutUser),
  takeLatest(Types.SET_USER_AUTH, setUser)
];

export default sagas;
