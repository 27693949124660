import _cloneDeep from 'lodash/cloneDeep';

import * as Types from './types';

const initialState = {
  isMobile: false,
  isWebp: true, // we assume user uses modern browser
  isGoogleMaps: false,
  is404: false,
  cookiesAccepted: false,
  petAlertVisible: true
};

const reducer = (state = initialState, action = {}) => {
  const { type, payload = {} } = action;
  let newState;

  switch (type) {
    case Types.SET_IS_MOBILE:
      newState = _cloneDeep(state);
      newState.isMobile = payload;
      return newState;

    case Types.SET_IS_WEBP:
      newState = _cloneDeep(state);
      newState.isWebp = payload;
      return newState;

    case Types.SET_IS_GOOGLE_MAPS:
      newState = _cloneDeep(state);
      newState.isGoogleMaps = payload;
      return newState;

    case Types.SET_IS_404:
      newState = _cloneDeep(state);
      newState.is404 = payload;
      return newState;

    case Types.SET_COOKIES_ACCEPTED:
      newState = _cloneDeep(state);
      newState.cookiesAccepted = true;
      return newState;

    case Types.PET_HIDE_ALERT:
      newState = _cloneDeep(state);
      newState.petAlertVisible = false;
      return newState;

    default:
      return state;
  }
};

const reducerConfig = {
  initialState,
  reducer,
  filter: ['cookiesAccepted', 'petAlertVisible']
};

export default reducerConfig;
