import _cloneDeep from 'lodash/cloneDeep';

import * as Types from './types';

const initialState = {
  isOpened: false
};

const reducer = (state = initialState, action = {}) => {
  const { type } = action;
  let newState;

  switch (type) {
    case Types.CLOSE_SIGNUP_MODAL:
      newState = _cloneDeep(state);
      newState.isOpened = false;
      return newState;

    case Types.OPEN_SIGNUP_MODAL:
      newState = _cloneDeep(state);
      newState.isOpened = true;
      return newState;

    default:
      return state;
  }
};

const reducerConfig = { initialState, reducer };

export default reducerConfig;
