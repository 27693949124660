import { mergeExistingKeys } from 'helpers/helpers';
import _cloneDeep from 'lodash/cloneDeep';

import * as Types from './types';

const initialState = {
  existingOrNew: '',
  resideIn: ''
};

const reducer = (state = initialState, action = {}) => {
  const { type, payload = {} } = action;
  let newState;

  switch (type) {
    case Types.SAVE_APPLY_INSURANCE_FORM:
      newState = _cloneDeep(state);
      newState = mergeExistingKeys(newState, payload);
      return newState;

    default:
      return state;
  }
};

const reducerConfig = { initialState, reducer };

export default reducerConfig;
