import assets from 'config/assets';
import files from 'config/files';
import routes from 'routes/routes';

import { pick } from './helpers';

export function getAssetLink(id) {
  const link = pick(assets, { id }, 'link');
  if (!link) {
    throw new Error(`Asset with id "${id}" not found`);
  }
  return link;
}

export function getFileLink(id) {
  const link = pick(files, { id }, 'link');
  if (!link) {
    throw new Error(`File with id "${id}" not found`);
  }
  return link;
}

export function getPageLink(id) {
  const link = pick(routes, { id }, 'path');
  if (!link) {
    throw new Error(`Page with id "${id}" not found`);
  }
  return link;
}
