import { checkSupportFormatWebp } from 'helpers/dom';
import PropTypes from 'prop-types';
import { useEffect } from 'react';
import { connect } from 'react-redux';
import { setIsMobile, setIsWebp } from 'store/parts/main/actions';

const propTypes = {
  children: PropTypes.node.isRequired,
  // store
  setIsMobile: PropTypes.func.isRequired,
  setIsWebp: PropTypes.func.isRequired
};

const CheckEnvContainer = ({ setIsMobile, setIsWebp, children }) => {
  // check if is mobile
  useEffect(() => {
    const handleScreenChange = () => {
      const MOBILE_SIZE = 900;

      const currentIsMobile = window.innerWidth < MOBILE_SIZE;

      setIsMobile(currentIsMobile);
    };

    handleScreenChange();

    window.addEventListener('resize', handleScreenChange);

    return () => {
      window.removeEventListener('resize', handleScreenChange);
    };
  }, [setIsMobile]);

  // check if webp is supported
  useEffect(() => {
    // we assume user uses modern browser
    if (!checkSupportFormatWebp()) {
      setIsWebp(false);
    }
  });

  return children;
};

CheckEnvContainer.propTypes = propTypes;

export default connect(null, {
  setIsMobile,
  setIsWebp
})(CheckEnvContainer);
