import { BRAND } from 'config/core';
import { getFileLink } from 'helpers/resources';

const decreasingGroupConfig = {
  common: {
    brand: BRAND.metlife,
    shortName: 'Decreasing Group Term Life Insurance',
    notAvailableForStates: ['AA', 'AE', 'AP', 'AK', 'ME', 'MP', 'MT', 'OR']
  },
  'decreasing-group': {
    pdf: getFileLink('file-decreasing-group-brochure')
  },
  'decreasing-group-spouse': {
    pdf: getFileLink('file-decreasing-group-spouse-brochure')
  }
};

export default decreasingGroupConfig;
